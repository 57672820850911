<template>
  <div>
    <v-stepper-content step="3">
      <v-alert
          v-if="loading"
          class="mx-3"
          text
          prominent
          type="info"
          icon="mdi-clock-fast"
      >
        Processing texts data. Please wait few minutes.
      </v-alert>
      <v-alert
          v-if="false === loading"
          class="mx-3"
          text
          prominent
          color="blue-grey"
          icon="mdi-information-outline"
      >
        Choose content to analyze (at least two competitors)
      </v-alert>
      <v-card
          class="mb-12 mt-2 mx-3"
          scrollable
          :loading="loading"
          raised
          style="overflow: auto"
      >
        <v-card-text>
          <v-data-table
              v-model="selected_urls"
              :headers="tableHeader"
              :items="tableData"
              item-key="url"
              :custom-sort="customSort"
              show-select
              hide-default-footer
              :items-per-page="-1"
              class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="(item, index) in items" :key="item.url">
                <td>
                  <v-checkbox class="mt-0" hide-details v-model="selected_urls" :value="item"
                              show-select/>
                </td>
                <td>
                  <a :href="item.url" target="_blank">{{ item.url }}</a><br/>
                  <span v-html="item.title"></span>
                </td>
                <td class="text-center">
                  {{ item.length }}
                </td>
                <td class="text-center">
                  {{ Math.round(getVisionByUrl(item.url) * 100 / amountVisibility) }}%
                </td>
                <td class="text-center">
                  <v-btn
                      color="primary"
                      small
                      dark
                      @click.stop="showStructure(item)"
                  >
                    <v-icon
                        small
                    >
                      mdi-magnify-plus-outline
                    </v-icon>
                  </v-btn>
                </td>
                <td class="text-center">
                  <v-btn
                      color="primary"
                      small
                      dark
                      @click.stop="showText(item, index)"
                  >
                    <v-icon
                        small
                    >
                      mdi-magnify-plus-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-row justify="center" v-if="!loading">
        <v-col
            cols="12"
            class="text-center"
        >
          Median text size: {{ Math.round((length_min + length_max) / 2) }} words<br>
          <span v-if="compare_text.length > 0">
                        Compare url text size: {{ compare_text.length }} words<br>
                    </span>
          <span v-if="has_compare_url">Compared URL text length: +-3% and you can change this value</span>
          <span v-else>Recommended median: +-10% and you can change this value</span>
        </v-col>
        <v-col
            cols="5"
            md="2"
        >
          <v-text-field
              label="Length Min"
              type="number"
              min="0"
              v-model="length_min"
              solo
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="text-center">
          <div class="py-4">&mdash;</div>
        </v-col>
        <v-col
            cols="5"
            md="2"
        >
          <v-text-field
              label="Length Max"
              type="number"
              min="0"
              v-model="length_max"
              solo
          ></v-text-field>
        </v-col>
      </v-row>

      <v-dialog
          v-model="dialogText"
          max-width="600"
      >
        <v-card>
          <v-card-title class="headline">{{ selectedItem.title }}</v-card-title>

          <v-card-text>
            <v-textarea
                v-model="selectedItem.text"
                rows="10"
            >
            </v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="saveText"
            >
              Save
            </v-btn>
            <v-btn
                text
                @click="dialogText = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="dialogStructure"
          max-width="600"
      >
        <v-card>
          <v-card-title class="headline">{{ selectedItem.title }}</v-card-title>

          <v-card-text>
            <div v-for="i in selectedItem.structure" v-bind:key="i"
                 v-html="`${i[1]}h${i[0]} ${i[2]}`"></div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                text
                @click="dialogStructure = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn @click="prevTab(2)" text>back to competitors</v-btn>
      <v-btn :disabled="disableNext" color="primary" @click="nextTab(4)" class="float-right">Create structure
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
// import {VueEditor} from 'vue2-editor'
import axios from 'axios'

export default {
  name: 'Step3',
  data() {
    return {
      selected: [],
      selectedItem: {
        url: null,
        text: null,
        index: null,
      },
      dialogStructure: false,
      dialogText: false,
    }
  },
  components: {
    // VueEditor,
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.compare(a, b);
        } else {
          return this.compare(b, a);
        }
      });
      return items;
    },
    compare(a, b) {
      let vision1 = this.getVisionByUrl(a.url);
      let vision2 = this.getVisionByUrl(b.url);

      if (vision1 < vision2) return -1;
      if (vision1 > vision2) return 1;

      return 0;
    },
    prevTab(tab) {
      this.setCurrentTab(tab);
    },
    nextTab(tab) {
      this.setCurrentTab(tab);
      this.$store.dispatch('fetchDataOnStep4');
    },
    setCurrentTab(tab) {
      this.$store.state.stepper.current_tab = tab;
    },
    getVisionByUrl(url) {
      let visions = this.$store.state.stepper.s2.vision.filter(value => value.url === url);
      return visions[0] !== undefined ? visions[0].vision : -1;
    },
    saveText() {
      let self = this;
      let url = `${self.$store.state.server_url}/api/v/tt/update-text`;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };
      let data = {
        url: this.selectedItem.url,
        text: this.selectedItem.text
      };

      self.disable_button = true;

      axios.post(url, data, config)
          .catch(() => self.$store.state.sheet = true)
          .finally(() => {
            this.dialogText = false;
          });
      this.$store.state.stepper.s3.texts[this.selectedItem.index].length = this.selectedItem.text.trim().split(/\s+/).length;
      this.$store.state.stepper.s3.texts[this.selectedItem.index].blocks = this.selectedItem.text.split("\n");

      let mediumLength = this.$store.state.stepper.s3.texts.reduce((a, c) => a + c.length, 0) / this.$store.state.stepper.s3.texts.length;

      this.$store.state.stepper.s3.additions = [Math.round(mediumLength * 0.9), Math.round(mediumLength * 1.1)];
    },
    showText(item, index) {
      this.selectedItem.index = index;
      this.selectedItem.title = item.title;
      this.selectedItem.url = item.url;
      this.selectedItem.text = item.blocks.join("\n");
      this.dialogText = true;
    },
    showStructure(item) {
      this.selectedItem.title = item.title;
      this.selectedItem.url = item.url;
      this.selectedItem.structure = item.structure;
      this.dialogStructure = true;
    }
  },
  computed: {
    has_compare_url() {
      return this.$store.getters.hasCompareUrl
    },
    tableHeader() {
      return [
        {text: 'Url', value: 'url'},
        {text: 'Length (words)', align: 'center', value: 'length'},
        {text: 'Visibility', align: 'center', value: 'vision', sortable: true},
        {text: 'View Structure', align: 'center', value: 'structure'},
        {text: 'View Text', align: 'center', value: 'action'},
      ]
    },
    additions() {
      return this.$store.state.stepper.s3.additions
    },
    tableData() {
      return this.$store.state.stepper.s3.texts
    },
    compare_text() {
      return this.$store.state.stepper.s3.compare_text
    },
    length_min: {
      get() {
        return this.$store.state.stepper.s3.additions[0]
      },
      set(value) {
        this.$store.state.stepper.s3.additions[0] = parseInt(value)
      }
    },
    length_max: {
      get() {
        return this.$store.state.stepper.s3.additions[1]
      },
      set(value) {
        this.$store.state.stepper.s3.additions[1] = parseInt(value)
      }
    },
    selected_urls: {
      get() {
        return this.$store.state.stepper.s3.selected_urls
      },
      set(value) {
        // let lengths = [];
        let self = this;
        // value.forEach((x) => lengths.push(x.length));
        // let avgLength = parseInt(lengths.reduce((a, b) => a + b, 0) / value.length);
        // this.$store.state.stepper.s3.additions = [Math.round(avgLength * 0.9), Math.round(avgLength * 1.1)];
        self.$store.state.stepper.s3.selected_urls = value.sort((a, b) => {
          let aVision = self.getVisionByUrl(a.url);
          let bVision = self.getVisionByUrl(b.url);

          if (aVision < bVision) return 1;
          if (aVision > bVision) return -1;
          return 0;
        });
        self.$store.commit('updateAdditions')
      }
    },
    loading() {
      return this.$store.state.stepper.s3.loading
    },
    disableNext() {
      return this.selected_urls.length <= 1
    },
    count() {
      return this.$store.state.count
    },
    amountVisibility() {
      return this.$store.getters.amountVisibility;
    },
    content() {
      return this.$store.state.stepper.s3.texts
    },
    headers() {
      return [
        {label: 'Url', align: 'center', field: 'url'},
        {label: 'Length (words)', align: 'center', field: 'length'},
        {label: 'Visibility', align: 'center', field: 'vision', sortable: true},
        {label: 'Structure', align: 'center', field: 'structure'},
        {label: 'Text', align: 'center', field: 'action'}
      ]
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>