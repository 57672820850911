export default {
    state: {
        loading: true,
        content: [],
        selected_phrases: [],
        custom_selected_phrases: []
    },
    actions: {
        collectPhrases(ctx) {
            let selected_phrases = [];
            let selected_phrases_main = [];

            let modules_main = [
                'MainPhrasesBySerp',
                'AdditionalPhrasesByVision',
                'KeywordsFromMainPhrasesByVision',
                'Snippet',
                'Related',
                'Suggest',
            ];

            let modules = [
                'WithoutStopWords',
                'WithoutStopWords5Default',
                'WithoutStopWords5StartEnd',
                'WithoutStopWords5Others',
                'WithoutStopWords4Default',
                'WithoutStopWords4StartEnd',
                'WithoutStopWords4Others',
                'WithoutStopWords3Default',
                'WithoutStopWords3StartEnd',
                'WithoutStopWords3Others',
                'WithoutStopWords2Default',
                'WithoutStopWords2Others',
                'ByWords',
                'ByWords10',
                'ByWords9',
                'ByWords8',
                'ByWords7',
                'ByWords6',
                'ByWords5',
                'ByWords4'
            ];

            console.log('222', ctx.rootState.stepper.s4.Snippet.selected_phrases)

            for (let i in modules) {
                let module = modules[i];
                if (ctx.rootState.stepper.s4[module].selected_phrases !== undefined) {
                    selected_phrases = selected_phrases.concat(ctx.rootState.stepper.s4[module].selected_phrases)
                }
            }

            for (let i in modules_main) {
                let module = modules_main[i];
                if (ctx.rootState.stepper.s4[module].selected_phrases !== undefined) {
                    selected_phrases_main = selected_phrases_main.concat(ctx.rootState.stepper.s4[module].selected_phrases)
                }
            }

            selected_phrases_main = selected_phrases_main.map((v) => {
                v.favorite = true

                return v
            })

            let by_words_1 = ctx.rootState.stepper.s4['ByWords1'].selected_phrases.map((item) => {
                let main_match = selected_phrases_main.filter((v) => {
                    let m
                    try {
                        m = v.ph.match(new RegExp(new RegExp(`\\b${item.ph}\\b`), 'is')) != null && v.favorite
                    } catch (e) {
                        m = false
                    }

                    return m
                }).length !== 0
                if (main_match) {
                    item.favorite = true
                } else {
                    item.favorite = selected_phrases.filter((v) => {
                        let m;
                        try {
                            m = v.ph.match(new RegExp(new RegExp(`\\b${item.ph}\\b`), 'is')) != null && v.favorite
                        } catch (e) {
                            m = false
                        }
                        return m
                    }).length !== 0
                }
                return item
            })

            for (let i in modules_main) {
                let module = modules_main[i];
                if (ctx.rootState.stepper.s4[module].selected_phrases !== undefined) {
                    selected_phrases = selected_phrases.concat(ctx.rootState.stepper.s4[module].selected_phrases)
                }
            }

            selected_phrases = selected_phrases.concat(by_words_1)

            let selected_phrases_uniq = [];
            let listA = [];
            let resources = {}


            selected_phrases.forEach((v) => {
                if (!resources[v.ph]) {
                    resources[v.ph] = []
                }

                resources[v.ph].push(v.resource)
            });

            console.log('111', selected_phrases)

            selected_phrases.forEach((v) => {
                if (listA.indexOf(v.ph) === -1) {
                    v.resources = resources[v.ph]
                    selected_phrases_uniq.push(v);
                }
                listA.push(v.ph);
            });

            ctx.commit('updateSelectedPhrasesProcessing', selected_phrases_uniq);
        }
    },
    mutations: {
        updateSelectedPhrasesProcessing(state, value) {
            state.content = value;
            state.selected_phrases = value;
        },
    }
}