<template>
    <div>
        <v-stepper-content :step="step">
            <v-alert
                    :loading="false === loading"
                    class="mx-3"
                    text
                    prominent
                    color="blue-grey"
                    icon="mdi-information-outline"
            >
                Recommended textual characteristics for the text.
            </v-alert>
            <v-card
                    class="mb-12 mt-2 mx-3"
                    :loading="loading"
                    raised
            >
                <!--                <v-card-text v-if="content.length > 0">-->
                <v-card-text>
                    <v-data-table
                            :headers="headers"
                            :items="content"
                            :items-per-page="15"
                            item-key="ph"
                            class="elevation-1"
                    >
                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="item in items" :key="item.ph">
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ types[item.type].title }} <a href="#">?</a></span>
                                        </template>
                                        <span>{{ types[item.type].description }}</span>
                                    </v-tooltip>
                                </td>
                                <td class="text-center" v-if="is_compare_url">
                                    {{ item.cu.value.value }}
                                    <v-icon
                                            size="14"
                                            :color="getOurIcon(item).color"
                                            v-html="getOurIcon(item).icon"
                                    ></v-icon>
                                </td>
                                <td class="text-center">{{ item.avg }}</td>
                                <td class="text-center">
                                    {{ item.rv[0] }} - {{ item.rv[1] }}
                                </td>
                                <td v-for="(v, k) in item.child.slice(0, max_headers)" v-bind:key="k"
                                    class="text-center">
                                    {{ v.value }}<br>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-btn @click="setCurrentTab(step - 1)" text>back to texts</v-btn>
            <v-btn color="primary" @click="setCurrentTab(step + 1)" class="float-right">Choose general characteristics
            </v-btn>
        </v-stepper-content>
    </div>
</template>

<script>
    export default {
        name: "Step",
        data() {
            return {
                step: 4,
                types: {
                    zipfs_law: {
                        title: 'Naturalness',
                        description: 'Естественность текста по закону Ципфа'
                    },
                    total_words: {
                        title: 'Total words',
                        description: 'Общее количество слов в тексте'
                    },
                    vocabulary: {
                        title: 'Vocabulary',
                        description: 'Кол-во слов употребляющихся в тексте'
                    },
                    semantic_vocabulary: {
                        title: 'Semantic Vocabulary',
                        description: 'Кол-во РАЗНЫХ слов исключая стоп-слова'
                    },
                    total_sentences: {
                        title: 'Total sentences',
                        description: 'Общее кол-во предложений в тексте'
                    },
                    average_sentence_length: {
                        title: 'Average sentence length',
                        description: 'Среднее кол-во слов в предложении'
                    },
                    classical_nausea_index: {
                        title: 'Classical Nausea Index',
                        description: 'Классическая тошнота'
                    },
                    water_index: {
                        title: 'Water Index',
                        description: 'Показывает процент слов не несущих информационную нагрузку (водность)'
                    },
                    dale_chall_score: {
                        title: 'Dale–Chall Readability',
                        description: 'Читабельность по алгоритму Dale–Chal'
                    },
                    spache: {
                        title: 'Spache Readability',
                        description: 'Читабельность по алгоритму Spache'
                    },
                    flesch: {
                        title: 'Flesch Readability',
                        description: 'Читабельность по алгоритму Flesch'
                    },
                    automated_index: {
                        title: 'Automated readability index Readabilit',
                        description: 'Читабельность по алгоритму Automated readability index'
                    }
                }
            }
        },
        computed: {
            headers() {
                let self = this;
                let columns = [
                    {text: '', value: 'type'},
                    {text: 'CU', value: 'our', align: 'center', width: '90px'},
                    {text: 'AVG', value: 'avg', align: 'center'},
                    {text: 'RV', value: 'rv', align: 'center', sortable: true, width: '180px'},
                ];

                columns = columns.filter((v) => self.is_compare_url ? true : v.value !== 'our');

                let headers = this.$store.state.stepper.s3.selected_urls.slice(0, this.max_headers);
                for (let i in headers) {
                    let visibility = 0;
                    if (this.$store.state.stepper.s2.visionByUrl[headers[i].url.trim()] !== undefined) {
                        visibility = Math.round(this.$store.state.stepper.s2.visionByUrl[headers[i].url.trim()] * 100 / this.$store.getters.amountVisibility)
                    }
                    columns.push({text: `${visibility}%`, value: `p${i + 1}`, align: 'center'});
                }

                return columns
            },
            loading() {
                return this.content.length === 0
            },
            content() {
                return this.$store.state.stepper.textual_factors.content
            },
            additions() {
                return this.$store.state.stepper.s3.additions
            },
            max_headers() {
                return this.size_child > 0 ? this.size_child : 0
            },
            size_child() {
                return this.content[0] !== undefined ? this.content[0].child.length : 0
            },
            is_compare_url() {
                return this.$store.state.stepper.s1.compare_url.length > 0
            }
        },
        methods: {
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab;
            },
            getOurIcon(item) {
                let icon = '';
                let color = '';

                let cu = item.cu.value.value;

                let minRv = item.rv[0];
                let maxRv = item.rv[1];

                let delta_min = Math.abs((cu - minRv) * 100 / minRv);
                let delta_max = Math.abs((cu - maxRv) * 100 / maxRv);

                if (cu > maxRv && delta_max < 15) {
                    color = '';
                } else if (cu > maxRv && delta_max > 15 && delta_max <= 30) {
                    icon = 'mdi-arrow-down-right-thick';
                    color = 'warning';
                } else if (cu > maxRv && delta_max > 30) {
                    icon = 'mdi-arrow-down-thick';
                    color = 'error';
                } else if (cu < minRv && delta_min < 15) {
                    color = '';
                } else if (cu < minRv && delta_min > 15 && delta_min <= 30) {
                    icon = 'mdi-arrow-up-thick';
                    color = 'warning';
                } else if (cu < minRv && delta_min > 30) {
                    icon = 'mdi-arrow-up-thick';
                    color = 'error';
                } else if (cu > minRv && cu < maxRv) {
                    icon = '';
                }

                return {
                    icon: icon,
                    color: color
                };
            },
            getClass(item, type = 'rwc') {
                let self = this;
                let result = {};
                let colors = {
                    success: 'light-green lighten-4',
                    danger: 'amber lighten-4'
                };
                let minAvgRec,
                    minMedianRec;

                if (type === 'rwc') {
                    minAvgRec = Math.round(item.avg[1] / 100 * self.additions[0]);
                    minMedianRec = Math.round(item.med[1] / 100 * self.additions[0]);
                    // maxRWC = Math.round(item.avg[1] / 100 * self.additions[1]);

                    if (item.our[0] === 0 && (minAvgRec >= 1 || minMedianRec >= 1)) {
                        result.color = colors.danger;
                    }

                    if (item.our[0] < item.avg[0]) {
                        result.color = colors.danger;
                    }

                    if (item.our[0] > item.avg[0]) {
                        result.colorRWC = colors.danger;
                    }
                } else if (type === 'child') {
                    result.color = item[0] > 0 ? colors.success : ''
                }

                return result
            },
        }
    }
</script>

<style scoped>

</style>