<template>
    <div>
        <v-stepper-content :step="step">
            <div v-if="disabledDownloadBtn">
                <v-container>
                    <v-row>
                        <v-col>
                            <GeneralInfo/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <Characteristics/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <vue-editor
                                    placeholder="Enter your structure ..."
                                    v-model="structure"
                                    :editorToolbar="customToolbar"
                            ></vue-editor>
                        </v-col>
                    </v-row>
                </v-container>
                <v-btn @click="setCurrentTab(step - 1)" text>back to general characteristics</v-btn>
                <v-btn class="mx-2" color="primary" :disabled="disabledExportBtn" :loading="disabledExportBtn" @click="exportReport()">Save Task
                </v-btn>
            </div>
            <div v-if="false === disabledDownloadBtn">
                <v-container>
                    <v-row  class="text-center">
                        <v-col>
                            <h3 class="title my-5">
                                Technical assignment successfully saved!
                            </h3>
                        </v-col>
                    </v-row>
                </v-container>

            </div>
        </v-stepper-content>
    </div>
</template>

<script>
    import Characteristics from './Child/Characteristics'
    import {VueEditor} from 'vue2-editor'
    import axios from 'axios';
    import GeneralInfo from "./Child/GeneralInfo";

    export default {
        name: 'Step7',
        data() {
            return {
                customToolbar: [],
                step: 6,
                disabledExportBtn: false,
                disabledDownloadBtn: true,
                taskData: {
                    msg: 'No data',
                    user_id: 0,
                    hash: '',
                }
            }
        },
        components: {
            GeneralInfo,
            Characteristics,
            VueEditor
        },
        methods: {
            exportReport: function () {
                this.saveTask();
            },
            downloadFile() {
                location.href = `${this.$store.state.server_url}/api/v/tt/get-doc/${this.taskData.user_id}/${this.taskData.hash}`;
            },
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab
            },
            saveTask() {
                let self = this;
                let url = `${this.$store.state.server_url}/api/v/tt/save-task`;
                // this.disabledExportBtn = true;

                let postData = {
                    _token: "undefined",
                    data: {
                        type: "textual_factors",
                        urls: this.$store.getters.preparedSelectedUrlsForReports,
                        step1: this.$store.state.stepper.s1,
                        step6: this.$store.state.stepper.s6,
                        textual_factors: this.$store.state.stepper.textual_factors,
                        step3: {
                            additions: this.$store.state.stepper.s3.additions
                        },
                    }
                };

                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.$store.state.auth.token
                    }
                };

                axios.post(url, postData, config)
                    .then((response) => {
                        this.taskData = response.data;
                        this.disabledDownloadBtn = false;
                    })
                    .catch(() => self.$store.state.sheet = true);
            }
        },
        computed: {
            structure: {
                get() {
                    if (this.$store.state.stepper.current_tab === this.step) {
                        return this.$store.state.stepper.s3.structure
                    }
                    return ''
                },
                set(value) {
                    if (this.$store.state.stepper.current_tab === this.step) {
                        this.$store.state.stepper.s3.structure = value
                    } else {
                        this.$store.state.stepper.s3.structure = ''
                    }
                },
            },
        }
    }
</script>