var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",staticStyle:{"height":"90vh"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.is_service_not_available)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-md-4 mx-lg-auto",attrs:{"id":"info-block","color":"#26c6da","dark":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"70"}},[_vm._v("mdi-emoticon-sad-outline")]),_vm._v(" Service unavailable ")],1)],1)],1):_vm._e(),_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"id":"table-tasks","loading":_vm.loading_tasks,"disabled":_vm.is_service_not_available}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","solo":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('router-link',{staticClass:"float-right",attrs:{"to":"/stepper"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":_vm.is_service_not_available || !_vm.server_pinged}},[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-plus")]),_vm._v(" Create Task ")],1)],1),_c('router-link',{staticClass:"float-right ml-2",attrs:{"to":"/textual_factor_analysis"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"primary","disabled":_vm.is_service_not_available || !_vm.server_pinged}},on),[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-plus")]),_vm._v(" Create Task TFA ")],1)]}}])},[_c('span',[_vm._v("Create Task Textual Factors Analysis")])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"table-custom",attrs:{"headers":_vm.headers,"items":_vm.content,"search":_vm.search,"items-per-page":5},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'default')?_c('v-chip',{attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(" DF ")]):_vm._e(),(item.type === 'textual_factors')?_c('v-chip',{attrs:{"small":"","outlined":"","color":"warning"}},[_vm._v(" TFA ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.split(' ')[0])+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":"","x-small":"","icon":""},on:{"click":function($event){return _vm.downloadDocx(item.hash)}}},on),[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-file-word-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Download .docx")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":"","x-small":"","icon":""},on:{"click":function($event){return _vm.openHtmlFile(item.hash)}}},on),[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-language-html5")])],1)]}}],null,true)},[_c('span',[_vm._v("Open HTML")])]),(item.type !== 'textual_factors')?_c('router-link',{attrs:{"to":("/editor/" + _vm.user_id + "/" + (item.hash))}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","fab":"","x-small":"","icon":""}},on),[_c('v-icon',{attrs:{"size":"17"}},[_vm._v("mdi-file-document-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to Live Editor")])])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }