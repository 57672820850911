export default {
    state: {
        url: 'empty',
        title: 'empty',
        description: '30-35 words',
        uniqueness: null,
        text_type: null,
        tonality_of_the_text: null,
        condition_have_lists: false,
        condition_have_table: false,
        special_characteristics: null,
        guide: `<ul><li>Keywords should be distributed throughout the text. There should be no concentration of keywords only in individual paragraphs. Use the topic-specific keywords.</li><li>Do not use the words: punters, venues, establishments, gambling house and similar synonyms.</li><li>Make the headings h2 and h3 informative and understandable. The title should describe the content of the paragraph.</li><li>Introduction and conclusion are required.</li><li>The introduction should be about 100-200 words. (It can't be a whole page).</li><li>The conclusion should be informative and summarize the article. Write a good and informative summary, not just the same info in other words. There should be about 100-200 words.</li><li>Make the content readable, coherent, and interesting to read. Use examples to explain ideas.</li><li>Don’t make too long sentences. Sentences should be simple and easy to read. Sentences should not be longer than 25 words.</li><li>Phrases for linking (the phrases for linking should be uniformly and organically distributed throughout the text. Do not put links in the first paragraph or in the conclusion of the article).</li><li>The link must be inserted naturally, in the middle of the paragraph, surrounded by thematical words from both sides. Note: if the link is about the best online games, the paragraph where you place the link has to be also about the best online games.</li><li>Don’t put the links close to each other – distribute links evenly throughout the text. </li><li>Do not insert links at the beginning or end of the sentence.</li><li>Add 2-3 links to trust resources (not Wiki or Forbes). Links should be distributed evenly and naturally. </li><li>Add 2-3 appropriate images (use free stocks, for example, unsplash.com)</li><li>Add links of internal pages of the site for which the article is written (according to the topic) - ( if possible )</li></ul>`,
        relinking: []
    },
}