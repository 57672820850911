<template>
  <div id="app">
    <v-app>
      <v-app-bar
          :color="beta ? 'black' : 'primary'"
          dark
          style="max-height: 64px"
          v-if="isAuthenticated && !isEditorUrl"
      >
        <v-icon class="pr-2">mdi-balloon</v-icon>

        <audio id="audio-complete"
               src="http://app.contentball.com/time-is-now.mp3">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <audio id="audio-error"
               src="http://app.contentball.com/vlad-working.mp3">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->

        <router-link to="/" tag="v-toolbar-title" class="cursor-pointer">
          ContentBall
        </router-link>

        <v-spacer></v-spacer>

        <v-btn @click="toggle_dark_mode" icon>
          <v-icon>mdi-desk-lamp</v-icon>
        </v-btn>

        <v-btn
            v-if="isAdmin"
            dark
            icon
            @click="autocompleteFromJson"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>

        <v-dialog
            v-model="dialog_import_from_json"
            persistent
            max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="isAdmin"
                dark
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>
                mdi-qrcode
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Import From  JSON</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        solo
                        v-model="import_from_json_data"
                        name="input-7-1"
                        value=""
                        hide-details
                    ></v-textarea>
                    <v-alert
                        v-if="import_from_json_error"
                        border="left"
                        dense
                        type="error"
                    >
                      I can't read this JSON
                    </v-alert>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog_import_from_json = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="startImportFromJson"
              >
                Import
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="settings_dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Settings</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <h3 class="title my-4">Guide</h3>
                    <vue-editor
                        v-model="settings.guide"
                    ></vue-editor>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="settings_dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveSettings"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-menu
            v-model="menu_bug"
            :close-on-content-click="false"
            :nudge-width="500"
            offset-y
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-bug</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              Bug form
            </v-card-title>
            <v-card-text>
              <v-textarea
                  v-model="msg_bug"
                  solo
                  name="input-7-4"
                  label="What's your problem?"
                  hide-details
              ></v-textarea>
              <small>Want to attach a screenshot? Use <a href="http://joxi.net/download/"
                                                         target="_blank">joxi</a></small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="send_bug_form"
                  :loading="bug_loading"
              >
                Send
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <v-menu
            left
            bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                @click="settings_dialog = true"
            >
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="toggle_beta"
            >
              <v-list-item-title>Turn {{ beta ? 'off' : 'on' }} beta mode</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="logout"
            >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <!-- Sizes your content based upon application components -->
      <v-content>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- If using vue-router -->
          <router-view class="view"></router-view>
        </v-container>
        <v-bottom-sheet v-model="sheet" inset :hide-overlay="true" :persistent="false">
          <v-alert
              prominent
              type="error"
              class="mb-0"
              v-if="sheet_type === 'error'"
          >
            <v-row align="center">
              <v-col class="grow">{{ sheet_message }}</v-col>
              <v-col class="shrink">
                <v-btn v-on:click="refresh_page">Refresh page</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-bottom-sheet>
      </v-content>

      <!--<v-footer app>-->
      <!--&lt;!&ndash; &ndash;&gt;-->
      <!--</v-footer>-->
    </v-app>
  </div>
</template>

<script>
import store from './store/index'
import axios from "axios";
import {VueEditor} from 'vue2-editor'

export default {
  name: 'app',
  data() {
    return {
      interface_settings: {},
      import_json_data: {},
      settings: {
        guide: `<ol>
<li>
<ol>
<li>Keywords should be distributed throughout the text. There should be no concentration of keywords only in individual paragraphs. Use the topic-specific keywords.</li>
<li>Do not use the words: punters, venues, establishments, gambling house and similar synonyms.</li>
<li>Make the headings h2 and h3 informative and understandable. The title should describe the content of the paragraph.</li>
<li>Introduction and conclusion are required.</li>
</ol>
<ul>
<li>The introduction should be about 100-200 words. (It can't be a whole page).</li>
<li>The conclusion should be informative and summarize the article. Write a good and informative summary, not just the same info in other words. There should be about 100-200 words.</li>
</ul>
<ol>
<li>Make the content readable, coherent, and interesting to read. Use examples to explain ideas.</li>
<li>Don&rsquo;t make too long sentences. Sentences should be simple and easy to read. Check it out with hemingwayapp.com</li>
<li>In the Live Editor (contentball) check &ldquo;Flesch Readability&rdquo;, &ldquo;Nausea Indexes Academic&rdquo;, &ldquo;Nausea Indexes Classic&rdquo;, &ldquo;Naturalness&rdquo;. They should not be highlighted in yellow.</li>
<li>Phrases for linking (the phrases for linking should be uniformly and organically distributed throughout the text. Do not put links in the first paragraph or in the conclusion of the article).</li>
<li>The link must be inserted naturally, in the middle of the paragraph, surrounded by topical words from both sides. Note: if the link is about the best online games, the paragraph where you place the link has to be also about the best online games.</li>
<li>Don&rsquo;t put the links close to each other &ndash; the optimal distance between each link is around 300 words</li>
<li>Do not insert links at the beginning or end of the sentence.</li>
<li>Add 2-3 links to trust resources (not Wiki or Forbes).</li>
<li>Add 2-3 appropriate images (use free stocks, for example, unsplash.com)</li>
<li>Add links of internal pages of the site for which the article is written (according to the topic)</li>
</ol>
</li>
</ol>`
      },
      settings_dialog: false,
      dialog_import_from_json: false,
      import_from_json_data: undefined,
      import_from_json_error: false,
      menu_bug: false,
      bug_loading: false,
      msg_bug: undefined,
      menu: [
        {
          icon: 'mdi-file-document-edit-outline',
          url: '/editor/:id',
          title: 'Editor',
        },
        {
          icon: 'mdi-gavel',
          url: '/stepper',
          title: 'Stepper',
        }
      ]
    }
  },
  components: {VueEditor},
  methods: {
    toggle_dark_mode() {
      this.interface_settings.dark_mode = !this.interface_settings.dark_mode
      this.save_interface_settings()
    },
    save_interface_settings() {
      localStorage.setItem('interface_settings', JSON.stringify(this.interface_settings))

      this.$vuetify.theme.dark = this.interface_settings.dark_mode
    },
    saveSettings() {
      let self = this

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios
          .post(`${self.$store.state.server_url}/api/v/tt/save-settings`, self.settings, config)
          .finally(() => self.settings_dialog = false)
    },
    autocompleteFromJson() {
      let j = this.import_json_data

      if (this.$store.state.stepper.current_tab === 1) {
        if (j.stepper.s1.main_keywords) {
          this.$store.state.stepper.s1.main_keywords = j.stepper.s1.main_keywords
        }
        if (j.stepper.s1.compare_url) {
          this.$store.state.stepper.s1.compare_url = j.stepper.s1.compare_url
        }
        if (j.stepper.s1.additional_keywords) {
          this.$store.state.stepper.s1.additional_keywords = j.stepper.s1.additional_keywords
        }
        if (j.stepper.s1.search_engine) {
          this.$store.state.stepper.s1.search_engine = j.stepper.s1.search_engine
        }
        if (j.stepper.s1.serp_type) {
          this.$store.state.stepper.s1.serp_type = j.stepper.s1.serp_type
        }
        if (j.stepper.s1.language) {
          this.$store.state.stepper.s1.language = j.stepper.s1.language
        }
        if (j.stepper.s1.translation_language) {
          this.$store.state.stepper.s1.translation_language = j.stepper.s1.translation_language
        }
      } else if (this.$store.state.stepper.current_tab === 2) {
        if (j.stepper.s2.selected_urls) {
          this.$store.state.stepper.s2.selected_urls = j.stepper.s2.selected_urls
        }
        if (j.stepper.s2.additional_urls) {
          this.$store.state.stepper.s2.additional_urls = j.stepper.s2.additional_urls
        }
      }
    },
    startImportFromJson() {
      // try {
      this.import_from_json_error = false

      this.import_json_data = JSON.parse(this.import_from_json_data)

      this.dialog_import_from_json = false

      // if(j.stepper.state.current_tab){
      //   console.log(this.$store.state.stepper)
      //   this.$store.state.stepper.current_tab = j.stepper.state.current_tab
      // }

      // console.log(j)
      //
      // } catch (e) {
      //   this.import_from_json_error = true
      // }
    },
    refresh_page() {
      location.reload();
    },
    send_bug_form() {
      let self = this

      self.bug_loading = true

      let url = `${self.$store.state.server_url}/api/v/tt/bug-request`

      let post_data = {
        msg: self.msg_bug,
        stepper: {
          state: {
            current_tab: self.$store.state.stepper.current_tab
          },
          s1: self.$store.state.stepper.s1,
          s2: {
            selected_urls: self.$store.state.stepper.s2.selected_urls,
            additional_urls: self.$store.state.stepper.s2.additional_urls
          },
          s3: {
            selected_urls: self.$store.state.stepper.s3.selected_urls.map((v) => v.url),
            additions: self.$store.state.stepper.s3.additions
          },
        }
      }

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.post(url, post_data, config)
          .finally(() => {
            self.bug_loading = false
            self.menu_bug = false
          })
    },
    logout() {
      let self = this;
      self.$store.dispatch('logout', function () {
        self.$router.push('login');
      })
    },
    toggle_beta() {
      this.$store.state.beta = !this.$store.state.beta
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.id_companies === 21
    },
    beta() {
      return this.$store.state.beta
    },
    sheet: {
      set(val) {
        this.$store.state.sheet = val
      },
      get() {
        return this.$store.state.sheet
      }
    },
    sheet_type() {
      return this.$store.state.sheet_type
    },
    sheet_message: {
      set(val) {
        this.$store.state.sheet_message = val
      },
      get() {
        return this.$store.state.sheet_message
      }
    },
    isEditorUrl() {
      // return parseInt(this.$route.query.ext) === 1
      return this.$route.matched[0].path === '/editor/:user_id/:hash'
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  },
  created() {
    window.onbeforeunload = function () {
      return ""
    }

    let self = this

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': self.$store.state.auth.token
      }
    }

    axios
        .get(`${self.$store.state.server_url}/api/v/tt/get-settings`, config)
        .then((response) => {
          if (response.data.guide) {
            self.settings.guide = response.data.guide
            self.$store.state.stepper.s6.guide = response.data.guide
          }
        })
  },
  mounted() {
    if (localStorage.getItem('interface_settings')) {
      try {
        this.interface_settings = JSON.parse(localStorage.getItem('interface_settings'))
        this.$vuetify.theme.dark = this.interface_settings.dark_mode
      } catch (e) {
        localStorage.removeItem('interface_settings')
      }
    }
  },
  store
}
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
