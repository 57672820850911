<template>
    <Report
            :title="comp_title"
            :component="name_component"
            :loading="loading"
            :content="content"
    />
</template>

<script>
    const name_component = 'ByWords';
    import Report from './../Report'

    export default {
        name: name_component,
        props: ['words'],
        components: {
            Report
        },
        computed: {
          name_component(){
              return name_component + this.$props.words
            },
            loading(){
                return this.$store.state.stepper.s4[this.name_component].loading
            },
            content: {
                get() {
                    return this.$store.state.stepper.s4[this.name_component].content
                },
                set(newValue) {
                    this.$store.state.stepper.s4[this.name_component].content = newValue
                }
            },
            comp_title() {
                return `By Words ${this.$props.words}`
            }
        }
    }
</script>

<style scoped>

</style>