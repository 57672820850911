<template>
    <v-card
            class="mx-auto"
            raised
    >
        <v-card-title>
            Thematic Phrases
        </v-card-title>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="result"
                    item-key="phrase"
                    :items-per-page="10"
                    class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.phrase">
                        <td>{{ item.ph }}</td>
                        <td v-if="is_compare_url" class="text-center">
                            <span class="red--text" v-if="item.our[0] > item.rwc_max">{{ item.rwc_max - item.our[0] }}</span>
                            <span class="red--text" v-else-if="item.rwc_min > item.our[0]">{{'+' + (item.rwc_min - item.our[0]) }}</span>
                            <span v-else><v-icon class="green--text">mdi-check</v-icon></span>
                        </td>
                        <td class="text-center">{{ item.rwc_min }} - {{ item.rwc_max }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        computed: {
            result() {
                return this.$store.state.stepper.processing.selected_phrases;
            },
            headers() {
                let headers = [
                    {text: 'Phrase', value: 'phrase'},
                    {text: 'Corrections', value: 'corrections', align: 'center'},
                    {text: 'Min-Max', value: 'min_max', align: 'center'}
                ];

                if (!this.is_compare_url) {
                    return headers.filter((x) => x.value !== 'corrections')
                }

                return headers
            },
            is_compare_url() {
                return this.$store.state.stepper.s1.compare_url.length > 0
            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>