<template>
    <div>
        <v-stepper-content step="1">
            <v-container>
                <v-row>
                    <v-col
                            cols="6"
                    >
                        <v-card
                                class="mx-auto mb-12 mt-2"
                                max-width="500"
                                raised
                        >
                            <v-card-text>
                                <v-form
                                        ref="form"
                                        v-model="valid"
                                >
                                    <v-textarea
                                            label="Main Keywords"
                                            no-resize
                                            rows="4"
                                            :rules="rules_main_keywords"
                                            :value="main_keywords"
                                            required
                                            @change="updateMainKeywords"
                                    >
                                        <template v-slot:prepend>
                                            <v-tooltip
                                                    left
                                                    :allow-overflow="true"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information-outline</v-icon>
                                                </template>
                                                keyword 1;volume<br>
                                                keyword 2;volume
                                            </v-tooltip>
                                        </template>
                                    </v-textarea>
                                    <v-textarea
                                            label="Additional Keywords"
                                            no-resize
                                            rows="4"
                                            :rules="rules_addition_keywords"
                                            :value="additional_keywords"
                                            @change="updateAdditionalKeywords"
                                    >
                                        <template v-slot:prepend>
                                            <v-tooltip
                                                    left
                                                    :allow-overflow="true"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information-outline</v-icon>
                                                </template>
                                                keyword 1;volume<br>
                                                keyword 2;volume
                                            </v-tooltip>
                                        </template>
                                    </v-textarea>
                                    <v-text-field
                                            label="Compare Url"
                                            @change="updateCompareUrl"
                                            :value="compare_url"
                                    >
                                        <template v-slot:prepend>
                                            <v-tooltip
                                                    bottom
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information-outline</v-icon>
                                                </template>
                                                Compare Url
                                            </v-tooltip>
                                        </template>
                                    </v-text-field>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                            cols="6"
                    >
                        <v-card
                                class="mx-auto mb-12 mt-2"
                                max-width="500"
                                raised
                        >
                            <v-card-text>
                                <v-form
                                        ref="form"
                                        v-model="valid"
                                >
                                    <v-autocomplete
                                            label="SERP"
                                            @change="updateSerp"
                                            :items="serp_list"
                                            :value="serp_type"
                                            :rules="[v => !!v || 'Item is required']"
                                            :filter="active_search_engine_filter"
                                    >
                                        <template v-slot:prepend>
                                            <v-tooltip
                                                    bottom
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information-outline</v-icon>
                                                </template>
                                                Google Search
                                            </v-tooltip>
                                        </template>
                                    </v-autocomplete>
                                    <v-autocomplete
                                            label="Search Engine"
                                            @change="updateSearchEngine"
                                            :items="search_engines"
                                            :value="search_engine"
                                            :rules="[v => !!v || 'Item is required']"
                                            :filter="active_search_engine_filter"
                                    >
                                        <template v-slot:prepend>
                                            <v-tooltip
                                                    bottom
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information-outline</v-icon>
                                                </template>
                                                Google Search
                                            </v-tooltip>
                                        </template>
                                    </v-autocomplete>
                                    <v-autocomplete
                                            :items="languages"
                                            :rules="[v => !!v || 'Item is required']"
                                            :value="language"
                                            label="Language"
                                            item-text="language"
                                            item-value="iso"
                                            @change="updateLanguage"
                                            :filter="active_search_engine_filter"
                                    >
                                        <template v-slot:prepend>
                                            <v-tooltip
                                                    bottom
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on">mdi-information-outline</v-icon>
                                                </template>
                                                Interface Language
                                            </v-tooltip>
                                        </template>
                                    </v-autocomplete>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-btn
                    :disabled="!valid"
                    color="primary"
                    class="float-right"
                    @click="setCurrentTab(2)"
            >
                Choose competitors
            </v-btn>
        </v-stepper-content>
    </div>
</template>

<script>
    export default {
        name: 'Step1',
        props: ['text'],
        data: function () {
            return {
                valid: true,
                dialog: false,
                myText: this.text,
                languages: [
                    {"language": "аҧсуа бызшәа, аҧсшәа", "iso": "ab"},
                    {"language": "Afaraf", "iso": "aa"},
                    {"language": "Afrikaans", "iso": "af"},
                    {"language": "Akan", "iso": "ak"},
                    {"language": "Shqip", "iso": "sq"},
                    {"language": "አማርኛ", "iso": "am"},
                    {"language": "العربية", "iso": "ar"},
                    {"language": "aragonés", "iso": "an"},
                    {"language": "Հայերեն", "iso": "hy"},
                    {"language": "অসমীয়া", "iso": "as"},
                    {"language": "авар мацӀ, магӀарул мацӀ", "iso": "av"},
                    {"language": "avesta", "iso": "ae"},
                    {"language": "aymar aru", "iso": "ay"},
                    {"language": "azərbaycan dili", "iso": "az"},
                    {"language": "bamanankan", "iso": "bm"},
                    {"language": "башҡорт теле", "iso": "ba"},
                    {"language": "euskara, euskera", "iso": "eu"},
                    {"language": "беларуская мова", "iso": "be"},
                    {"language": "বাংলা", "iso": "bn"},
                    {"language": "भोजपुरी", "iso": "bh"},
                    {"language": "Bislama", "iso": "bi"},
                    {"language": "bosanski jezik", "iso": "bs"},
                    {"language": "brezhoneg", "iso": "br"},
                    {"language": "български език", "iso": "bg"},
                    {"language": "ဗမာစာ", "iso": "my"},
                    {"language": "català, valencià", "iso": "ca"},
                    {"language": "Chamoru", "iso": "ch"},
                    {"language": "нохчийн мотт", "iso": "ce"},
                    {"language": "chiCheŵa, chinyanja", "iso": "ny"},
                    {"language": "中文(Zhōngwén), 汉语, 漢語", "iso": "zh"},
                    {"language": "чӑваш чӗлхи", "iso": "cv"},
                    {"language": "Kernewek", "iso": "kw"},
                    {"language": "corsu, lingua corsa", "iso": "co"},
                    {"language": "ᓀᐦᐃᔭᐍᐏᐣ", "iso": "cr"},
                    {"language": "hrvatski jezik", "iso": "hr"},
                    {"language": "čeština, český jazyk", "iso": "cs"},
                    {"language": "dansk", "iso": "da"},
                    {"language": "ދިވެހި", "iso": "dv"},
                    {"language": "Nederlands, Vlaams", "iso": "nl"},
                    {"language": "རྫོང་ཁ", "iso": "dz"},
                    {"language": "English", "iso": "en"},
                    {"language": "Esperanto", "iso": "eo"},
                    {"language": "eesti, eesti keel", "iso": "et"},
                    {"language": "Eʋegbe", "iso": "ee"},
                    {"language": "føroyskt", "iso": "fo"},
                    {"language": "vosa Vakaviti", "iso": "fj"},
                    {"language": "suomi, suomen kieli", "iso": "fi"},
                    {"language": "français, langue française", "iso": "fr"},
                    {"language": "Fulfulde, Pulaar, Pular", "iso": "ff"},
                    {"language": "Galego", "iso": "gl"},
                    {"language": "ქართული", "iso": "ka"},
                    {"language": "Deutsch", "iso": "de"},
                    {"language": "ελληνικά", "iso": "el"},
                    {"language": "Avañe'ẽ", "iso": "gn"},
                    {"language": "ગુજરાતી", "iso": "gu"},
                    {"language": "Kreyòl ayisyen", "iso": "ht"},
                    {"language": "(Hausa) هَوُسَ", "iso": "ha"},
                    {"language": "עברית", "iso": "he"},
                    {"language": "Otjiherero", "iso": "hz"},
                    {"language": "हिन्दी, हिंदी", "iso": "hi"},
                    {"language": "Hiri Motu", "iso": "ho"},
                    {"language": "magyar", "iso": "hu"},
                    {"language": "Interlingua", "iso": "ia"},
                    {"language": "Bahasa Indonesia", "iso": "id"},
                    {"language": "Originally called Occidental; then Interlingue aft", "iso": "ie"},
                    {"language": "Gaeilge", "iso": "ga"},
                    {"language": "Asụsụ Igbo", "iso": "ig"},
                    {"language": "Iñupiaq, Iñupiatun", "iso": "ik"},
                    {"language": "Ido", "iso": "io"},
                    {"language": "Íslenska", "iso": "is"},
                    {"language": "Italiano", "iso": "it"},
                    {"language": "ᐃᓄᒃᑎᑐᑦ", "iso": "iu"},
                    {"language": "日本語 (にほんご)", "iso": "ja"},
                    {"language": "ꦧꦱꦗꦮ, Basa Jawa", "iso": "jv"},
                    {"language": "kalaallisut, kalaallit oqaasii", "iso": "kl"},
                    {"language": "ಕನ್ನಡ", "iso": "kn"},
                    {"language": "Kanuri", "iso": "kr"},
                    {"language": "कश्मीरी, كشميري‎", "iso": "ks"},
                    {"language": "қазақ тілі", "iso": "kk"},
                    {"language": "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ", "iso": "km"},
                    {"language": "Gĩkũyũ", "iso": "ki"},
                    {"language": "Ikinyarwanda", "iso": "rw"},
                    {"language": "Кыргызча, Кыргыз тили", "iso": "ky"},
                    {"language": "коми кыв", "iso": "kv"},
                    {"language": "Kikongo", "iso": "kg"},
                    {"language": "한국어", "iso": "ko"},
                    {"language": "Kurdî, کوردی‎", "iso": "ku"},
                    {"language": "Kuanyama", "iso": "kj"},
                    {"language": "latine, lingua latina", "iso": "la"},
                    {"language": "Lëtzebuergesch", "iso": "lb"},
                    {"language": "Luganda", "iso": "lg"},
                    {"language": "Limburgs", "iso": "li"},
                    {"language": "Lingála", "iso": "ln"},
                    {"language": "ພາສາລາວ", "iso": "lo"},
                    {"language": "lietuvių kalba", "iso": "lt"},
                    {"language": "Kiluba", "iso": "lu"},
                    {"language": "latviešu valoda", "iso": "lv"},
                    {"language": "Gaelg, Gailck", "iso": "gv"},
                    {"language": "македонски јазик", "iso": "mk"},
                    {"language": "fiteny malagasy", "iso": "mg"},
                    {"language": "Bahasa Melayu, بهاس ملايو‎", "iso": "ms"},
                    {"language": "മലയാളം", "iso": "ml"},
                    {"language": "Malti", "iso": "mt"},
                    {"language": "te reo Māori", "iso": "mi"},
                    {"language": "मराठी", "iso": "mr"},
                    {"language": "Kajin M̧ajeļ", "iso": "mh"},
                    {"language": "Монгол хэл", "iso": "mn"},
                    {"language": "Dorerin Naoero", "iso": "na"},
                    {"language": "Diné bizaad", "iso": "nv"},
                    {"language": "isiNdebele", "iso": "nd"},
                    {"language": "नेपाली", "iso": "ne"},
                    {"language": "Owambo", "iso": "ng"},
                    {"language": "Norsk Bokmål", "iso": "nb"},
                    {"language": "Norsk Nynorsk", "iso": "nn"},
                    {"language": "Norsk", "iso": "no"},
                    {"language": "ꆈꌠ꒿ Nuosuhxop", "iso": "ii"},
                    {"language": "isiNdebele", "iso": "nr"},
                    {"language": "occitan, lenga d'òc", "iso": "oc"},
                    {"language": "ᐊᓂᔑᓈᐯᒧᐎᓐ", "iso": "oj"},
                    {"language": "ѩзыкъ словѣньскъ", "iso": "cu"},
                    {"language": "Afaan Oromoo", "iso": "om"},
                    {"language": "ଓଡ଼ିଆ", "iso": "or"},
                    {"language": "ирон æвзаг", "iso": "os"},
                    {"language": "ਪੰਜਾਬੀ", "iso": "pa"},
                    {"language": "पाऴि", "iso": "pi"},
                    {"language": "فارسی", "iso": "fa"},
                    {"language": "język polski, polszczyzna", "iso": "pl"},
                    {"language": "پښتو", "iso": "ps"},
                    {"language": "Português", "iso": "pt"},
                    {"language": "Runa Simi, Kichwa", "iso": "qu"},
                    {"language": "Rumantsch Grischun", "iso": "rm"},
                    {"language": "Ikirundi", "iso": "rn"},
                    {"language": "Română", "iso": "ro"},
                    {"language": "русский", "iso": "ru"},
                    {"language": "संस्कृतम्", "iso": "sa"},
                    {"language": "sardu", "iso": "sc"},
                    {"language": "सिन्धी, سنڌي، سندھی‎", "iso": "sd"},
                    {"language": "Davvisámegiella", "iso": "se"},
                    {"language": "gagana fa'a Samoa", "iso": "sm"},
                    {"language": "yângâ tî sängö", "iso": "sg"},
                    {"language": "српски језик", "iso": "sr"},
                    {"language": "Gàidhlig", "iso": "gd"},
                    {"language": "chiShona", "iso": "sn"},
                    {"language": "සිංහල", "iso": "si"},
                    {"language": "Slovenčina, Slovenský Jazyk", "iso": "sk"},
                    {"language": "Slovenski Jezik, Slovenščina", "iso": "sl"},
                    {"language": "Soomaaliga, af Soomaali", "iso": "so"},
                    {"language": "Sesotho", "iso": "st"},
                    {"language": "Español", "iso": "es"},
                    {"language": "Basa Sunda", "iso": "su"},
                    {"language": "Kiswahili", "iso": "sw"},
                    {"language": "SiSwati", "iso": "ss"},
                    {"language": "Svenska", "iso": "sv"},
                    {"language": "தமிழ்", "iso": "ta"},
                    {"language": "తెలుగు", "iso": "te"},
                    {"language": "тоҷикӣ, toçikī, تاجیکی‎", "iso": "tg"},
                    {"language": "ไทย", "iso": "th"},
                    {"language": "ትግርኛ", "iso": "ti"},
                    {"language": "བོད་ཡིག", "iso": "bo"},
                    {"language": "Türkmen, Түркмен", "iso": "tk"},
                    {"language": "Wikang Tagalog", "iso": "tl"},
                    {"language": "Setswana", "iso": "tn"},
                    {"language": "Faka Tonga", "iso": "to"},
                    {"language": "Türkçe", "iso": "tr"},
                    {"language": "Xitsonga", "iso": "ts"},
                    {"language": "татар теле, tatar tele", "iso": "tt"},
                    {"language": "Twi", "iso": "tw"},
                    {"language": "Reo Tahiti", "iso": "ty"},
                    {"language": "ئۇيغۇرچە‎, Uyghurche", "iso": "ug"},
                    {"language": "Українська", "iso": "uk"},
                    {"language": "اردو", "iso": "ur"},
                    {"language": "Oʻzbek, Ўзбек, أۇزبېك‎", "iso": "uz"},
                    {"language": "Tshivenḓa", "iso": "ve"},
                    {"language": "Tiếng Việt", "iso": "vi"},
                    {"language": "Volapük", "iso": "vo"},
                    {"language": "Walon", "iso": "wa"},
                    {"language": "Cymraeg", "iso": "cy"},
                    {"language": "Wollof", "iso": "wo"},
                    {"language": "Frysk", "iso": "fy"},
                    {"language": "isiXhosa", "iso": "xh"},
                    {"language": "ייִדיש", "iso": "yi"},
                    {"language": "Yorùbá", "iso": "yo"},
                    {"language": "Saɯ cueŋƅ, Saw cuengh", "iso": "za"},
                    {"language": "isiZulu", "iso": "zu"}
                ],
                serp_list: ['desktop', 'mobile'],
                search_engines: ['com', 'ac', 'ad', 'ae', 'com.af', 'com.ag', 'com.ai', 'al', 'am', 'co.ao', 'com.ar', 'as', 'at', 'com.au', 'az', 'ba', 'com.bd', 'be', 'bf', 'bg', 'com.bh', 'bi', 'bj', 'com.bn', 'com.bo', 'com.br', 'bs', 'co.bw', 'by', 'com.bz', 'ca', 'com.kh', 'cc', 'cd', 'cf', 'cat', 'cg', 'ch', 'ci', 'co.ck', 'cl', 'cm', 'cn', 'cn', 'com.co', 'co.cr', 'com.cu', 'cv', 'com.cy', 'cz', 'de', 'dj', 'dk', 'dm', 'com.do', 'dz', 'com.ec', 'ee', 'com.eg', 'es', 'com.et', 'fi', 'com.fj', 'fm', 'fr', 'ga', 'ge', 'gf', 'gg', 'com.gh', 'com.gi', 'gl', 'gm', 'gp', 'gr', 'com.gt', 'gy', 'com.hk', 'hn', 'hr', 'ht', 'hu', 'co.id', 'iq', 'ie', 'co.il', 'im', 'co.in', 'io', 'is', 'it', 'je', 'com.jm', 'jo', 'co.jp', 'co.ke', 'com.kh', 'ki', 'kg', 'co.kr', 'com.kw', 'kz', 'la', 'com.lb', 'com.lc', 'li', 'lk', 'co.ls', 'lt', 'lu', 'lv', 'com.ly', 'co.ma', 'md', 'me', 'mg', 'mk', 'ml', 'mn', 'ms', 'com.mt', 'mu', 'mv', 'mw', 'com.mx', 'com.my', 'co.mz', 'com.na', 'ne', 'com.nf', 'com.ng', 'com.ni', 'nl', 'no', 'com.np', 'nr', 'nu', 'co.nz', 'com.om', 'com.pa', 'com.pe', 'com.ph', 'com.pk', 'pl', 'com.pg', 'pn', 'com.pr', 'ps', 'pt', 'com.py', 'com.qa', 'ro', 'rs', 'ru', 'rw', 'com.sa', 'com.sb', 'sc', 'se', 'com.sg', 'sh', 'si', 'sk', 'com.sl', 'sn', 'sm', 'so', 'st', 'com.sv', 'td', 'tg', 'co.th', 'com.tj', 'tk', 'tl', 'tm', 'to', 'com.tn', 'com.tr', 'tt', 'com.tw', 'co.tz', 'com.ua', 'co.ug', 'co.uk', 'com.uy', 'co.uz', 'com.vc', 'co.ve', 'vg', 'co.vi', 'com.vn', 'vu', 'ws', 'co.za', 'co.zm', 'co.zw', 'com.mm'],
                rules_main_keywords: [
                    v => !!v || 'Main keywords is required',
                    v => v !== undefined && v.trim().split('\n').length <= 25 || 'Main keywords should be less than 25',
                ],
                rules_addition_keywords: [
                    v => v !== undefined && v.trim().split('\n').length <= 50 || 'Additions keywords should be less than 50',
                ]
            }
        },
        computed: {
            active_search_engine_filter() {
                if (this.model == null) {
                    return undefined
                }
                return this.filters[this.model].fn
            },
            language() {
                return this.$store.state.stepper.s1.language
            },
            search_engine() {
                return this.$store.state.stepper.s1.search_engine
            },
            serp_type() {
                return this.$store.state.stepper.s1.serp_type
            },
            compare_url() {
                return this.$store.state.stepper.s1.compare_url
            },
            main_keywords() {
                return this.$store.state.stepper.s1.main_keywords.map(v => `${v.phrase};${v.volume}`).join("\n")
            },
            additional_keywords() {
                return this.$store.state.stepper.s1.additional_keywords.map(v => `${v.phrase};${v.volume}`).join("\n")
            },
        },
        methods: {
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab;
                this.$store.dispatch('fetchSerp');
            },
            updateLanguage(value) {
                this.$store.state.stepper.s1.language = value
            },
            updateSearchEngine(value) {
                this.$store.state.stepper.s1.search_engine = value
            },
            updateSerp(value) {
                this.$store.state.stepper.s1.serp_type = value
            },
            updateMainKeywords(value) {
                this.$store.state.stepper.s1.main_keywords = this.getArrayKeywords(value);
            },
            updateAdditionalKeywords(value) {
                this.$store.state.stepper.s1.additional_keywords = this.getArrayKeywords(value);
            },
            getArrayKeywords(value) {
                return value.split("\n").map((v) => {
                    let split = v.replace("\t", ";").split(";");
                    return {
                        phrase: split[0].toLowerCase(),
                        volume: split[1] || 10
                    }
                }).filter((v) => {
                    return v.phrase !== ''
                });
            },
            updateCompareUrl(value) {
                this.$store.state.stepper.s1.compare_url = value;
            }
        }
    }
</script>