<template>
  <v-dialog v-model="dialog" fullscreen transition="slide-y-transition" class="compare-dialog">
    <v-btn icon class="compare-dialog__close-btn" @click="dialog = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card>
      <v-card-text class="compare-dialog__body pt-5" style="overflow-x: scroll">
        <v-row dense style="flex-wrap: nowrap">

          <v-col v-for="(section, sectionIdx) in getSelectedUrlsContent" :key="section.url"
                 class="compare-dialog__section">
            <div class="section__url">
              <a :href=" section.url " target="_blank">{{ section.url }}</a>
            </div>
            <div
                v-for="(row, i) in section.structure"
                :key="i"
                :class="`section__row ${getRowClass(row[0])} ${hasChilds(i, sectionIdx)} ${getColorHeader(row[0])}`"
                :style="{'padding-left': calculatePadding(row[0])}"
                @click="$emit('addToStructure', {row, url: section.url})"
            >
              <!--              <span class="heading-item">-->
              <!--                <span v-html="row[0]"></span>-->
              <!--              </span>-->
              <!--              <span style="padding: 0 4px;"></span>-->
              <span v-html="markMainWords(row[2])"></span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Compare",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    unique_words: false,
    bannedWords: [],
  }),
  async created() {
    await this.fetchBannedWords()
  },
  watch: {
    getActiveLanguage() {
      this.fetchBannedWords()
    },
  },
  computed: {
    getActiveLanguage() {
      return this.$store.getters['getActiveLanguage']
    },
    dialog: {
      get() {
        return this.modelValue
      },
      set(value) {
        return this.$emit('update:modelValue', value)
      }
    },
    getSelectedUrlsContent() {
      return this.$store.state.stepper.s3.selected_urls
    }
  },
  methods: {
    markBannedWords(str) {
      let fixedStr = ''
      this.bannedWords.forEach(word => {
        fixedStr = str.replace(new RegExp(word, "gis"), `<span style="opacity: 0.5">${word}</span>`)
      })
      return fixedStr
    },
    async fetchBannedWords() {
      const words = await this.$store.dispatch('fetchBannedWords', {language: this.$store.state.stepper.s1.language})
      if (words) {
        this.bannedWords = words.items
      }
    },
    getColorHeader(h) {
      let colors = {
        1: 'green--text font-weight-bold'
      };
      return colors[h] !== undefined ? colors[h] : '';
    },
    calculatePadding(heading) {
      const fixedHeading = parseInt(heading)
      if (fixedHeading === 1 || fixedHeading === 2) {
        return '8px'
      }
      return fixedHeading * 8 + 'px'
    },
    markMainWords(block) {
      let marked_block = block;
      let unique_words;

      if (false === this.unique_words) {
        let words = [];

        this.$store.state.stepper.s1.main_keywords.map((v) => v.phrase.split(" ")).forEach((v) => {
          v.forEach((v) => words.push(v))
        });

        unique_words = words.filter((value, index, self) => {
          return self.indexOf(value) === index
        });
      } else {
        unique_words = this.unique_words;
      }

      for (let i = 0; i < unique_words.length; i++) {
        marked_block = marked_block.replace(new RegExp(unique_words[i], "gis"), `<span style="text-decoration: underline;">${unique_words[i]}</span>`)
      }

      return marked_block
    },
    hasChilds(idx, sectionIdx) {
      try {

        const currentHeader =
            this.getSelectedUrlsContent[sectionIdx]
                .structure[idx][0]
        const nextHeader =
            this.getSelectedUrlsContent[sectionIdx]
                .structure[idx + 1][0]

        if (parseInt(currentHeader) < parseInt(nextHeader)) {
          return 'has-child'
        }
        if (parseInt(currentHeader) > parseInt(nextHeader)) {
          return 'last-child'
        }
        return ''
      } catch {
        return 'last-child'
      }
    },
    getRowClass(heading) {
      return `h${heading}-row`
    },
  }
}
</script>

<style scoped lang="scss">
.compare-dialog {
  position: relative;

  .text--green {
    & * {
      color: #4CAF50 !important;
    }
  }

  &__body {

    & * {
      font-size: 12px !important;
    }
  }

  &__section {
    min-width: 300px;

    .main-word {
      color: green !important;
      font-size: 16px !important;
    }

    .section {
      &__h1 {
        color: green;
        margin-bottom: 20px;
      }

      &__url {
        color: #1976d2;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 10px;
        padding: 0 8px;
      }

      &__row {
        padding: 0 10px;
        border-radius: 5px;
        cursor: pointer;

        .heading-item {
          opacity: 0.5;
          transition: 150ms ease-in-out;
        }

        &:hover {
          background-color: #eeeeee;

          & * {
            opacity: 1;
          }
        }

        &:active {
          background-color: rgba(175, 175, 175, 0.57);
        }

        &.last-child {
          margin-bottom: 20px;
        }

        &.h1-row {
        }

        &.h2-row {
          & + .has-child {
            margin-top: 10px;
          }
        }
      }
    }
  }

  &__close-btn {
    position: absolute;
    z-index: 10;
    top: 7px;
    right: 7px;
  }
}
</style>