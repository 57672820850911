import axios from "axios";

export default {
    state: {
        loading: true,
        content: [],
    },
    actions: {
        fetchTextualFactors(ctx) {
            let url = `${ctx.rootState.server_url}/api/v/tt/analysis-texts`;

            let postData = {
                urls: ctx.getters.preparedSelectedUrlsForReports,
                compare_url: ctx.rootState.stepper.s1.compare_url
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            };

            axios.post(url, postData, config)
                .then((response) => {
                    ctx.commit('updateTextualFactorsContent', response.data);
                })
                .catch(() => ctx.rootState.sheet = true);
        }
    },
    mutations: {
        updateTextualFactorsContent(state, value) {
            state.content = value
        }
    }
}