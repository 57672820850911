<template>
    <v-card
            class="mx-auto"
            raised
    >
        <v-card-title>
            General Information
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <p><strong>Text-size:</strong> {{ additionsS3[0] }} - {{ additionsS3[1] }}</p>
                    <p><strong>Text type:</strong> {{ dataS6.text_type }}</p>
                </v-col>
                <v-col>
                    <p><strong>Tonality of the text:</strong> {{ dataS6.tonality_of_the_text }}</p>
                    <p><strong>Need lists:</strong> {{ dataS6.condition_have_lists ? 'Yes' : 'No' }}</p>
                    <p><strong>Need tables:</strong> {{ dataS6.condition_have_table ? 'Yes' : 'No' }}</p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "GeneralInfo",
        computed: {
            dataS6() {
                return this.$store.state.stepper.s6
            },
            additionsS3(){
                return this.$store.state.stepper.s3.additions
            }
        }
    }
</script>

<style scoped>

</style>