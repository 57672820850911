import Editor from '../components/Editor';
import Stepper from '../components/Stepper/Stepper';
import TextualFactorAnalysis from '../components/TextualFactorAnalysis/Stepper';
import Home from '../components/Pages/Home';
import Login from '../components/Pages/Login';
import Register from '../components/Pages/Register';
import Welcome from '../components/Pages/Welcome';
import Compare from "../components/Compare";

import Vue from 'vue'
import VueRouter from "vue-router";
import store from '../store'

import auth from './middleware/auth'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: '/editor/:user_id/:hash',
            name: 'editor',
            component: Editor,
        },
        {
            path: '/stepper',
            name: 'stepper',
            component: Stepper,
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/compare',
            name: 'Compare',
            component: Compare,
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/textual_factor_analysis',
            name: 'textual_factor_analysis',
            component: TextualFactorAnalysis,
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: Welcome,
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/',
            component: Home,
            name: 'home',
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router