import axios from "axios";

export default {
    state: {
        token: typeof localStorage.getItem('token') === 'string' && localStorage.getItem('token') !== 'undefined undefined' ? localStorage.getItem('token') : null,
        user: typeof localStorage.getItem('user') === 'string' && localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : {},
        response: {
            msg: null,
            code: null
        },
        response_register: {
            msg: null,
            code: null
        },
        loading: false
    },
    getters: {
        isAuthenticated: state => !!state.token,
    },
    actions: {
        login(ctx, options) {
            let url = `${ctx.rootState.server_url}/api/login`;

            let postData = {
                email: options.email,
                password: options.password
            };

            axios.post(url, postData)
                .then((response) => {
                    let token = `${response.data.token_type} ${response.data.token}`;
                    ctx.commit('updateAuthUser', response.data.user);
                    localStorage.setItem('token', token);
                    ctx.rootState.auth.token = token;
                    options.then();
                })
                .catch((error) => {
                    if (error.response) {
                        ctx.state.response.msg = error.response.data.message;
                    }
                    ctx.rootState.sheet = true;
                    options.catch();
                })
                .finally(() => {
                    ctx.state.loading = false;
                });
        },
        register(ctx, options) {
            let url = `${ctx.rootState.server_url}/api/register`;

            let postData = {
                name: options.name,
                email: options.email,
                password: options.password
            };

            axios.post(url, postData)
                .then(() => {
                    options.then();
                })
                .catch((error) => {
                    if (error.response) {
                        ctx.state.response_register.msg = error.response.data.message;
                    }
                    ctx.rootState.sheet = true;
                    options.catch();
                });
        },
        logout(ctx, callback) {
            ctx.rootState.auth.token = null;
            localStorage.removeItem('token');
            callback();
        }
    },
    mutations: {
        updateAuthUser(state, data) {
            let user = JSON.stringify(data);
            localStorage.setItem('user', user);

            state.user = data;
        }
    }
}