<template>
  <div>
    <v-stepper-content :step="step">
      <v-alert
          class="mx-3"
          text
          prominent
          color="blue-grey"
          icon="mdi-information-outline"
      >
        You can change words count or add your own keywords, if you need.
      </v-alert>
      <v-card
          class="mb-12 mt-2 mx-3"
          :loading="loading"
          raised
      >
        <!--                <v-card-text v-if="content.length > 0">-->
        <v-card-title>
          <v-text-field
              class="pt-0 mt-0"
              v-model="search"
              label="Search"
              solo
              prepend-inner-icon="mdi-magnify"
              hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
              v-model="selected_phrases"
              :headers="tableHeader"
              :items="content"
              :custom-sort="customSort"
              :custom-filter="custom_filter"
              :search="search"
              item-key="ph"
              show-select
              class="elevation-1"
              :items-per-page="10"
              :footer-props="{'items-per-page-options': [10, 50, 100, 200, -1]}"
              dense
          >
            <template v-slot:header.our="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>Compare Url</span>
              </v-tooltip>
            </template>
            <template v-slot:header.rwc="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>Recommended Word Count</span>
              </v-tooltip>
            </template>
            <template v-slot:header.rwc_med="{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>Recommended Word Count (Median)</span>
              </v-tooltip>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item.ph">
                <td>
                  <v-icon
                      v-if="parseInt(item.rwc_min) > parseInt(item.rwc_max) || content_conflict.filter((v) => v.ph === item.ph).length !== 0"
                      size="24"
                      color="error">mdi-alert-circle-outline
                  </v-icon>
                  <v-checkbox
                      v-else
                      class="mt-0"
                      hide-details
                      v-model="selected_phrases"
                      :value="item"
                      show-select
                  />
                </td>
                <td>
                  {{ item.ph }}
                  <v-tooltip bottom v-for="i in item.resources" :key="i">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                          class="ma-2"
                          small
                          :color="get_resource_label(i).color"
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ get_resource_label(i).label }}
                      </v-chip>
                    </template>
                    <span>{{ get_resource_label(i).tooltip }}</span>
                  </v-tooltip>
                  <v-btn text icon color="grey" @click="examples_of_using(item.ph)">
                    <v-icon size="16">mdi-comment-question</v-icon>
                  </v-btn>
                  <v-btn text icon @click="updateFavorite(item)">
                    <v-icon v-if="item.favorite || favorites.indexOf(item.ph) !== -1" color="orange">mdi-star</v-icon>
                    <v-icon v-else>mdi-star-outline</v-icon>
                  </v-btn>
                </td>
                <td class="text-center">{{ Math.round(item.docs * 100 / size_child) }}%</td>
                <td class="text-center" v-if="visibleCompareUrl">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }"><span v-on="on">{{ item.our[0] }}</span></template>
                    <span>
                      Title: {{ compare_text.title }}<br>
                      H1: {{ compare_text.h1 }}<br>
                      Url: {{ compare_text.url }}<br>
                      Text Length: {{ compare_text.length }}
                    </span>
                  </v-tooltip>
                  <v-icon
                      size="14"
                      :color="getOurIcon(item, 0).color"
                      v-html="getOurIcon(item, 0).icon"
                  ></v-icon>
                  <span v-if="item.our[0] !== 0 || getOurIcon(item, 1).icon !== 'mdi-arrow-up-thick'">
                       <br>
                      {{ item.our[1] }}%
                      <v-icon
                          size="14"
                          :color="getOurIcon(item, 1).color"
                          v-html="getOurIcon(item, 1).icon"
                      ></v-icon>
                  </span>
                </td>
                <td class="text-center">
                  {{ Math.floor(item.med[0]) }}<br>{{ item.med[1] }}%
                </td>
                <td class="text-center" style="white-space: nowrap">
                  <v-text-field
                      label="Min"
                      type="number"
                      min="0"
                      v-model="item.rwc_min"
                      @input="find_conflict()"
                      dense
                      single-line
                      style="max-width: 60px; display: inline-block;"
                  ></v-text-field>
                  <v-text-field
                      label="Max"
                      type="number"
                      min="0"
                      v-model="item.rwc_max"
                      @input="find_conflict()"
                      dense
                      single-line
                      style="max-width: 60px; display: inline-block;"
                      class="ml-2"
                  ></v-text-field>
                </td>
                <td v-for="(v, k) in item.child.slice(0, max_headers)" v-bind:key="k"
                    :class="getClass(v, 'child').color"
                    class="text-center">
                  {{ v[0] }}<br>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }"><span v-on="on">{{ v[1] }}%</span>
                    </template>
                    <span>Text Length: {{ item.headers[k].length }}<br>Url: {{
                        item.headers[k].url
                      }}<br>Additions: {{ additions[0] }}</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
          <div v-if="disabled_next_step">
            <h3 class="title my-4">Conflict Phrases</h3>
            <v-alert
                text
                prominent
                type="error"
            >
              <v-row align="center">
                <v-col class="grow">
                  Please, fix range conflict
                </v-col>
                <v-col class="shrink">
                  <v-btn color="error" @click="disabled_next_step_manual = !disabled_next_step_manual">Ignore</v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <v-text-field
                v-model="search_conflict"
                label="Search"
                solo
                prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-data-table
                :headers="tableHeader"
                :items="content_conflict"
                :search="search_conflict"
                :custom-sort="customSort"
                item-key="ph"
                show-select
                class="elevation-1"
            >
              <template v-slot:header.our="{ header }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>Compare Url</span>
                </v-tooltip>
              </template>
              <template v-slot:header.rwc="{ header }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>Recommended Word Count</span>
                </v-tooltip>
              </template>
              <template v-slot:header.rwc_med="{ header }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>Recommended Word Count (Median)</span>
                </v-tooltip>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                <tr v-for="item in items" :key="item.ph">
                  <td>
                    <v-checkbox class="mt-0" hide-details v-model="selected_phrases" :value="item"
                                show-select/>
                  </td>
                  <td>{{ item.ph }}</td>
                  <td class="text-center">{{ Math.round(item.docs * 100 / size_child) }}%</td>
                  <td class="text-center" v-if="visibleCompareUrl">
                    {{ item.our[0] }}
                    <v-icon
                        size="14"
                        :color="getOurIcon(item, 0).color"
                        v-html="getOurIcon(item, 0).icon"
                    ></v-icon>
                    <span v-if="item.our[0] !== 0 || getOurIcon(item, 1).icon !== 'mdi-arrow-up-thick'">
                                 <br>
                                {{ item.our[1] }}%
                                <v-icon
                                    size="14"
                                    :color="getOurIcon(item, 1).color"
                                    v-html="getOurIcon(item, 1).icon"
                                ></v-icon>
                            </span>
                  </td>
                  <td class="text-center">
                    {{ Math.floor(item.med[0]) }}<br>{{ item.med[1] }}%
                  </td>
                  <td class="text-center">
                    <v-row>

                      <v-col cols="12" sm="6">
                        <v-text-field
                            label="Min"
                            type="number"
                            min="0"
                            v-model="item.rwc_min"
                            @input="find_conflict"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                            label="Max"
                            type="number"
                            min="0"
                            v-model="item.rwc_max"
                            @input="find_conflict"
                        ></v-text-field>
                      </v-col>

                    </v-row>
                  </td>
                  <td v-for="(v, k) in item.child.slice(0, max_headers)" v-bind:key="k"
                      :class="getClass(v, 'child').color"
                      class="text-center">
                    {{ v[0] }}<br>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }"><span v-on="on">{{ v[1] }}%</span>
                      </template>
                      <span>Text Length: {{ item.headers[k].length }}<br>Url: {{
                          item.headers[k].url
                        }}<br>Additions: {{ additions[0] }}</span>
                    </v-tooltip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
          <h3 class="title my-4">Add more phrases</h3>
          <v-row>

            <v-col cols="12" sm="5">
              <v-text-field
                  label="Keyword"
                  v-model="custom_keyword"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                  label="Min"
                  type="number"
                  min="0"
                  v-model="custom_keyword_min"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                  label="Max"
                  type="number"
                  min="0"
                  v-model="custom_keyword_max"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="1">
              <v-btn @click="addCustomKeyword" color="primary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

          </v-row>
          <h3 class="title my-4">Custom phrases</h3>
          <v-data-table
              v-model="custom_selected_phrases"
              :headers="custom_headers"
              :items="custom_content"
              item-key="ph"
              show-select
              class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
              <tr v-for="item in items" :key="item.ph">
                <td>
                  <v-checkbox class="mt-0" hide-details v-model="custom_selected_phrases"
                              :value="item"
                              show-select/>
                </td>
                <td>{{ item.keyword }}</td>
                <td class="text-center">
                  {{ item.min }} - {{ item.max }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-bottom-sheet v-model="e_sheet" inset :hide-overlay="true" :persistent="false">
            <v-alert
                prominent
                border="top"
                colored-border
                color="white"
                class="mb-0"
            >
              <v-container style="height: 200px;" v-if="e_sheet_loading === false">
                <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                >
                  <v-col
                      class="subtitle-1 text-center"
                      cols="12"
                  >
                    Getting examples of using
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear
                        color="info"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-container>
              <v-row align="center" v-else>
                <v-col class="grow" v-html="e_sheet_message"></v-col>
              </v-row>
            </v-alert>
          </v-bottom-sheet>
        </v-card-text>
      </v-card>
      <v-btn @click="setCurrentTab(step - 1)" text>back to keywords</v-btn>
      <v-btn color="primary" @click="setCurrentTab(step + 1)" class="float-right"
             :disabled="disabled_next_step">Choose keywords for the first 150
        words
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import {calcMinMaxMixin} from "../../../mixins/calcMinMaxEntryMixin";
import axios from "axios";

export default {
  name: "Step",
  mixins: [calcMinMaxMixin],
  data() {
    return {
      e_sheet: false,
      e_sheet_loading: false,
      sheet: false,
      sheet_message: "Placeholder",
      e_sheet_message: "Placeholder",
      search: '',
      search_conflict: '',
      loading: false,
      step: 6,
      custom_keyword: null,
      custom_keyword_min: null,
      custom_keyword_max: null,
      custom_content: [],
      content_conflict_arr: [],
      custom_headers: [
        {text: 'Keyword', value: 'keyword'},
        {text: 'RWC', value: 'rwc', align: 'center'},
      ],
      favorites: [],
      disabled_next_step_manual: true
    }
  },
  watch: {
    content() {
      this.find_conflict()
    }
  },
  methods: {
    get_resource_label(resource) {
      switch (resource) {
        case 'MainPhrasesBySerp':
          return {color: 'primary', label: 'M', tooltip: 'Main Phrases'}
        case 'AdditionalPhrasesByVision':
          return {color: '', label: 'A', tooltip: 'Additional Phrases'}
        case 'KeywordsFromMainPhrasesByVision':
          return {color: '', label: 'V', tooltip: 'Keywords From Main Phrases'}
        case 'Snippet':
          return {color: 'info', label: 'SN', tooltip: 'Snippet'}
        case 'Related':
          return {color: 'info', label: 'R', tooltip: 'Related'}
        case 'Suggest':
          return {color: 'info', label: 'SU', tooltip: 'Suggest'}
        case 'WithoutStopWords':
          return {color: 'error', label: 'SW', tooltip: 'Without Stop Words'}
        case 'ByWords':
          return {color: '', label: 'W', tooltip: 'By Words'}
        default:
          return {color: '', label: '', tooltip: ''}
      }
    },
    examples_of_using(phrase) {
      let self = this;

      self.e_sheet = true;
      self.e_sheet_loading = false;
      self.e_sheet_message = "Wait a few seconds";

      let url = `${self.$store.state.server_url}/api/v/tt/examples-of-using`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      };

      let post_data = {
        phrase: phrase,
        urls: this.$store.state.stepper.s3.selected_urls
      };

      axios.post(url, post_data, config)
          .then((response) => {
            self.e_sheet_loading = true;
            self.e_sheet_message = '<ol>' + response.data.map((v) => `<li>${v.replace(new RegExp(phrase, 'gsi'), `<b>${phrase}</b>`)}</li>`).join('') + '</ol>';
          });
    },
    addCustomKeyword() {
      let item = {
        keyword: this.custom_keyword,
        min: this.custom_keyword_min,
        max: this.custom_keyword_max,
      };

      this.custom_content.push(item);
      this.custom_selected_phrases.push(item);
    },
    setCurrentTab(tab) {
      this.$store.state.stepper.current_tab = tab;
      this.$store.dispatch('fetchResult');
    },
    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.compare(a, b);
        } else {
          return this.compare(b, a);
        }
      });
      return items;
    },
    custom_filter(value, search, item) {
      let search_items = [];
      search = search.toString().toLowerCase()

      if (search.indexOf('+') !== -1) {
        search_items = search.split('+').map((v) => v.trim())
      }

      if (search_items.length === 0) {
        return item.ph.indexOf(search) !== -1
      }

      return search_items.filter((v) => item.ph.indexOf(v) === -1).length === 0
    },
    compare(a, b) {
      let aLen = a.ph.length;
      let bLen = b.ph.length;

      if (a.favorite === b.favorite) {
        if (a.docs === b.docs) {
          if (a.med[0] === b.med[0]) {
            if (aLen === bLen) {
              return 0
            } else {
              return aLen < bLen ? -1 : 1
            }
          } else {
            return a.med[0] < b.med[0] ? -1 : 1
          }
        } else {
          return a.docs < b.docs ? -1 : 1
        }
      } else {
        return a.favorite < b.favorite ? -1 : 1
      }
    },
    getOurIcon(item, type) {
      let icon = '';
      let color = '';

      if (type === 0) {
        let entry = item.our[0];

        let minRwc = this.calcMinEntry(item.med, item.ph.split(" ").length);
        let maxRwc = this.calcMaxEntry(item.med, item.ph.split(" ").length);

        let delta_min = Math.abs((entry - minRwc) * 100 / minRwc);
        let delta_max = Math.abs((entry - maxRwc) * 100 / maxRwc);

        if (item.our[1] === item.med[1]) {
          color = 'success';
        } else if (entry > maxRwc && delta_max > 15 && delta_max <= 30) {
          icon = 'mdi-arrow-down-right-thick';
          color = 'warning';
        } else if (entry > maxRwc && delta_min > delta_max && delta_max > 30) {
          icon = 'mdi-arrow-down-thick';
          color = 'error';
        } else if (entry < minRwc && delta_min > 15 && delta_min <= 30) {
          icon = 'mdi-arrow-up-thick';
          color = 'warning';
        } else if (entry < minRwc && delta_min > 30) {
          icon = 'mdi-arrow-up-thick';
          color = 'error';
        } else if (entry > minRwc && entry < maxRwc) {
          color = 'success';
        } else if ((entry < minRwc && delta_min < 15) || (entry > maxRwc && delta_max < 15)) {
          icon = '';
        }
      } else {
        let entry = item.our[1];
        let median = item.med[1];

        let delta = Math.abs((entry - median) * 100 / median);

        if ((entry > median && delta < 15) || (entry < median && delta < 15)) {
          icon = '';
        } else if (entry > median && delta > 15 && delta <= 30) {
          icon = 'mdi-arrow-down-right-thick';
          color = 'warning';
        } else if (entry > median && delta > 30) {
          icon = 'mdi-arrow-down-thick';
          color = 'error';
        } else if (entry < median && median > 15 && median <= 30) {
          icon = 'mdi-arrow-up-right-thick';
          color = 'warning';
        } else if (entry < median && delta > 30) {
          icon = 'mdi-arrow-up-thick';
          color = 'error';
        } else if (entry === median) {
          color = 'success';
        }
      }

      return {
        icon: icon,
        color: color
      };
    },
    getClass(item, type = 'rwc') {
      let self = this;
      let result = {};
      let colors = {
        success: 'light-green lighten-4',
        danger: 'amber lighten-4'
      };
      let minAvgRec,
          minMedianRec;

      if (type === 'rwc') {
        minAvgRec = Math.round(item.avg[1] / 100 * self.additions[0]);
        minMedianRec = Math.round(item.med[1] / 100 * self.additions[0]);
        // maxRWC = Math.round(item.avg[1] / 100 * self.additions[1]);

        if (item.our[0] === 0 && (minAvgRec >= 1 || minMedianRec >= 1)) {
          result.color = colors.danger;
        }

        if (item.our[0] < item.avg[0]) {
          result.color = colors.danger;
        }

        if (item.our[0] > item.avg[0]) {
          result.colorRWC = colors.danger;
        }
      } else if (type === 'child') {
        result.color = item[0] > 0 ? colors.success : ''
      }

      return result
    },
    comparePhrases(haystack, needle) {
      let base_p = haystack.ph
      let cond1

      try {
        cond1 = base_p.match(new RegExp(new RegExp(`\\b${needle.ph}\\b`), 'is')) != null
      } catch (e) {
        cond1 = false;
      }
      let cond2 = base_p !== needle.ph
      let cond3 = parseInt(haystack.rwc_min) > parseInt(needle.rwc_max)

      return cond1 && cond2 && cond3
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    find_conflict() {
      let self = this

      let content_short = self.content.map((v) => {
        return {
          ph: v.ph,
          rwc_max: parseInt(v.rwc_max),
          rwc_min: parseInt(v.rwc_min)
        }
      })

      let content_short_2 = content_short.map((v) => {
        let item = v
        item.similar = content_short
            .filter((i) => i.ph.match(new RegExp(new RegExp(`\\b${v.ph}\\b`), 'is')) !== null && i.ph !== v.ph)
            .map((v) => {
              return {
                ph: v.ph,
                rwc_min: parseInt(v.rwc_min),
                rwc_max: parseInt(v.rwc_max)
              }
            })
        let sum_rwc_min = v.similar.map((i) => parseInt(i.rwc_min))
        item.conflict = sum_rwc_min.length > 0 ? sum_rwc_min.reduce((a, b) => a + b, 0) > parseInt(v.rwc_max) : false
        return item
      })

      let content_short_3 = content_short_2.filter((v) => v.conflict)

      let stack_phrases = []

      content_short_3.map((v) => {
        if (stack_phrases.indexOf(v.ph) === -1) {
          stack_phrases.push(v.ph)
        }

        v.similar.map((v) => {
          if (stack_phrases.indexOf(v.ph) === -1) {
            stack_phrases.push(v.ph)
          }
        })
      })

      self.content_conflict = self.content.filter((v) => stack_phrases.indexOf(v.ph) !== -1 || parseInt(v.rwc_min) > parseInt(v.rwc_max))
    },
    content_conflict_update(item) {
      let content = this.$store.state.stepper.processing.content;
      let result = []
      let stack = []

      if (parseInt(item.rwc_min) > parseInt(item.rwc_max)) {
        result.push(item)
        stack.push(item.ph)
      }

      for (let i in content) {
        let step1 = content.filter((v) => this.comparePhrases(content[i], v))
        for (let i2 in step1) {
          if (stack.indexOf(content[i].ph) === -1) {
            result.push(content[i])
            stack.push(content[i].ph)
          }
          if (stack.indexOf(step1[i2].ph) === -1) {
            result.push(step1[i2])
            stack.push(step1[i2].ph)
          }
        }
      }

      this.content_conflict = result
    },
    updateFavorite(item) {
      item.favorite = !item.favorite

      if (item.favorite) {
        this.favorites.push(item.ph)
      } else {
        this.favorites = this.favorites.filter((v) => v !== item.ph)
      }
    }
  },
  computed: {
    disabled_next_step(){
      return this.content_conflict.length > 0 && this.disabled_next_step_manual
    },
    content_conflict: {
      get() {
        return this.content_conflict_arr
      },
      set(newValue) {
        this.content_conflict_arr = newValue
      }
    },
    custom_selected_phrases: {
      get() {
        return this.$store.state.stepper.processing.custom_selected_phrases
      },
      set(newValue) {
        this.$store.state.stepper.processing.custom_selected_phrases = newValue
      },
    },
    content: {
      get() {
        let content = this.$store.state.stepper.processing.content;

        return content.map((v) => {
          let rwc_min = this.calcMinEntry(v.med, v.ph.split(" ").length);
          let rwc_max = this.calcMaxEntry(v.med, v.ph.split(" ").length);

          v.rwc_min = rwc_min === 0 ? 1 : parseInt(rwc_min);
          v.rwc_max = rwc_max === 0 ? 1 : parseInt(rwc_max);

          return v;
        })
      },
      set(newValue) {
        this.$store.state.stepper.processing.content = newValue
      }
    },
    tableHeader() {
      let self = this;
      let columns = [
        {text: 'Phrase', value: 'ph'},
        {text: `Popularity`, value: 'docs', sortable: true, align: 'center'},
        {text: 'CU', value: 'our', align: 'center', width: '90px'},
        {text: 'Median', value: 'med', align: 'center'},
        {text: 'RWC', value: 'rwc_med', align: 'center', sortable: true, width: '180px'},
      ].filter((v) => {
        if (false === self.visibleCompareUrl) {
          return v.value !== 'our'
        }
        return true
      });

      if (this.size_child > 0 && this.content.length > 0) {
        let headers = self.content[self.content.length - 1].headers;
        for (let i in headers) {
          let visibility = 0;
          if (this.$store.state.stepper.s2.visionByUrl[headers[i].url.trim()] !== undefined) {
            visibility = Math.round(this.$store.state.stepper.s2.visionByUrl[headers[i].url.trim()] * 100 / this.$store.getters.amountVisibility)
          }
          columns.push({text: `${visibility}%`, value: `p${i + 1}`, align: 'center'});
        }
      }

      return columns
    },
    selected_phrases: {
      get() {
        return this.$store.state.stepper.processing.selected_phrases
      },
      set(newValue) {
        this.$store.state.stepper.processing.selected_phrases = newValue
      }
    },
    additions() {
      return this.$store.state.stepper.s3.additions
    },
    max_headers() {
      if (this.size_child > 0 && this.content.length > 0) {
        return this.content[this.content.length - 1].headers.length
      }
      return 0
    },
    visibleCompareUrl() {
      return this.$store.getters.hasCompareUrl
    },
    size_child() {
      return this.$store.state.stepper.s3.selected_urls.length
    },
    compare_text() {
      return this.$store.state.stepper.s3.compare_text
    }
  }
}
</script>

<style scoped>
td {
  white-space: nowrap;
}
</style>