import axios from "axios";

export default {
    state: {},
    actions: {
        fetchByWords(ctx, options) {
            if (options.words === undefined) {
                return;
            }

            if (ctx.rootState.enable_test_data) {
                setTimeout(function () {
                    let data = JSON.parse('[{"ph":"papersowl review","vol":1500,"avg":[1,0.3],"med":[1,0.3],"our":[0,0],"docs":2,"headers":[{"vision":1,"url":"https:\\/\\/www.topdissertations.org\\/papersowl-review\\/","length":1222},{"vision":1,"url":"https:\\/\\/awriter.org\\/papersowl-com-review\\/","length":549},{"vision":1,"url":"https:\\/\\/scamfighter.net\\/review\\/papersowl.com","length":1208},{"vision":1,"url":"https:\\/\\/reviewingwriting.com\\/papersowl-review\\/","length":0},{"vision":1,"url":"https:\\/\\/www.sitejabber.com\\/reviews\\/papersowl.com","length":0},{"vision":1,"url":"https:\\/\\/paidpaper.net\\/papersowl-com-review\\/","length":0},{"vision":1,"url":"https:\\/\\/www.reddit.com\\/r\\/ApplyingToCollege\\/comments\\/dbwj66\\/papersowl_review\\/","length":0},{"vision":1,"url":"https:\\/\\/papersowl.com\\/what-is-papersowl","length":0},{"vision":1,"url":"https:\\/\\/papersowl.com\\/essay-writing-service-review","length":0},{"vision":1,"url":"https:\\/\\/www.quora.com\\/Is-Papersowl-legit","length":0},{"vision":1,"url":"https:\\/\\/sky-writer.com\\/papersowl-review\\/","length":0},{"vision":1,"url":"https:\\/\\/typemyessays.com\\/blog\\/papersowl-review","length":0},{"vision":1,"url":"https:\\/\\/essayservicescanner.com\\/papersowl-review\\/","length":0},{"vision":1,"url":"https:\\/\\/www.writingpapersucks.com\\/papersowl-com-review\\/","length":0},{"vision":1,"url":"https:\\/\\/essayreviewexpert.com\\/review\\/papersowl\\/","length":0}],"child":[[1,0.2],[1,0.4],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]]}]');

                    ctx.commit(`updateByWords${options.words}`, data);
                    ctx.commit(`updateLoading${options.words}`, false);
                    ctx.rootState.stepper.s4.ignore_keywords = ctx.rootState.stepper.s4.ignore_keywords.concat(data.map(i => i.ph));

                    if (options.words > 4 && options.ignore) {
                        ctx.dispatch(`fetchByWords`, {words: options.words - 1, ignore: options.ignore});
                    } else if (options.words === 4) {
                        ctx.dispatch(`fetchByWords`, {words: 1, ignore: options.ignore});
                    }
                }, 1000);
                return;
            }

            let url = `${ctx.rootState.server_url}/api/v/tt/density-by-words/${options.words}`;
            ctx.commit(`updateLoading${options.words}`, true);

            let postData = {
                urls: ctx.getters.preparedSelectedUrlsForReports,
                language: ctx.rootState.stepper.s1.language,
                compare_url: ctx.rootState.stepper.s1.compare_url,
                disable_stop_words: ctx.rootState.stepper.s4.disable_stop_words,
                ignore_keywords: ctx.rootState.stepper.s4.ignore_keywords,
                ignore: ctx.rootState.stepper.s4.ignore_parent_keywords,
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            };

            let condition_lang = ctx.rootState.stepper.s1.language !== 'ja';

            axios.post(url, postData, config)
                .then((response) => {
                    ctx.commit(`updateByWords${options.words}`, response.data);
                    ctx.commit(`updateLoading${options.words}`, false);

                    ctx.rootState.stepper.s4.ignore_keywords = ctx.rootState.stepper.s4.ignore_keywords.concat(response.data.map((i) => {
                        return {
                            ph: i.ph,
                            med: i.med
                        }
                    }));

                    if (options.words > 4 && options.ignore) {
                        ctx.dispatch(`fetchByWords`, {words: options.words - 1, ignore: options.ignore});
                    } else if (options.words === 4 && condition_lang) {
                        ctx.dispatch(`fetchByWords`, {words: 1, ignore: options.ignore});
                    } else if (options.words === 4 && !condition_lang) {
                        ctx.commit(`updateLoading1`, false);
                        ctx.commit('updateLoadingKeywordsFromMainPhrasesByVision', false);
                    }
                })
                .catch(() => ctx.rootState.sheet = true);
        }
    },
};