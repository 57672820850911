<template>
    <div>
        <v-stepper-content :step="step">
            <v-alert
                    class="mx-3"
                    text
                    prominent
                    color="blue-grey"
                    icon="mdi-information-outline"
            >
                Create a structure for the future text based on competitors or add your own.
            </v-alert>
            <v-row justify="center">
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-switch v-model="add_link_to_structure" class="mx-2"
                          label="Add link to Structure"></v-switch>
                <v-switch v-model="add_translate_to_structure" class="mx-2"
                          label="Add translate to Structure"></v-switch>
                <v-btn small color="primary" class="mx-2" outlined @click="goToComparePage">
                  <v-icon small class="mr-2">mdi-compare</v-icon>
                  Compare
                </v-btn>
              </v-col>
            </v-row>
            <v-card
                    class="mx-auto mb-12 mt-2"
                    max-width="1000"
                    scrollable
                    raised
                    style="overflow: auto"
            >
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                    cols="6"
                            >
                                <div v-for="row in content" v-bind:key="row.url" class="mb-2">
                                    <a class="subtitle-1 font-weight-bold" :href="row.url" target="_blank">{{ row.url }}
                                        <v-icon size="15">mdi-open-in-new</v-icon>
                                    </a>
                                    <ul class="pa-0">
                                        <li
                                                v-for="block in row.structure" v-bind:key="block[2]"
                                                :class="['cursor-pointer']"
                                                :style="`display: grid; grid-template-columns: 80px ${(block[0] - 1) * 40}px auto`"
                                        >
                                            <span>
                                                <v-btn text icon color="grey" @click="translate(block[2])">
                                                    <v-icon size="16">mdi-google-translate</v-icon>
                                                </v-btn>
                                                <v-btn text icon color="grey"
                                                       @click="addToStructure(`${block[1]}h${block[0]} ${block[2]}`, row.url, $event)">
                                                    <v-icon size="16">mdi-plus</v-icon>
                                                </v-btn>
                                            </span>
                                            <span></span>
                                            <!--                                            <span v-html="prepareBlock(block)" :class="getColorHeader(block)"></span>-->
                                            <span style="align-content: center; display: grid"
                                                  @click="addToStructure(`${block[1]}h${block[0]} ${block[2]}`, row.url, $event)"
                                                  :class="getColorHeader(block[0])">
                                                <span><span
                                                        style="opacity: 0.6;">h{{block[0]}}</span> <span v-html="mark_main_words(block[2])"></span></span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                            <v-col
                                    cols="6"
                            >
                                <v-textarea
                                        rows="30"
                                        name="structure"
                                        label="Structure"
                                        :class="[{'fixed-textarea': fixed_textarea}]"
                                        :value="structure"
                                        @input="updateStructure"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <v-bottom-sheet v-model="sheet" inset :hide-overlay="true" :persistent="false">
                <v-alert
                        prominent
                        type="info"
                        icon="mdi-google-translate"
                        class="mb-0"
                >
                    <v-row align="center">
                        <v-col class="grow" v-html="sheet_message"></v-col>
                        <v-col class="shrink">
                            <v-btn
                                    v-on:click="sheet = false"
                                    color="white"
                                    outlined
                            >Okay
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-bottom-sheet>
            <v-btn @click="setCurrentTab(step - 1)" text>back to texts</v-btn>
            <v-btn color="primary" @click="setCurrentTab(step + 1)" class="float-right">Choose keywords</v-btn>
        </v-stepper-content>
      <compare :model-value="compare_dialog" @update:modelValue="compare_dialog = $event" @addToStructure="handleAddToStructure"/>
    </div>
</template>

<script>
    import axios from "axios";
    import Compare from "../../Compare";

    export default {
        name: "Step",
      components: {Compare},
      data() {
            return {
              compare_dialog: false,
              sheet: false,
              sheet_message: "Placeholder",
              add_link_to_structure: true,
              add_translate_to_structure: false,
              settings: [],
              panel: [],
              step: 4,
              fixed_textarea: false,
              unique_words: false
            }
        },
        methods: {
          handleAddToStructure({row, url}){
            const fixedRow = `${row[1]}h${row[0]} ${row[2]}`
            this.addToStructure(fixedRow, url, null)
          },
          goToComparePage() {
            this.compare_dialog = true
          },
            mark_main_words(block) {
                let marked_block = block;
                let unique_words;

                if (false === this.unique_words) {
                    let words = [];

                    this.$store.state.stepper.s1.main_keywords.map((v) => v.phrase.split(" ")).forEach((v) => {
                        v.forEach((v) => words.push(v))
                    });

                    unique_words = words.filter((value, index, self) => {
                        return self.indexOf(value) === index
                    });
                } else {
                    unique_words = this.unique_words;
                }

                for (let i = 0; i < unique_words.length; i++) {
                    marked_block = marked_block.replace(new RegExp(unique_words[i],"gis"), `<b>${unique_words[i]}</b>`)
                }

                return marked_block
            },
            translate(string, callback = false) {
                let self = this;

                self.sheet = false;
                self.sheet_message = "Wait a few seconds";

                let url = `${self.$store.state.server_url}/api/v/tt/get-translation`;

                let config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': self.$store.state.auth.token
                    },
                    params: {
                        lang_source: self.$store.state.stepper.s1.language,
                        lang_target: self.$store.state.stepper.s1.translation_language,
                        string: string
                    }
                };

                if (callback !== false) {
                    axios.get(url, config)
                        .then((response) => {
                            callback(response.data.translation)
                        })
                } else {
                    axios.get(url, config)
                        .then((response) => {
                            self.sheet = true;
                            self.sheet_message = `<b>Original:</b> ${string} <br><b>Translation:</b> ${response.data.translation}`;
                        });
                }
            },
            updateStructure(value) {
                this.structure = value
            },
            handleScroll() {
                this.fixed_textarea = window.scrollY > 210;
            },
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab;
                let self = this;

                self.$vuetify.goTo(0, {
                    duration: 300,
                    offset: 0,
                    easing: 'easeInOutCubic',
                });
            },
            addToStructure(block, url, event) {
                if(event) {
                    event.target.classList.add('grey--text');
                    event.target.classList.add('text--lighten-3');
                }
                let urlPrepare = '';
                let self = this;

                if (this.add_link_to_structure) {
                    urlPrepare = `(${url})`;
                }
                if (this.add_translate_to_structure) {
                    this.translate(block, function (translate) {
                        self.structure += `${block} (${translate}) ${urlPrepare}\n`.replace(self.getRegexSpace(), '\t');
                    });
                } else {
                    self.structure += `${block} ${urlPrepare}\n`.replace(self.getRegexSpace(), '\t');
                }
            },
            prepareBlock(block) {
                return block.replace(this.getRegexSpace(), '&emsp;&emsp;&emsp;&emsp;')
            },
            getColorHeader(h) {
                let colors = {
                    1: 'green--text font-weight-bold'
                };
                return colors[h] !== undefined ? colors[h] : '';
            },
            getRegexSpace() {
                let find = '&emsp;';
                return new RegExp(find, 'g');
            }
        },
        computed: {
            structure: {
                get() {
                    return this.$store.state.stepper.s3.structure
                },
                set(value) {
                    this.$store.state.stepper.s3.structure = value
                }
            },
            content() {
              return this.$store.state.stepper.s3.selected_urls
            }
        },
    }
</script>

<style scoped>
    .cursor-pointer {
        cursor: pointer;
    }

    .cursor-pointer:hover {
        background-color: #F5F5F5;
    }

    .fixed-textarea {
        position: fixed;
        top: 10px;
        width: 400px;
    }
</style>