<template>
    <v-container
            class="fill-height"
            style="height: 90vh"
    >
        <v-row
                align="center"
                justify="center"
        >
            <v-col
                    cols="6"
            >
                <v-card
                        id="info-block"
                        class="pa-md-4 mx-lg-auto"
                >
                    <v-cart-text>
                        <div
                                align="center"
                                justify="center">
                            <div class="my-6">
                                There is no any technical assignment.<br>It's time to <router-link to="/stepper">create your first task</router-link>.
                            </div>
                            <router-link to="/stepper">
                                <v-btn class="mt-6" color="primary">Create Task</v-btn>
                            </router-link>
                        </div>
                    </v-cart-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Welcome"
    }
</script>

<style scoped>

</style>