export default {
    state: {
        content: [],
        selected_phrases: [],
        loading: true
    },
    mutations: {
        updateLoadingWithoutStopWords2Default(state, value){
            state.loading = value
        },
        updateWithoutStopWords2Default(state, value) {
            state.content = value
        },
        updateWithoutStopWords2DefaultSelected(state, value) {
            state.selected_phrases = value
        },
    }
};