<template>
  <v-card
      class="mx-auto mb-12 mt-2"
      raised
  >
    <v-card-title>
      Main Keywords
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="result"
          item-key="ph"
          :items-per-page="5"
          class="elevation-1"
      >
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.ph">
            <td>{{ item.ph }}</td>
            <td v-if="is_compare_url" class="text-center">
              <span class="red--text" v-if="item.our[0] > item.min_max[1]">{{ item.min_max[1] - item.our[0] }}</span>
              <span class="red--text" v-else-if="item.min_max[0] > item.our[0]">{{
                  '+' + (item.min_max[0] - item.our[0])
                }}</span>
              <span v-else><v-icon class="green--text">mdi-check</v-icon></span>
            </td>
            <td width="130" class="text-center">
              {{ item.min_max[0] }} - {{ item.min_max[1] }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {calcMinMaxMixin} from "../../../../mixins/calcMinMaxEntryMixin";

export default {
  mixins: [calcMinMaxMixin],
  computed: {
    result() {
      let result = this.$store.state.stepper.processing.selected_phrases.filter((v) => v.favorite);
      return result.map((v) => {
        v.min_max = [
          this.calcMinEntry(v.med, v.ph.split(" ").length),
          this.calcMaxEntry(v.med, v.ph.split(" ").length)
        ];
        return v
      });
    },
    headers() {
      let headers = [
        {text: 'Phrase', value: 'ph'},
        {text: 'Corrections', value: 'corrections', align: 'center'},
        {text: 'Min-max', value: 'volume', align: 'center'},
      ];

      if (!this.is_compare_url) {
        return headers.filter((x) => x.value !== 'corrections')
      }

      return headers
    },
    is_compare_url() {
      return this.$store.state.stepper.s1.compare_url.length > 0
    },
    additions() {
      return this.$store.state.stepper.s3.additions
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>