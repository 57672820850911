<template>
    <div>
        <v-stepper-content :step="step">
            <v-card
                    class="mx-auto mb-12 mt-2"
                    max-width="500"
                    raised
            >
                <v-card-text>
                    <v-form
                            ref="form"
                            v-model="valid"
                    >
                        <v-select
                                :items="items_text_type"
                                v-model="text_type"
                                label="Text type"
                        >
                        </v-select>
                        <v-select
                                :items="items_tonality_of_the_text"
                                v-model="tonality_of_the_text"
                                label="Tonality of the text"
                        >
                        </v-select>
                        <v-checkbox v-model="condition_have_lists" class="mx-2" label="Lists needed"></v-checkbox>
                        <v-checkbox v-model="condition_have_table" class="mx-2" label="Table needed"></v-checkbox>
                        <v-textarea
                                label="Special Characteristics"
                                v-model="special_characteristics"
                                no-resize
                                rows="3"
                                required
                        ></v-textarea>
                    </v-form>
                </v-card-text>
            </v-card>
            <v-btn @click="setCurrentTab(step - 1)" text>back to textual characteristics</v-btn>
            <v-btn color="primary" @click="setCurrentTab(step + 1)" class="float-right">Finish</v-btn>
        </v-stepper-content>
    </div>
</template>

<script>
    export default {
        name: 'Step6',
        data() {
            return {
                step: 5,
                items_uniqueness: [],
                items_text_type: [],
                items_tonality_of_the_text: [],
                valid: true,
            }
        },
        created() {
            for (let i = 100; i >= 5; i -= 5) {
                this.items_uniqueness.push(i)
            }

            this.items_text_type = [
                'Commercial',
                'Informational'
            ];

            this.items_tonality_of_the_text = [
                'Positive',
                'Negative',
                'No matter'
            ];
        },
        methods: {
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab
            },
            getClass(item) {
                return item.entry > 0 ? 'success' : ''
            },
        },
        computed: {
            text_type: {
                get() {
                    return this.$store.state.stepper.s6.text_type
                },
                set(newValue) {
                    this.$store.state.stepper.s6.text_type = newValue
                }
            },
            uniqueness: {
                get() {
                    return this.$store.state.stepper.s6.uniqueness
                },
                set(newValue) {
                    this.$store.state.stepper.s6.uniqueness = newValue
                }
            },
            tonality_of_the_text: {
                get() {
                    return this.$store.state.stepper.s6.tonality_of_the_text
                },
                set(newValue) {
                    this.$store.state.stepper.s6.tonality_of_the_text = newValue
                }
            },
            condition_have_lists: {
                get() {
                    return this.$store.state.stepper.s6.condition_have_lists
                },
                set(newValue) {
                    this.$store.state.stepper.s6.condition_have_lists = newValue
                }
            },
            condition_have_table: {
                get() {
                    return this.$store.state.stepper.s6.condition_have_table
                },
                set(newValue) {
                    this.$store.state.stepper.s6.condition_have_table = newValue
                }
            },
            special_characteristics: {
                get() {
                    return this.$store.state.stepper.s6.special_characteristics
                },
                set(newValue) {
                    this.$store.state.stepper.s6.special_characteristics = newValue
                }
            }
        }
    }
</script>

<style>
    table th,
    table td {
        vertical-align: middle;
    }
</style>