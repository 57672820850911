export default {
    state: {
        content: [],
        selected_phrases: [],
        loading: true
    },
    mutations: {
        updateLoading8(state, value) {
            state.loading = value
        },
        updateByWords8(state, value) {
            state.content = value
        },
        updateByWords8Selected(state, value) {
            state.selected_phrases = value
        },
    }
};