<template>
  <div>
    <v-stepper-content :step="step">
      <v-card
          class="mx-auto mb-12 mt-2"
          max-width="500"
          raised
      >
        <v-card-text>
          <v-form
              ref="form"
              v-model="valid"
          >
            <v-text-field
                v-model="url"
                label="Url"
                placeholder="Url"
                solo
            ></v-text-field>
            <v-text-field
                v-model="title"
                label="Title"
                placeholder="Title"
                solo
            ></v-text-field>
            <v-text-field
                v-model="description"
                label="Description"
                placeholder="Description"
                solo
            ></v-text-field>
            <v-select
                :items="items_uniqueness"
                v-model="uniqueness"
                label="Uniqueness"
                placeholder="Uniqueness"
                solo
            ></v-select>
            <v-select
                :items="items_text_type"
                v-model="text_type"
                label="Text type"
                placeholder="Text type"
                solo
            >
            </v-select>
            <v-select
                :items="items_tonality_of_the_text"
                v-model="tonality_of_the_text"
                label="Tonality of the text"
                placeholder="Tonality of the text"
                solo
            >
            </v-select>
            <v-checkbox v-model="condition_have_lists" class="mx-2" label="Lists needed"></v-checkbox>
            <v-checkbox v-model="condition_have_table" class="mx-2" label="Table needed"></v-checkbox>
            <v-textarea
                v-model="special_characteristics"
                no-resize
                rows="3"
                required
                label="Special Characteristics"
                placeholder="Special Characteristics"
                solo
            ></v-textarea>
            <h3 class="title my-4">
              Relinking
              <v-btn
                  text
                  icon
                  small
                  @click="addRelinkingRow"
                  color="primary"
                  class="float-right"
              >
                <v-icon size="22">mdi-plus</v-icon>
              </v-btn>
            </h3>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr v-for="(item, key) in relinking" v-bind:key="key">
                  <td>
                    <v-text-field
                        v-model="relinking[key].anchor"
                        placeholder="Anchor"
                        solo
                        hide-details
                        class="my-2"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="relinking[key].url"
                        placeholder="Url"
                        solo
                        hide-details
                        class="my-2"
                    ></v-text-field>
                  </td>
                  <td class="text-center">
                    <v-btn text icon small @click="deleteRelinkingRow(key)" color="deep-orange">
                      <v-icon size="20">mdi-close</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex justify-space-between">
            <h3 class="title my-4" style="display: inline-block">Guide.</h3>
              <v-switch
                  v-model="guideMode"
                  @change="guideModeChange"
                  label="for GP"
              ></v-switch>
            </div>
            <vue-editor
                v-model="guide"
            ></vue-editor>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn @click="setCurrentTab(step - 1)" text>back to the first 150 words</v-btn>
      <v-btn color="primary" @click="setCurrentTab(step + 1)" class="float-right">Finish</v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import {VueEditor} from 'vue2-editor'
import {NEW_GUIDE_DATA} from "../../../utils/defaultData";

export default {
  name: 'Step6',
  data() {
    return {
      step: 8,
      items_uniqueness: [],
      items_text_type: [],
      items_tonality_of_the_text: [],
      valid: true,
      guideHistory: ['',NEW_GUIDE_DATA],
      guideMode: false,
    }
  },
  created() {
    for (let i = 100; i >= 5; i -= 5) {
      this.items_uniqueness.push(i)
    }

    this.items_text_type = [
      'Commercial',
      'Informational'
    ];

    this.items_tonality_of_the_text = [
      'Positive',
      'Negative',
      'No matter'
    ];
  },
  components: {VueEditor},
  watch: {
    guide(value) {
      const idx = this.guideMode ? 1 : 0
      this.guideHistory[idx] = value
    },
  },
  mounted() {
    this.guideHistory[0] = this.guide
  },
  methods: {
    guideModeChange(event) {
      const idx = event ? 1 : 0
      this.guide = this.guideHistory[idx]
    },
    addRelinkingRow() {
      this.$store.state.stepper.s6.relinking.push({anchor: '', url: ''})
    },
    deleteRelinkingRow(index) {
      this.relinking = this.relinking.splice(index, 1)
    },
    setCurrentTab(tab) {
      this.$store.state.stepper.current_tab = tab
    },
    getClass(item) {
      return item.entry > 0 ? 'success' : ''
    },
  },
  computed: {
    relinking: {
      get() {
        return this.$store.state.stepper.s6.relinking
      },
      set(newValue) {
        this.$store.state.stepper.s6.relinking = newValue
      }
    },
    text_type: {
      get() {
        return this.$store.state.stepper.s6.text_type
      },
      set(newValue) {
        this.$store.state.stepper.s6.text_type = newValue
      }
    },
    url: {
      get() {
        return this.$store.state.stepper.s6.url
      },
      set(newValue) {
        this.$store.state.stepper.s6.url = newValue
      }
    },
    title: {
      get() {
        return this.$store.state.stepper.s6.title
      },
      set(newValue) {
        this.$store.state.stepper.s6.title = newValue
      }
    },
    description: {
      get() {
        return this.$store.state.stepper.s6.description
      },
      set(newValue) {
        this.$store.state.stepper.s6.description = newValue
      }
    },
    uniqueness: {
      get() {
        return this.$store.state.stepper.s6.uniqueness
      },
      set(newValue) {
        this.$store.state.stepper.s6.uniqueness = newValue
      }
    },
    tonality_of_the_text: {
      get() {
        return this.$store.state.stepper.s6.tonality_of_the_text
      },
      set(newValue) {
        this.$store.state.stepper.s6.tonality_of_the_text = newValue
      }
    },
    condition_have_lists: {
      get() {
        return this.$store.state.stepper.s6.condition_have_lists
      },
      set(newValue) {
        this.$store.state.stepper.s6.condition_have_lists = newValue
      }
    },
    condition_have_table: {
      get() {
        return this.$store.state.stepper.s6.condition_have_table
      },
      set(newValue) {
        this.$store.state.stepper.s6.condition_have_table = newValue
      }
    },
    special_characteristics: {
      get() {
        return this.$store.state.stepper.s6.special_characteristics
      },
      set(newValue) {
        this.$store.state.stepper.s6.special_characteristics = newValue
      }
    },
    guide: {
      get() {
        return this.$store.state.stepper.s6.guide
      },
      set(newValue) {
        this.$store.state.stepper.s6.guide = newValue
      }
    }
  }
}
</script>

<style>
table th,
table td {
  vertical-align: middle;
}
</style>