<template>
    <div>
        <v-stepper-content :step="step">
            <v-alert
                    v-if="false === loading"
                    class="mx-3"
                    text
                    prominent
                    color="blue-grey"
                    icon="mdi-information-outline"
            >
                Choose keywords for the first 150 words of the text.
            </v-alert>
            <v-alert
                    v-if="loading"
                    class="mx-3"
                    text
                    prominent
                    type="info"
                    icon="mdi-clock-fast"
            >
                Processing data. Please wait few minutes.
            </v-alert>
            <v-card
                    class="mb-12 mt-2 mx-3"
                    :loading="loading"
                    raised
            >
                <v-card-text>
                    <v-data-table
                            v-model="selected_phrases"
                            :headers="getHeaders"
                            :items="content"
                            item-key="phrase"
                            show-select
                            multi-sort
                            sort-by="in_doc"
                            :sort-desc="true"
                            class="elevation-1"
                    >
                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="item in items" :key="item.phrase">
                                <td>
                                    <v-checkbox class="mt-0" hide-details v-model="selected_phrases" :value="item"
                                                show-select/>
                                </td>
                                <td>{{ item.phrase }}</td>
                                <td v-if="is_compare_url" :class="getClass(item.cu)">{{ item.cu }}</td>
                                <td class="text-center">{{ Math.round(item.in_doc * 100 / size_child) }}%</td>
                                <td v-for="(v, k) in item.child" v-bind:key="k" :class="getClass(v)">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }"><span v-on="on">{{ v.entry }}</span>
                                        </template>
                                        <span>Text Length: {{ v.len }}<br>Url: {{ v.url }}</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-btn @click="setCurrentTab(step - 1)" text>back to processing keywords</v-btn>
            <v-btn color="primary" @click="setCurrentTab(step + 1)" class="float-right">Choose general characteristics</v-btn>
        </v-stepper-content>
    </div>
</template>

<script>
    export default {
        name: 'Step5',
        data() {
            return {
                step: 7
            }
        },
        methods: {
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab
            },
            getClass(item) {
                return "text-center " + (item.entry > 0 ? "light-green lighten-4" : "");
            },
        },
        watch: {
            loading() {
                let content = this.content;

                this.selected_phrases = content.filter((x) => {
                    let count_entry_in_child = x.child.filter((v) => v.entry > 0).length;
                    return count_entry_in_child === this.size_child && x.cu === 0;
                });
            }
        },
        computed: {
            is_compare_url() {
                return this.$store.state.stepper.s1.compare_url.length > 0
            },
            loading() {
                return this.$store.state.stepper.s7.loading
            },
            selected_phrases: {
                get() {
                    return this.$store.state.stepper.s5.phrases_in_top_150_words
                },
                set(newValue) {
                    this.$store.state.stepper.s5.phrases_in_top_150_words = newValue
                }
            },
            count() {
                return this.$store.state.count
            },
            content() {
              console.log('test',this.$store.state.stepper.s7.entry)
                return this.$store.state.stepper.s7.entry
            },
            size_child() {
                return this.content[0] !== undefined ? this.content[0].child.length : 0
            },
          getHeaders() {
                let columns = [
                    {text: 'Phrase', value: 'phrase'},
                    {text: 'CU', value: 'cu', align: 'center'},
                    {text: 'Popularity ', value: 'in_doc', align: 'center', sortable: true},
                ];

                if (!this.is_compare_url) {
                    columns = columns.filter((x) => x.value !== 'cu')
                }

                for (let i = 1; i <= this.size_child; i++) {
                    let visibility = 0;
                    if (this.$store.state.stepper.s2.vision[i - 1] !== undefined) {
                        visibility = Math.round(this.$store.state.stepper.s2.vision[i - 1].vision * 100 / this.$store.getters.amountVisibility)
                    }
                    columns.push({text: `${visibility}%`, value: `p${i}`, align: 'center'});
                }
                return columns
            }
        }
    }
</script>