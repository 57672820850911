var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-stepper-content',{attrs:{"step":_vm.step}},[_c('v-alert',{staticClass:"mx-3",attrs:{"text":"","prominent":"","color":"blue-grey","icon":"mdi-information-outline"}},[_vm._v(" You can change words count or add your own keywords, if you need. ")]),_c('v-card',{staticClass:"mb-12 mt-2 mx-3",attrs:{"loading":_vm.loading,"raised":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"label":"Search","solo":"","prepend-inner-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.content,"custom-sort":_vm.customSort,"custom-filter":_vm.custom_filter,"search":_vm.search,"item-key":"ph","show-select":"","items-per-page":10,"footer-props":{'items-per-page-options': [10, 50, 100, 200, -1]},"dense":""},scopedSlots:_vm._u([{key:"header.our",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Compare Url")])])]}},{key:"header.rwc",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Recommended Word Count")])])]}},{key:"header.rwc_med",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Recommended Word Count (Median)")])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.ph},[_c('td',[(parseInt(item.rwc_min) > parseInt(item.rwc_max) || _vm.content_conflict.filter(function (v) { return v.ph === item.ph; }).length !== 0)?_c('v-icon',{attrs:{"size":"24","color":"error"}},[_vm._v("mdi-alert-circle-outline ")]):_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","value":item,"show-select":""},model:{value:(_vm.selected_phrases),callback:function ($$v) {_vm.selected_phrases=$$v},expression:"selected_phrases"}})],1),_c('td',[_vm._v(" "+_vm._s(item.ph)+" "),_vm._l((item.resources),function(i){return _c('v-tooltip',{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":"","color":_vm.get_resource_label(i).color}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.get_resource_label(i).label)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_resource_label(i).tooltip))])])}),_c('v-btn',{attrs:{"text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.examples_of_using(item.ph)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-comment-question")])],1),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.updateFavorite(item)}}},[(item.favorite || _vm.favorites.indexOf(item.ph) !== -1)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-star")]):_c('v-icon',[_vm._v("mdi-star-outline")])],1)],2),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(Math.round(item.docs * 100 / _vm.size_child))+"%")]),(_vm.visibleCompareUrl)?_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.our[0]))])]}}],null,true)},[_c('span',[_vm._v(" Title: "+_vm._s(_vm.compare_text.title)),_c('br'),_vm._v(" H1: "+_vm._s(_vm.compare_text.h1)),_c('br'),_vm._v(" Url: "+_vm._s(_vm.compare_text.url)),_c('br'),_vm._v(" Text Length: "+_vm._s(_vm.compare_text.length)+" ")])]),_c('v-icon',{attrs:{"size":"14","color":_vm.getOurIcon(item, 0).color},domProps:{"innerHTML":_vm._s(_vm.getOurIcon(item, 0).icon)}}),(item.our[0] !== 0 || _vm.getOurIcon(item, 1).icon !== 'mdi-arrow-up-thick')?_c('span',[_c('br'),_vm._v(" "+_vm._s(item.our[1])+"% "),_c('v-icon',{attrs:{"size":"14","color":_vm.getOurIcon(item, 1).color},domProps:{"innerHTML":_vm._s(_vm.getOurIcon(item, 1).icon)}})],1):_vm._e()],1):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Math.floor(item.med[0]))),_c('br'),_vm._v(_vm._s(item.med[1])+"% ")]),_c('td',{staticClass:"text-center",staticStyle:{"white-space":"nowrap"}},[_c('v-text-field',{staticStyle:{"max-width":"60px","display":"inline-block"},attrs:{"label":"Min","type":"number","min":"0","dense":"","single-line":""},on:{"input":function($event){return _vm.find_conflict()}},model:{value:(item.rwc_min),callback:function ($$v) {_vm.$set(item, "rwc_min", $$v)},expression:"item.rwc_min"}}),_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"60px","display":"inline-block"},attrs:{"label":"Max","type":"number","min":"0","dense":"","single-line":""},on:{"input":function($event){return _vm.find_conflict()}},model:{value:(item.rwc_max),callback:function ($$v) {_vm.$set(item, "rwc_max", $$v)},expression:"item.rwc_max"}})],1),_vm._l((item.child.slice(0, _vm.max_headers)),function(v,k){return _c('td',{key:k,staticClass:"text-center",class:_vm.getClass(v, 'child').color},[_vm._v(" "+_vm._s(v[0])),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(v[1])+"%")])]}}],null,true)},[_c('span',[_vm._v("Text Length: "+_vm._s(item.headers[k].length)),_c('br'),_vm._v("Url: "+_vm._s(item.headers[k].url)),_c('br'),_vm._v("Additions: "+_vm._s(_vm.additions[0]))])])],1)})],2)}),0)]}}]),model:{value:(_vm.selected_phrases),callback:function ($$v) {_vm.selected_phrases=$$v},expression:"selected_phrases"}}),(_vm.disabled_next_step)?_c('div',[_c('h3',{staticClass:"title my-4"},[_vm._v("Conflict Phrases")]),_c('v-alert',{attrs:{"text":"","prominent":"","type":"error"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" Please, fix range conflict ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.disabled_next_step_manual = !_vm.disabled_next_step_manual}}},[_vm._v("Ignore")])],1)],1)],1),_c('v-text-field',{attrs:{"label":"Search","solo":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search_conflict),callback:function ($$v) {_vm.search_conflict=$$v},expression:"search_conflict"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.content_conflict,"search":_vm.search_conflict,"custom-sort":_vm.customSort,"item-key":"ph","show-select":""},scopedSlots:_vm._u([{key:"header.our",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Compare Url")])])]}},{key:"header.rwc",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Recommended Word Count")])])]}},{key:"header.rwc_med",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Recommended Word Count (Median)")])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.ph},[_c('td',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","value":item,"show-select":""},model:{value:(_vm.selected_phrases),callback:function ($$v) {_vm.selected_phrases=$$v},expression:"selected_phrases"}})],1),_c('td',[_vm._v(_vm._s(item.ph))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(Math.round(item.docs * 100 / _vm.size_child))+"%")]),(_vm.visibleCompareUrl)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.our[0])+" "),_c('v-icon',{attrs:{"size":"14","color":_vm.getOurIcon(item, 0).color},domProps:{"innerHTML":_vm._s(_vm.getOurIcon(item, 0).icon)}}),(item.our[0] !== 0 || _vm.getOurIcon(item, 1).icon !== 'mdi-arrow-up-thick')?_c('span',[_c('br'),_vm._v(" "+_vm._s(item.our[1])+"% "),_c('v-icon',{attrs:{"size":"14","color":_vm.getOurIcon(item, 1).color},domProps:{"innerHTML":_vm._s(_vm.getOurIcon(item, 1).icon)}})],1):_vm._e()],1):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Math.floor(item.med[0]))),_c('br'),_vm._v(_vm._s(item.med[1])+"% ")]),_c('td',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Min","type":"number","min":"0"},on:{"input":_vm.find_conflict},model:{value:(item.rwc_min),callback:function ($$v) {_vm.$set(item, "rwc_min", $$v)},expression:"item.rwc_min"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Max","type":"number","min":"0"},on:{"input":_vm.find_conflict},model:{value:(item.rwc_max),callback:function ($$v) {_vm.$set(item, "rwc_max", $$v)},expression:"item.rwc_max"}})],1)],1)],1),_vm._l((item.child.slice(0, _vm.max_headers)),function(v,k){return _c('td',{key:k,staticClass:"text-center",class:_vm.getClass(v, 'child').color},[_vm._v(" "+_vm._s(v[0])),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(v[1])+"%")])]}}],null,true)},[_c('span',[_vm._v("Text Length: "+_vm._s(item.headers[k].length)),_c('br'),_vm._v("Url: "+_vm._s(item.headers[k].url)),_c('br'),_vm._v("Additions: "+_vm._s(_vm.additions[0]))])])],1)})],2)}),0)]}}],null,false,3303115139)})],1):_vm._e(),_c('h3',{staticClass:"title my-4"},[_vm._v("Add more phrases")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-text-field',{attrs:{"label":"Keyword"},model:{value:(_vm.custom_keyword),callback:function ($$v) {_vm.custom_keyword=$$v},expression:"custom_keyword"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Min","type":"number","min":"0"},model:{value:(_vm.custom_keyword_min),callback:function ($$v) {_vm.custom_keyword_min=$$v},expression:"custom_keyword_min"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Max","type":"number","min":"0"},model:{value:(_vm.custom_keyword_max),callback:function ($$v) {_vm.custom_keyword_max=$$v},expression:"custom_keyword_max"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addCustomKeyword}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('h3',{staticClass:"title my-4"},[_vm._v("Custom phrases")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.custom_headers,"items":_vm.custom_content,"item-key":"ph","show-select":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.ph},[_c('td',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","value":item,"show-select":""},model:{value:(_vm.custom_selected_phrases),callback:function ($$v) {_vm.custom_selected_phrases=$$v},expression:"custom_selected_phrases"}})],1),_c('td',[_vm._v(_vm._s(item.keyword))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.min)+" - "+_vm._s(item.max)+" ")])])}),0)]}}]),model:{value:(_vm.custom_selected_phrases),callback:function ($$v) {_vm.custom_selected_phrases=$$v},expression:"custom_selected_phrases"}}),_c('v-bottom-sheet',{attrs:{"inset":"","hide-overlay":true,"persistent":false},model:{value:(_vm.e_sheet),callback:function ($$v) {_vm.e_sheet=$$v},expression:"e_sheet"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"prominent":"","border":"top","colored-border":"","color":"white"}},[(_vm.e_sheet_loading === false)?_c('v-container',{staticStyle:{"height":"200px"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Getting examples of using ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow",domProps:{"innerHTML":_vm._s(_vm.e_sheet_message)}})],1)],1)],1)],1)],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.setCurrentTab(_vm.step - 1)}}},[_vm._v("back to keywords")]),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","disabled":_vm.disabled_next_step},on:{"click":function($event){return _vm.setCurrentTab(_vm.step + 1)}}},[_vm._v("Choose keywords for the first 150 words ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }