<template>
  <div>
    <v-stepper-content :step="step">
      <v-alert
          class="mx-3"
          text
          prominent
          color="blue-grey"
          icon="mdi-information-outline"
      >
        Choose thematic words for the text.
      </v-alert>
      <v-row justify="center" v-if="beta">
        <v-select
            :items="competitors"
            @change="updateBaseUrl"
            label="Select Base Competitor"
            item-value="key"
            item-text="url"
        ></v-select>
      </v-row>
      <v-row justify="center">
        <v-switch :disabled="disabled_ignore_parent_keywords" v-model="ignore_parent_keywords" class="mx-2"
                  label="Ignore Child Phrases"></v-switch>
        <v-switch :disabled="disabled_disable_stop_words" v-model="disable_stop_words" class="mx-2"
                  label="Disable Stop Words"></v-switch>
      </v-row>
      <MainPhrasesBySerp ref="main_phrases_by_serp"/>

      <KeywordsFromMainPhrasesByVision v-if="lang_allowed" ref="keywords_from_main_phrases_by_vision"/>

      <AdditionalPhrasesByVision v-if="has_additions_keywords" ref="additional_phrases_by_vision"/>

      <Snippet ref="snippet"/>

      <Related ref="related"/>

      <Suggest ref="suggest"/>

      <ByWords ref="by_words_10" :words="10"/>

      <ByWords ref="by_words_9" :words="9"/>

      <ByWords ref="by_words_8" :words="8"/>

      <ByWords ref="by_words_7" :words="7"/>

      <ByWords ref="by_words_6" :words="6"/>

      <WithoutStopWords ref="without_stop_words_5" type="Default" count_words="5"
                        title="By words 5 without stop words"/>
      <WithoutStopWords ref="without_stop_words_5_start_end" type="StartEnd" count_words="5"
                        title="By words 5 without stop words on start or end"/>
      <WithoutStopWords ref="without_stop_words_5_others" type="Others" count_words="5"
                        title="By words 5 others"/>

      <WithoutStopWords ref="without_stop_words_4" type="Default" count_words="4"
                        title="By words 4 without stop words"/>
      <WithoutStopWords ref="without_stop_words_4_start_end" type="StartEnd" count_words="4"
                        title="By words 4 without stop words on start or end"/>
      <WithoutStopWords ref="without_stop_words_4_others" type="Others" count_words="4"
                        title="By words 4 others"/>

      <WithoutStopWords ref="without_stop_words_3" type="Default" count_words="3"
                        title="By words 3 without stop words"/>
      <WithoutStopWords ref="without_stop_words_3_start_end" type="StartEnd" count_words="3"
                        title="By words 3 without stop words on start or end"/>
      <WithoutStopWords ref="without_stop_words_3_others" type="Others" count_words="3"
                        title="By words 3 others"/>

      <WithoutStopWords ref="without_stop_words_2" type="Default" count_words="2"
                        title="By words 2 without stop words"/>
      <WithoutStopWords ref="without_stop_words_2_others" type="Others" count_words="2"
                        title="By words 2 others"/>

      <ByWords v-if="lang_allowed" ref="by_words_1" :words="1"/>
      <v-btn @click="setCurrentTab(step - 1)" text>back to structure</v-btn>
      <v-btn :disabled="disableNext" color="primary" @click="setCurrentTab(step + 1)" class="float-right">
        Processing keywords
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import MainPhrasesBySerp from './Reports/MainPhrasesBySerp'
import Snippet from './Reports/Snippet'
import Related from './Reports/Related'
import Suggest from './Reports/Suggest'
import AdditionalPhrasesByVision from './Reports/AdditionalPhrasesByVision'
import KeywordsFromMainPhrasesByVision from './Reports/KeywordsFromMainPhrasesByVision'
import ByWords from './Reports/ByWords'
import WithoutStopWords from './Reports/WithoutStopWords'

export default {
  name: 'Step4',
  components: {
    MainPhrasesBySerp,
    AdditionalPhrasesByVision,
    KeywordsFromMainPhrasesByVision,
    ByWords,
    WithoutStopWords,
    Snippet,
    Related,
    Suggest
  },
  data() {
    return {
      step: 5,
      selectedItem: {
        url: null,
        text: null
      },
    }
  },
  methods: {
    log(data) {
      console.log(data)
    },
    setCurrentTab(tab) {
      this.$store.state.stepper.current_tab = tab;
      // this.$store.dispatch('fetchResult');
      this.$store.dispatch('collectPhrases');
    },
    updateBaseUrl(value) {
      this.$store.state.stepper.s4.base_url = value
    }
  },
  computed: {
    beta() {
      return this.$store.state.beta
    },
    competitors() {
      let urls = this.$store.state.stepper.s3.selected_urls;
      let data_select = [{key: -1, url: 'Not selected'}];
      for (let i in urls) {
        data_select.push({
          key: i,
          url: urls[i].url
        });
      }
      return data_select;
    },
    lang_allowed() {
      return this.$store.state.stepper.s1.language !== "ja"
    },
    has_additions_keywords() {
      return this.$store.state.stepper.s1.additional_keywords.length > 0
    },
    disabled_ignore_parent_keywords() {
      return this.$store.getters.is_data_received
    },
    disabled_disable_stop_words() {
      return this.$store.getters.is_data_received
    },
    ignore_parent_keywords: {
      get() {
        return this.$store.state.stepper.s4.ignore_parent_keywords
      },
      set(value) {
        this.$store.state.stepper.s4.ignore_parent_keywords = value;
        this.$store.dispatch('fetchDataOnStep4');
      }
    },
    disable_stop_words: {
      get() {
        return this.$store.state.stepper.s4.disable_stop_words
      },
      set(value) {
        this.$store.state.stepper.s4.disable_stop_words = value;
        this.$store.dispatch('fetchDataOnStep4');
      }
    },
    disableNext() {
      return !(this.selectedPhrases.length >= 1) || this.$store.getters.is_data_received
    },
    selectedPhrases() {
      let result = [];
      for (let prop in this.$store.state.stepper.s4) {
        if (this.$store.state.stepper.s4[prop].selected_phrases !== undefined) {
          result = result.concat(this.$store.state.stepper.s4[prop].selected_phrases);
        }
      }
      return result;
    }
  },
}
</script>