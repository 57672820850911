<template>
    <v-card
            class="mx-auto mb-12 mt-2"
            raised
    >
        <v-card-title>
            Text Characteristics
        </v-card-title>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="result"
                    item-key="ph"
                    :items-per-page="15"
                    class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.type">
                        <td>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ types[item.type].title }} <a href="#">?</a></span>
                                </template>
                                <span>{{ types[item.type].description }}</span>
                            </v-tooltip>
                        </td>
                        <td class="text-center" v-if="is_compare_url">
                            {{ item.cu.value.value }}
                        </td>
                        <td class="text-center">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            label="Min"
                                            type="number"
                                            min="0"
                                            v-model="item.rv[0]"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            label="Max"
                                            type="number"
                                            min="0"
                                            v-model="item.rv[1]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </td>
                        <td v-if="is_compare_url">
                            <v-btn
                                    color="primary"
                                    v-on:click="dialogNaturalnessSetData(item.cu.value.full_data)"
                                    v-if="typeof item.cu.value.full_data === 'object'">
                                Detailed
                            </v-btn>
                            {{ item.cu.corrections.message }}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card-text>
        <v-dialog v-model="dialogNaturalness" width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Naturalness </span>
                </v-card-title>
                <v-card-text>
                    <div class="v-data-table elevation-1 theme--light">
                        <table>
                            <thead class="v-data-table-header">
                            <tr>
                                <th>Word</th>
                                <th>Entry</th>
                                <th>By Zipfs</th>
                                <th>Conformity</th>
                                <th>Recommendation</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, i) in dialogNaturalnessData" :key="i">
                                <td>{{ item.word}}</td>
                                <td class="text-center">{{ item.count }}</td>
                                <td class="text-center">{{ item.entry_by_zipfs }}</td>
                                <td class="text-center">{{ Math.round(item.percent) }}%</td>
                                <td class="text-center">{{ item.recommendation === 0 ? '' : item.recommendation }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialogNaturalness = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                dialogNaturalnessData: [],
                dialogNaturalness: false,
                types: {
                    zipfs_law: {
                        title: 'Naturalness',
                        description: 'Естественность текста по закону Ципфа'
                    },
                    total_words: {
                        title: 'Total words',
                        description: 'Общее количество слов в тексте'
                    },
                    vocabulary: {
                        title: 'Vocabulary',
                        description: 'Кол-во слов употребляющихся в тексте'
                    },
                    semantic_vocabulary: {
                        title: 'Semantic Vocabulary',
                        description: 'Кол-во РАЗНЫХ слов исключая стоп-слова'
                    },
                    total_sentences: {
                        title: 'Total sentences',
                        description: 'Общее кол-во предложений в тексте'
                    },
                    average_sentence_length: {
                        title: 'Average sentence length',
                        description: 'Среднее кол-во слов в предложении'
                    },
                    classical_nausea_index: {
                        title: 'Classical Nausea Index',
                        description: 'Классическая тошнота'
                    },
                    water_index: {
                        title: 'Water Index',
                        description: 'Показывает процент слов не несущих информационную нагрузку (водность)'
                    },
                    dale_chall_score: {
                        title: 'Dale–Chall Readability',
                        description: 'Читабельность по алгоритму Dale–Chal'
                    },
                    spache: {
                        title: 'Spache Readability',
                        description: 'Читабельность по алгоритму Spache'
                    },
                    flesch: {
                        title: 'Flesch Readability',
                        description: 'Читабельность по алгоритму Flesch'
                    },
                    automated_index: {
                        title: 'Automated readability index Readabilit',
                        description: 'Читабельность по алгоритму Automated readability index'
                    }
                }
            }
        },
        computed: {
            result() {
                return this.$store.state.stepper.textual_factors.content;
            },
            headers() {
                let headers = [
                    {text: 'Characteristic', value: 'characteristic'},
                    {text: 'CU', value: 'cu', align: 'center'},
                    {text: 'RV / Min-max', value: 'volume', align: 'center', width: '200px'},
                    {text: 'Corrections', value: 'corrections'},
                ];

                if (!this.is_compare_url) {
                    return headers.filter((x) => x.value !== 'corrections' && x.value !== 'cu')
                }

                return headers
            },
            is_compare_url() {
                return this.$store.state.stepper.s1.compare_url.length > 0
            }
        },
        methods: {
            dialogNaturalnessSetData(data) {
                this.dialogNaturalnessData = data;
                this.dialogNaturalness = true;
            },
        }
    }
</script>

<style scoped>

</style>