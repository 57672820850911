export const audioMixin = {
    methods: {
        play(id) {
            id = id === undefined ? 'audio-complete' : id
            setTimeout(function () {
                let promise = document.querySelector(`#${id}`).play();
                if (promise !== undefined) {
                    promise.catch(error => {
                        console.log(error);
                        // Autoplay was prevented.
                        // Show a "Play" button so that user can start playback.
                    });
                }
            }, 500);
        }
    }
};