import s1 from './s1'
import s2 from './s2'
import s3 from './s3'
import s4 from './s4'
import s5 from './s5'
import s6 from './s6'
import s7 from './s7'
import processing from './processing'
import textual_factors from './textual_factors'
import axios from "axios";

const module = {
    modules: {s1, s2, s3, s4, s5, s6, s7, processing, textual_factors},
    state: {
        current_tab: 1
    },
    mutations: {
        updateCurrentTab(state, tab) {
            state.current_tab = tab
        }
    },
    actions: {
        async fetchBannedWords(ctx, payload) {
            let url = `${ctx.rootState.server_url}/api/s/cb/stop-words?lang=${payload.language}`;

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            };

            const resp = await axios.get(url, config)

            if(resp) {
                return resp.data
            }
        },
        changedTab(ctx, tab) {
            ctx.commit('updateCurrentTab', tab);
        }
    }
};

export default module