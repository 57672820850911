<template>
    <div>
        <v-app id="inspire">
            <v-stepper v-model="current_tab" :input="log" alt-labels>
                <v-stepper-header>
                    <v-stepper-step :complete="current_tab > 1" step="1">Settings</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 2" step="2">Serp</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 3" step="3">Texts</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 4" step="4">Structure</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 5" step="5">Entry</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 6" step="6">Processing</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 7" step="7">First 150 words</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="current_tab > 8" step="8">Characteristics</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="9">Finish</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <Step1/>
                    <Step2 ref="Step2"/>
                    <Step3/>
                    <Structure/>
                    <Step4/>
                    <Processing/>
                    <Step5/>
                    <Step6/>
                    <Step7/>
                </v-stepper-items>
            </v-stepper>
        </v-app>
    </div>
</template>

<script>
    import Step1 from './Step1/Step1.vue'
    import Step2 from './Step2/Step2.vue'
    import Step3 from './Step3/Step3.vue'
    import Step4 from './Step4/Step4.vue'
    import Step5 from './Step5/Step5.vue'
    import Step6 from './Step6/Step.vue'
    import Step7 from './Step7/Step.vue'
    import Structure from './Structure/Step.vue'
    import Processing from './Processing/Step.vue'

    export default {
        name: "Stepper",
        data: function () {
            return {
                loadingFinish: false,
                tabs: [1, 2, 3],
                testText: 'Test Text',
                disabledPrevButton: true,
                disabledNextButton: false
            }
        },
        methods: {
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab
            },
            log() {

            },
            prevStep: function () {
                if (this.tabs.includes(this.current_tab - 1)) {
                    --this.$store.state.stepper.current_tab;
                    this.disabledPrevButton = false;
                    this.disabledNextButton = true;
                } else {
                    this.disabledPrevButton = true;
                    this.disabledNextButton = false;
                }
            },
            nextStep: function () {
                if (this.tabs.includes(this.current_tab + 1)) {
                    ++this.$store.state.stepper.current_tab;
                    this.disabledNextButton = false;
                    this.disabledPrevButton = false;
                } else {
                    this.disabledNextButton = true;
                    this.disabledPrevButton = false;
                }
            }
        },
        components: {
            Step1,
            Step2,
            Step3,
            Structure,
            Step4,
            Processing,
            Step5,
            Step6,
            Step7,
        },
        computed: {
            current_tab() {
                // let virtual_pages = {
                //     1: '/stepper-settings',
                //     2: '/stepper-serp',
                //     3: '/stepper-texts',
                //     4: '/stepper-structure',
                //     5: '/stepper-entry',
                //     6: '/stepper-processing',
                //     7: '/stepper-first 150 words',
                //     8: '/stepper-characteristics',
                //     9: '/stepper-finish',
                // };
                // this.$ga.page(virtual_pages[this.$store.state.stepper.current_tab]);
                return this.$store.state.stepper.current_tab
            }
        }
    }
</script>

<style>
    .table-overflow .table-wrapper {
        overflow: auto;
    }
</style>