<template>
  <v-container
      class="fill-height"
      style="height: 90vh"
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          cols="12"
          v-if="is_service_not_available"
      >
        <v-card
            id="info-block"
            class="pa-md-4 mx-lg-auto"
            color="#26c6da"
            dark
        >
          <v-card-title>
            <v-icon class="mr-3" size="70">mdi-emoticon-sad-outline</v-icon>
            Service unavailable
          </v-card-title>
        </v-card>
      </v-col>
      <!--            <v-col-->
      <!--                    cols="12"-->
      <!--            >-->
      <!--                <v-card-->
      <!--                        id="technical-assigment"-->
      <!--                        class="pa-md-4 mx-lg-auto"-->
      <!--                        :disabled="is_service_not_available"-->
      <!--                >-->
      <!--                    <v-card-title>Technical assigment</v-card-title>-->

      <!--                    <v-card-text>-->
      <!--                        Adjust screen size to see spacing changes-->
      <!--                    </v-card-text>-->
      <!--                    <v-card-actions>-->
      <!--                        <router-link to="/stepper">-->
      <!--                            <v-btn color="primary" :disabled="is_service_not_available || !server_pinged">-->
      <!--                                Go to Create-->
      <!--                            </v-btn>-->
      <!--                        </router-link>-->
      <!--                    </v-card-actions>-->
      <!--                </v-card>-->
      <!--            </v-col>-->
      <v-col>
        <v-card
            id="table-tasks"
            class="mx-auto"
            :loading="loading_tasks"
            :disabled="is_service_not_available"
        >
          <v-card-title>
            <!--                        Tasks-->
            <!--                        <v-spacer></v-spacer>-->
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                class="mx-4"
                single-line
                solo
                hide-details
            ></v-text-field>
            <router-link to="/stepper" class="float-right">
              <v-btn outlined color="primary" :disabled="is_service_not_available || !server_pinged">
                <v-icon size="17">mdi-plus</v-icon>
                Create Task
              </v-btn>
            </router-link>

            <router-link to="/textual_factor_analysis" class="float-right ml-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="primary" :disabled="is_service_not_available || !server_pinged"
                         v-on="on">
                    <v-icon size="17">mdi-plus</v-icon>
                    Create Task TFA
                  </v-btn>
                </template>
                <span>Create Task Textual Factors Analysis</span>
              </v-tooltip>
            </router-link>
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="content"
                :search="search"
                :items-per-page="5"
                class="table-custom"
            >
              <template v-slot:item.id="{ item }">
                {{ item.id }}
              </template>
              <template v-slot:item.type="{ item }">
                <v-chip
                    v-if="item.type === 'default'"
                    small
                    outlined
                    color="primary"
                >
                  DF
                </v-chip>
                <v-chip
                    v-if="item.type === 'textual_factors'"
                    small
                    outlined
                    color="warning"
                >
                  TFA
                </v-chip>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ item.created_at.split(' ')[0] }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" small fab x-small icon
                           @click="downloadDocx(item.hash)" v-on="on">
                      <v-icon size="17">mdi-file-word-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Download .docx</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" small fab x-small icon
                           @click="openHtmlFile(item.hash)" v-on="on">
                      <v-icon size="17">mdi-language-html5</v-icon>
                    </v-btn>
                  </template>
                  <span>Open HTML</span>
                </v-tooltip>
                <router-link v-if="item.type !== 'textual_factors'"
                             :to="`/editor/${user_id}/${item.hash}`">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" small fab x-small icon v-on="on">
                        <v-icon size="17">mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Go to Live Editor</span>
                  </v-tooltip>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import {audioMixin} from "../../mixins/audioMixin";
import {exportDocx} from "../../mixins/exportDocx";
import {saveAs} from "file-saver"
import {Packer} from "docx";

export default {
  name: "Home",
  mixins: [audioMixin, exportDocx],
  data() {
    return {
      search: '',
      loading_tasks: false,
      is_service_not_available: false,
      server_pinged: false,
      headers: [
        {text: 'ID', value: 'id', width: '120px', align: 'center'},
        {text: 'Name', value: 'name'},
        {text: 'SE/Lang', value: 'se_lang', width: '100px', align: 'center'},
        {text: 'Type', value: 'type', width: '100px', align: 'center'},
        {text: 'Created At', value: 'created_at', width: '120px', align: 'center'},
        {text: 'Actions', value: 'actions', width: '140px', align: 'center'},
      ],
      content: []
    }
  },
  computed: {
    user_id() {
      return this.$store.state.auth.user.id;
    }
  },
  methods: {
    downloadDocx(hash) {
      let self = this

      let url = `${self.$store.state.server_url}/api/v/tt/get-doc/${self.user_id}/${hash}`;

      let config = {
        params: {
          json: true
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then((response) => {
            console.log(JSON.stringify(response.data))
            let doc = exportDocx.methods.generate_docx(response.data)

            Packer.toBlob(doc).then((blob) => {
              saveAs(blob, "example.docx");
            });
          })
          .catch((error) => {
            console.log(error)
            self.$store.state.sheet = true
          })
    },
    openHtmlFile(hash) {
      location.href = `${this.$store.state.server_url}/api/v/tt/get-doc/${this.user_id}/${hash}`;
    },
    checkAvailableServer(callback) {
      let url = `${this.$store.state.server_url}/api/v/tt/ping`;
      let self = this;

      axios.get(url)
          .then((response) => {
            self.is_service_not_available = !response.status;
            self.server_pinged = true;
            callback();
          })
          .catch(() => self.is_service_not_available = true);
    }
  },
  created() {
    let self = this;

    self.checkAvailableServer(() => {
      let url = `${self.$store.state.server_url}/api/v/tt/get-tasks`;
      self.loading_tasks = true;
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      };
      axios.get(url, config)
          .then((response) => {
            self.content = response.data.data;
            self.loading_tasks = false;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('login');
              })
            } else {
              self.$store.state.sheet = true
            }
          });
    });
  }
}
</script>

<style>
.table-custom th,
.table-custom td {
  vertical-align: middle;
}
</style>