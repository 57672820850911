export default {
    state: {
        content: [],
        selected_phrases: [],
        loading: true
    },
    mutations: {
        updateLoading1(state, value) {
            state.loading = value
        },
        updateByWords1(state, value) {
            state.content = value
        },
        updateByWords1Selected(state, value) {
            state.selected_phrases = value
        },
    }
};