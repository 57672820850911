import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import buefy from './plugins/buefy'
import router from './router/router'
import store from './store/index'
// import VueAnalytics from "vue-analytics";

// Vue.use(VueAnalytics, {
//     id: 'UA-153988207-1'
// });


Vue.config.productionTip = false;

new Vue({
    vuetify,
    // buefy,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
