<template>
    <v-container
            class="fill-height"
            style="height: 90vh"
    >
        <v-row
                align="center"
                justify="center"
        >
            <v-col
                    cols="4"
            >
                <v-card
                        class="pa-md-4 mx-lg-auto"
                        color="white"
                        elevation="12"
                >
                    <v-card-title>Login Form</v-card-title>
                    <v-card-text>
                        <v-text-field
                                label="Email"
                                v-model="email"
                        ></v-text-field>
                        <v-text-field
                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show3 ? 'text' : 'password'"
                                name="input-10-2"
                                label="Password"
                                value="wqfasds"
                                v-model="password"
                                class="input-group--focused"
                                @click:append="show3 = !show3"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                                color="primary"
                                @click="login"
                                :loading="loading"
                                class="mr-2"
                        >
                            Login
                        </v-btn>
                        <router-link
                                to="register"
                        >
                            <v-btn
                                    color="primary"
                            >
                                Create account
                            </v-btn>
                        </router-link>
                    </v-card-actions>
                </v-card>
                <v-snackbar
                        v-model="snackbar"
                >
                    {{ message }}
                    <v-btn
                            color="pink"
                            text
                            @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                snackbar: false,
                show3: false,
                email: '',
                password: '',
            }
        },
        computed: {
            message() {
                return this.$store.state.auth.response.msg
            },
            loading: {
                set(value) {
                    this.$store.state.auth.loading = value
                },
                get() {
                    return this.$store.state.auth.loading
                }
            }
        },
        methods: {
            login() {
                let self = this;

                self.loading = true;

                self.$store.dispatch('login', {
                    email: self.email,
                    password: self.password,
                    then() {
                        let redirect_url = self.$store.state.redirect_to_after_login;

                        if (redirect_url !== '') {
                            self.$store.state.redirect_to_after_login = '';
                            self.$router.push(redirect_url)
                        } else {
                            self.$router.push('/')
                        }
                    },
                    catch() {
                        self.snackbar = true;
                    }
                })
            }
        },
    }
</script>

<style scoped>

</style>