var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading || _vm.content.length !== 0)?_c('v-card',{staticClass:"mb-12 mt-2 mx-3",attrs:{"loading":_vm.loading,"raised":""}},[_c('v-card-title',[(_vm.content.length === 0 && !_vm.loading)?_c('v-chip',{staticClass:"ma-2 float-right"},[_vm._v(" Empty ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"label":"Search","solo":"","prepend-inner-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.content.length > 0)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeader,"items":_vm.content,"custom-sort":_vm.custom_sort,"custom-filter":_vm.custom_filter,"search":_vm.search,"item-key":"ph","show-select":"","items-per-page":10,"footer-props":{'items-per-page-options': [10, 50, 100, 200, -1]}},scopedSlots:_vm._u([(_vm.visibleCompareUrl)?{key:"header.our",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Compare Url")])])]}}:null,{key:"header.rwc",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Recommended Word Count")])])]}},{key:"header.rwc_med",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Recommended Word Count (Median)")])])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.ph},[_c('td',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"hide-details":"","value":item,"show-select":""},model:{value:(_vm.selected_phrases),callback:function ($$v) {_vm.selected_phrases=$$v},expression:"selected_phrases"}})],1),_c('td',[_vm._v(" "+_vm._s(item.ph)+" "),_c('v-btn',{attrs:{"small":"","text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.examples_of_using(item.ph)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-comment-question")])],1),_c('v-btn',{attrs:{"small":"","text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.translate(item.ph)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-google-translate")])],1),(_vm.addedToRelinking[item.ph] === undefined)?_c('v-btn',{attrs:{"small":"","text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.addToRelinking(item.ph)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-link")])],1):_c('v-btn',{attrs:{"small":"","text":"","icon":"","color":"grey"}},[_c('v-icon',{attrs:{"size":"16","color":"success"}},[_vm._v("mdi-check")])],1)],1),(_vm.needShowVolume)?_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.vol))]):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(Math.round(item.docs * 100 / _vm.size_child))+"%")]),(_vm.visibleCompareUrl)?_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.our[0]))])]}}],null,true)},[_c('span',[_vm._v(" Title: "+_vm._s(_vm.compare_text.title)),_c('br'),_vm._v(" H1: "+_vm._s(_vm.compare_text.h1)),_c('br'),_vm._v(" Url: "+_vm._s(_vm.compare_text.url)),_c('br'),_vm._v(" Text Length: "+_vm._s(_vm.compare_text.length)+" ")])]),_c('v-icon',{attrs:{"size":"14","color":_vm.getOurIcon(item, 0).color},domProps:{"innerHTML":_vm._s(_vm.getOurIcon(item, 0).icon)}}),(item.our[0] !== 0 || _vm.getOurIcon(item, 1).icon !== 'mdi-arrow-up-thick')?_c('span',[_c('br'),_vm._v(" "+_vm._s(item.our[1])+"% "),_c('v-icon',{attrs:{"size":"14","color":_vm.getOurIcon(item, 1).color},domProps:{"innerHTML":_vm._s(_vm.getOurIcon(item, 1).icon)}})],1):_vm._e()],1):_vm._e(),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(Math.floor(_vm.base_url_key !== -1 ? item.child[_vm.base_url_key][0] : item.med[0]))),_c('br'),_vm._v(_vm._s(_vm.base_url_key !== -1 ? item.child[_vm.base_url_key][1] : item.med[1])+"% ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.calcMinEntry(_vm.base_url_key !== -1 ? item.child[_vm.base_url_key] : item.med, item.ph.split(" ").length))+" - "+_vm._s(_vm.calcMaxEntry(_vm.base_url_key !== -1 ? item.child[_vm.base_url_key] : item.med, item.ph.split(" ").length))+" ")]),_vm._l((item.child),function(v,k){return _c('td',{key:k,staticClass:"text-center",class:_vm.getClass(v, 'child').color},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(v[0]))]),_c('br')]}}],null,true)},[_c('span',[_vm._v(" Url: "+_vm._s(item.headers[k].url)),_c('br'),_vm._v(" Text Length: "+_vm._s(item.headers[k].length)),_c('br'),_vm._v(" Additions: "+_vm._s(_vm.additions[0])+" ")])]),_vm._v(" "+_vm._s(v[1])+"% ")],1)})],2)}),0)]}}],null,true),model:{value:(_vm.selected_phrases),callback:function ($$v) {_vm.selected_phrases=$$v},expression:"selected_phrases"}}),_c('v-bottom-sheet',{attrs:{"inset":"","hide-overlay":true,"persistent":false},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"prominent":"","type":"info","icon":"mdi-google-translate"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow",domProps:{"innerHTML":_vm._s(_vm.sheet_message)}}),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"white","outlined":""},on:{"click":function($event){_vm.sheet = false}}},[_vm._v("Okay ")])],1)],1)],1)],1),_c('v-bottom-sheet',{attrs:{"inset":"","hide-overlay":true,"persistent":false},model:{value:(_vm.e_sheet),callback:function ($$v) {_vm.e_sheet=$$v},expression:"e_sheet"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"prominent":"","border":"top","colored-border":"","color":"white"}},[(_vm.e_sheet_loading === false)?_c('v-container',{staticStyle:{"height":"200px"}},[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Getting examples of using ")]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","rounded":"","height":"6"}})],1)],1)],1):_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow",domProps:{"innerHTML":_vm._s(_vm.e_sheet_message)}})],1)],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }