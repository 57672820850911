import {
    Document,
    Paragraph,
    Table,
    TableRow,
    TableCell,
    TextRun,
    WidthType,
    AlignmentType
} from "docx";

export const exportDocx = {
    methods: {
        generate_docx(task_data) {
            const doc = new Document({
                numbering: {
                    config: [
                        {
                            levels: [
                                {
                                    level: 0,
                                    format: "decimal",
                                    text: "%1",
                                    alignment: AlignmentType.START,
                                    style: {
                                        paragraph: {
                                            indent: {left: 720, hanging: 260},
                                        },
                                    },
                                },
                            ],
                            reference: "phrases_for_linking",
                        },
                        {
                            levels: [
                                {
                                    level: 0,
                                    format: "decimal",
                                    text: "%1",
                                    alignment: AlignmentType.START,
                                    style: {
                                        paragraph: {
                                            indent: {left: 720, hanging: 260},
                                        },
                                    },
                                },
                            ],
                            reference: "requirements",
                        },
                        {
                            levels: [
                                {
                                    level: 0,
                                    format: "decimal",
                                    text: "%1",
                                    alignment: AlignmentType.START,
                                    style: {
                                        paragraph: {
                                            indent: {left: 720, hanging: 260},
                                        },
                                    },
                                },
                            ],
                            reference: "guide_list",
                        },
                        {
                            levels: [
                                {
                                    level: 0,
                                    format: "decimal",
                                    text: "%1",
                                    alignment: AlignmentType.START,
                                    style: {
                                        paragraph: {
                                            indent: {left: 720, hanging: 260},
                                        },
                                    },
                                },
                            ],
                            reference: "examples_urls",
                        }
                    ]
                },
                sections: []
            })

            let children = []

            // doc.addSection({
            //     headers: {
            //         default: new Header({
            //             children: [new Paragraph({
            //                 text: `Task ${task_data.id}`,
            //                 heading: HeadingLevel.HEADING_1
            //             })],
            //         }),
            //     },
            //     properties: {},
            //     children: []
            // })

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Title: ${task_data.title}`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Description: ${task_data.description}`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Url: ${task_data.url}`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Word Count: ${task_data.word_count}`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Live Editor: https://app.contentball.com/${task_data.live_editor_uri}`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Structure: ${task_data.structure}`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Main KW:`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            let main_keywords_headers_count = task_data.main_keywords.headers.length
            let main_keywords_headers = []

            for (let i = 0; i < main_keywords_headers_count; i++) {
                main_keywords_headers.push(new TableCell({
                    children: [new Paragraph(task_data.main_keywords.headers[i])],
                }))
            }

            let main_keywords_rows = [
                new TableRow({
                    children: main_keywords_headers,
                }),
            ]

            for (let i = 0; i < task_data.main_keywords.items.length; i++) {
                let main_keywords_items = []

                for (let y = 0; y < main_keywords_headers_count; y++) {
                    main_keywords_items.push(new TableCell({
                        children: [new Paragraph(task_data.main_keywords.items[i][y])],
                    }))
                }

                let table_row = new TableRow({
                    children: main_keywords_items,
                })

                main_keywords_rows.push(table_row)
            }

            children.push(new Table({
                rows: main_keywords_rows,
                width: {
                    size: 7000,
                    type: WidthType.DXA,
                },
            }))

            let keywords_headers_count = task_data.need_use_phrases.headers.length
            let keywords_headers = []

            for (let i = 0; i < keywords_headers_count; i++) {
                keywords_headers.push(new TableCell({
                    children: [new Paragraph(task_data.need_use_phrases.headers[i])],
                }))
            }

            let keywords_rows = [
                new TableRow({
                    children: keywords_headers,
                }),
            ]

            for (let i = 0; i < task_data.need_use_phrases.items.length; i++) {
                let keywords_items = []

                for (let y = 0; y < keywords_headers_count; y++) {
                    keywords_items.push(new TableCell({
                        children: [new Paragraph(task_data.need_use_phrases.items[i][y])],
                    }))
                }

                let table_row = new TableRow({
                    children: keywords_items,
                })

                keywords_rows.push(table_row)
            }

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Keywords for first 150 words:`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: task_data.keywords_for_first_150_words,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Key phrases for the whole content (use of them):`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Note! The requirements can indicate that the phrase, for examlpe, "this new word" should be used 3 times, and the phrase "new word" should be used 6 times. This means that the phrase "new word" should be used 3 times in the phrase "this new word" in the text, and 3 times in other parts of the text. As a result, the phrase "new word" in the text will be used 6 times, in accordance with the requirements.`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(new Table({
                rows: keywords_rows,
                width: {
                    size: 7000,
                    type: WidthType.DXA,
                },
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: `Phrases for linking (the phrases for linking should be uniformly and organically distributed throughout the text. Do not put links in the first and last paragraphs. Do not input links in the introduction or in conclusion):`,
                        bold: true,
                    }),
                ],
            }))

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            for (let i = 0; i < task_data.phrases_for_linking.length; i++) {
                children.push(
                    new Paragraph({
                        text: task_data.phrases_for_linking[i],
                        numbering: {
                            reference: "phrases_for_linking",
                            level: 0,
                        },
                    }),
                )
            }

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `Characteristics:`,
                            bold: true,
                        }),
                    ],
                }),
            )

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            for (let i = 0; i < task_data.requirements.length; i++) {
                children.push(
                    new Paragraph({
                        text: task_data.requirements[i],
                        numbering: {
                            reference: "requirements",
                            level: 0,
                        },
                    }),
                )
            }

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `Guide (pay respect to previous requirements):`,
                            bold: true,
                        }),
                    ],
                }),
            )

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            for (let i = 0; i < task_data.guide_list.length; i++) {
                children.push(
                    new Paragraph({
                        text: task_data.guide_list[i],
                        numbering: {
                            reference: "guide_list",
                            level: 0,
                        },
                    }),
                )
            }

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            children.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `Examples:`,
                            bold: true,
                        }),
                    ],
                }),
            )

            children.push(new Paragraph({
                children: [
                    new TextRun({
                        text: ''
                    }),
                ],
            }))

            for (let i = 0; i < task_data.examples_urls.length; i++) {
                children.push(
                    new Paragraph({
                        text: task_data.examples_urls[i],
                        numbering: {
                            reference: "examples_urls",
                            level: 0,
                        },
                    }),
                )
            }

            doc.addSection({
                headers: {},
                children: children
            })

            return doc
        }
    }
}