import axios from "axios";

export default {
    state: {
        content: [],
        selected_phrases: [],
        loading: true
    },
    actions: {
        fetchSnippet(ctx) {
            let url = `${ctx.rootState.server_url}/api/v/tt/density-by-type/snippet`;
            ctx.commit('updateLoadingSnippet', true);

            if (ctx.rootState.enable_test_data) {
                setTimeout(function () {
                    let data = JSON.parse('[{"ph":"papersowl review","vol":1500,"avg":[1,0.3],"med":[1,0.3],"our":[0,0],"docs":2,"headers":[{"vision":1,"url":"https:\\/\\/www.topdissertations.org\\/papersowl-review\\/","length":1222},{"vision":1,"url":"https:\\/\\/awriter.org\\/papersowl-com-review\\/","length":549},{"vision":1,"url":"https:\\/\\/scamfighter.net\\/review\\/papersowl.com","length":1208},{"vision":1,"url":"https:\\/\\/reviewingwriting.com\\/papersowl-review\\/","length":0},{"vision":1,"url":"https:\\/\\/www.sitejabber.com\\/reviews\\/papersowl.com","length":0},{"vision":1,"url":"https:\\/\\/paidpaper.net\\/papersowl-com-review\\/","length":0},{"vision":1,"url":"https:\\/\\/www.reddit.com\\/r\\/ApplyingToCollege\\/comments\\/dbwj66\\/papersowl_review\\/","length":0},{"vision":1,"url":"https:\\/\\/papersowl.com\\/what-is-papersowl","length":0},{"vision":1,"url":"https:\\/\\/papersowl.com\\/essay-writing-service-review","length":0},{"vision":1,"url":"https:\\/\\/www.quora.com\\/Is-Papersowl-legit","length":0},{"vision":1,"url":"https:\\/\\/sky-writer.com\\/papersowl-review\\/","length":0},{"vision":1,"url":"https:\\/\\/typemyessays.com\\/blog\\/papersowl-review","length":0},{"vision":1,"url":"https:\\/\\/essayservicescanner.com\\/papersowl-review\\/","length":0},{"vision":1,"url":"https:\\/\\/www.writingpapersucks.com\\/papersowl-com-review\\/","length":0},{"vision":1,"url":"https:\\/\\/essayreviewexpert.com\\/review\\/papersowl\\/","length":0}],"child":[[1,0.2],[1,0.4],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]]}]');

                    ctx.commit('updateSnippetContent', data);
                    ctx.commit('updateLoadingSnippet', false);
                }, 1000);
                return;
            }

            let postData = {
                urls: ctx.getters.preparedSelectedUrlsForReports,
                serp: ctx.rootState.stepper.s2.serp,
                language: ctx.rootState.stepper.s1.language,
                compare_url: ctx.rootState.stepper.s1.compare_url,
                snippet: ctx.rootState.stepper.s2.snippet,
                disable_stop_words: ctx.rootState.stepper.s4.disable_stop_words
            };

            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ctx.rootState.auth.token
                }
            };

            axios.post(url, postData, config)
                .then((response) => {
                    ctx.commit('updateSnippetContent', response.data);
                    ctx.commit('updateLoadingSnippet', false);
                })
                .catch(() => ctx.rootState.sheet = true);
        }
    },
    mutations: {
        updateLoadingSnippet(state, value) {
            state.loading = value
        },
        updateSnippetContent(state, value) {
            state.content = value
        }
    }
};