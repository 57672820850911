<template>
  <div>
    <v-stepper-content :step="step">
      <div v-if="disabledDownloadBtn">
        <v-container>
          <v-row>
            <v-col>
              <GeneralInfo/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <Result/>
            </v-col>
            <v-col>
              <MainKeywords/>
              <PhrasesInTop150Words/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <vue-editor
                  placeholder="Enter your structure ..."
                  v-model="structure"
                  :editorToolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
        </v-container>
        <v-btn @click="setCurrentTab(step - 1)" text>back to general characteristics</v-btn>
        <v-btn class="mx-2" color="primary" :disabled="disabledExportBtn" :loading="disabledExportBtn"
               @click="exportReport()">Save Task
        </v-btn>
      </div>
      <div v-if="false === disabledDownloadBtn">
        <v-container>
          <v-row class="text-center">
            <v-col>
              <h1>
                <v-icon size="80" color="primary">mdi-check-all</v-icon>
              </h1>
              <div class="text-h6 my-8">
                Technical assignment successfully saved!
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn outlined color="primary" @click="downloadDocx(taskData.hash)">
                Download .docx
                <v-icon size="17" class="ml-2">mdi-file-word-outline</v-icon>
              </v-btn>
              <router-link :to="`/editor/${taskData.user_id}/${taskData.hash}`" class="ml-5">
                <v-btn outlined color="primary">
                  Go to Live Editor
                  <v-icon size="17" class="ml-2">mdi-file-document-edit-outline</v-icon>
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>

      </div>
    </v-stepper-content>
  </div>
</template>

<script>
import MainKeywords from './Child/MainKeywords'
import Result from './Child/Result'
// import ResultFinal from './Child/ResultFinal'
import PhrasesInTop150Words from './Child/PhrasesInTop150Words'
import {VueEditor} from 'vue2-editor'
// import {Document, Paragraph, Packer, TextRun, Table, TableRow, TableCell, WidthType} from "docx";
// import {saveAs} from 'file-saver';
import axios from 'axios';
import GeneralInfo from "./Child/GeneralInfo";
import {exportDocx} from "@/mixins/exportDocx";
import {saveAs} from "file-saver"
import {Packer} from "docx";

export default {
  name: 'Step7',
  data() {
    return {
      customToolbar: [],
      step: 9,
      disabledExportBtn: false,
      disabledDownloadBtn: true,
      taskData: {
        msg: 'No data',
        user_id: 0,
        hash: '',
      }
    }
  },
  components: {
    GeneralInfo,
    Result,
    // ResultFinal,
    MainKeywords,
    PhrasesInTop150Words,
    VueEditor
  },
  methods: {
    exportReport: function () {
      this.saveTask();
    },
    downloadDocx(hash) {
      let self = this

      let url = `${self.$store.state.server_url}/api/v/tt/get-doc/${self.taskData.user_id}/${hash}`;

      let config = {
        params: {
          json: true
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .then((response) => {
            console.log(response.data)
            let doc = exportDocx.methods.generate_docx(response.data)

            Packer.toBlob(doc).then((blob) => {
              saveAs(blob, "example.docx");
            });
          })
          .catch((error) => {
            console.log(error)
            self.$store.state.sheet = true
          })
    },
    downloadFile() {
      location.href = `${this.$store.state.server_url}/api/v/tt/get-doc/${this.taskData.user_id}/${this.taskData.hash}`;
    },
    setCurrentTab(tab) {
      this.$store.state.stepper.current_tab = tab
    },
    saveTask() {
      let self = this;
      let url = `${this.$store.state.server_url}/api/v/tt/save-task`;
      this.disabledExportBtn = true;

      let postData = {
        _token: 'undefined',
        data: {
          urls: this.$store.getters.preparedSelectedUrlsForReports,
          main_phrases: this.$store.state.stepper.processing.selected_phrases.filter((v) => v.favorite),
          step1: this.$store.state.stepper.s1,
          step5: this.$store.state.stepper.s5,
          step6: this.$store.state.stepper.s6,
          step7: this.$store.state.stepper.s7,
          processing: this.$store.state.stepper.processing,
          step3: {
            additions: this.$store.state.stepper.s3.additions,
            structure: this.$store.state.stepper.s3.structure,
          },
        }
      };

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$store.state.auth.token
        }
      };

      axios.post(url, postData, config)
          .then((response) => {
            this.taskData = response.data;
            this.disabledDownloadBtn = false;
          })
          .catch(() => self.$store.state.sheet = true);
    }
  },
  computed: {
    structure: {
      get() {
        if (this.$store.state.stepper.current_tab === this.step) {
          return this.$store.state.stepper.s3.structure
        }
        return ''
      },
      set(value) {
        if (this.$store.state.stepper.current_tab === this.step) {
          this.$store.state.stepper.s3.structure = value
        } else {
          this.$store.state.stepper.s3.structure = ''
        }
      },
    },
  }
}
</script>