<template>
  <v-card
      class="mb-12 mt-2 mx-3"
      :loading="loading"
      raised
      v-if="loading || content.length !== 0"
  >
    <v-card-title>
      <v-chip
          v-if="content.length === 0 && !loading"
          class="ma-2 float-right"
      >
        Empty
      </v-chip>
      {{ title }}
      <v-spacer></v-spacer>
      <v-text-field
          class="pt-0 mt-0"
          v-model="search"
          label="Search"
          solo
          prepend-inner-icon="mdi-magnify"
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text v-if="content.length > 0">
      <v-data-table
          v-model="selected_phrases"
          :headers="tableHeader"
          :items="content"
          :custom-sort="custom_sort"
          :custom-filter="custom_filter"
          :search="search"
          item-key="ph"
          show-select
          class="elevation-1"
          :items-per-page="10"
          :footer-props="{'items-per-page-options': [10, 50, 100, 200, -1]}"
      >
        <template v-slot:header.our="{ header }" v-if="visibleCompareUrl">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ header.text }}</span>
            </template>
            <span>Compare Url</span>
          </v-tooltip>
        </template>
        <template v-slot:header.rwc="{ header }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ header.text }}</span>
            </template>
            <span>Recommended Word Count</span>
          </v-tooltip>
        </template>
        <template v-slot:header.rwc_med="{ header }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ header.text }}</span>
            </template>
            <span>Recommended Word Count (Median)</span>
          </v-tooltip>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="item in items" :key="item.ph">
            <td>
              <v-checkbox class="mt-0" hide-details v-model="selected_phrases" :value="item" show-select/>
            </td>
            <td>
              {{ item.ph }}
              <v-btn small text icon color="grey" @click="examples_of_using(item.ph)">
                <v-icon size="16">mdi-comment-question</v-icon>
              </v-btn>
              <v-btn small text icon color="grey" @click="translate(item.ph)">
                <v-icon size="16">mdi-google-translate</v-icon>
              </v-btn>
              <v-btn v-if="addedToRelinking[item.ph] === undefined" small text icon color="grey" @click="addToRelinking(item.ph)">
                <v-icon size="16">mdi-link</v-icon>
              </v-btn>
              <v-btn v-else small text icon color="grey">
                <v-icon size="16" color="success">mdi-check</v-icon>
              </v-btn>
            </td>
            <td class="text-center" v-if="needShowVolume">{{ item.vol }}</td>
            <td class="text-center">{{ Math.round(item.docs * 100 / size_child) }}%</td>
            <td class="text-center" v-if="visibleCompareUrl">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"><span v-on="on">{{ item.our[0] }}</span></template>
                <span>
                  Title: {{ compare_text.title }}<br>
                  H1: {{ compare_text.h1 }}<br>
                  Url: {{ compare_text.url }}<br>
                  Text Length: {{ compare_text.length }}
                </span>
              </v-tooltip>
              <v-icon
                  size="14"
                  :color="getOurIcon(item, 0).color"
                  v-html="getOurIcon(item, 0).icon"
              ></v-icon>
              <span v-if="item.our[0] !== 0 || getOurIcon(item, 1).icon !== 'mdi-arrow-up-thick'">
                                 <br>
                                {{ item.our[1] }}%
                                <v-icon
                                    size="14"
                                    :color="getOurIcon(item, 1).color"
                                    v-html="getOurIcon(item, 1).icon"
                                ></v-icon>
                            </span>
            </td>
            <td class="text-center">
              {{
                Math.floor(base_url_key !== -1 ? item.child[base_url_key][0] : item.med[0])
              }}<br>{{ base_url_key !== -1 ? item.child[base_url_key][1] : item.med[1] }}%
            </td>
            <td class="text-center">
              {{ calcMinEntry(base_url_key !== -1 ? item.child[base_url_key] : item.med, item.ph.split(" ").length) }} -
              {{ calcMaxEntry(base_url_key !== -1 ? item.child[base_url_key] : item.med, item.ph.split(" ").length) }}
            </td>
            <td v-for="(v, k) in item.child" v-bind:key="k" :class="getClass(v, 'child').color"
                class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }"><span v-on="on">{{ v[0] }}</span><br></template>
                <span>
                  Url: {{ item.headers[k].url }}<br>
                  Text Length: {{ item.headers[k].length }}<br>
                  Additions: {{ additions[0] }}
                </span>
              </v-tooltip>
              {{ v[1] }}%
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-bottom-sheet v-model="sheet" inset :hide-overlay="true" :persistent="false">
        <v-alert
            prominent
            type="info"
            icon="mdi-google-translate"
            class="mb-0"
        >
          <v-row align="center">
            <v-col class="grow" v-html="sheet_message"></v-col>
            <v-col class="shrink">
              <v-btn
                  v-on:click="sheet = false"
                  color="white"
                  outlined
              >Okay
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-bottom-sheet>
      <v-bottom-sheet v-model="e_sheet" inset :hide-overlay="true" :persistent="false">
        <v-alert
            prominent
            border="top"
            colored-border
            color="white"
            class="mb-0"
        >
          <v-container style="height: 200px;" v-if="e_sheet_loading === false">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
              <v-col
                  class="subtitle-1 text-center"
                  cols="12"
              >
                Getting examples of using
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                    color="info"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
          <v-row align="center" v-else>
            <v-col class="grow" v-html="e_sheet_message"></v-col>
          </v-row>
        </v-alert>
      </v-bottom-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import {calcMinMaxMixin} from "../../../mixins/calcMinMaxEntryMixin";

export default {
  name: "Report",
  mixins: [calcMinMaxMixin],
  data() {
    return {
      e_sheet: false,
      e_sheet_loading: false,
      sheet: false,
      sheet_message: "Placeholder",
      e_sheet_message: "Placeholder",
      search: ''
    }
  },
  methods: {
    addToRelinking(phrase){
      this.$store.state.stepper.s6.relinking.push({anchor: phrase, url: ''})
    },
    examples_of_using(phrase) {
      let self = this;

      self.e_sheet = true;
      self.e_sheet_loading = false;
      self.e_sheet_message = "Wait a few seconds";

      let url = `${self.$store.state.server_url}/api/v/tt/examples-of-using`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      };

      let post_data = {
        phrase: phrase,
        urls: this.$store.state.stepper.s3.selected_urls
      };

      axios.post(url, post_data, config)
          .then((response) => {
            self.e_sheet_loading = true;
            self.e_sheet_message = '<ol>' + response.data.map((v) => `<li>${v.replace(new RegExp(phrase, 'gsi'), `<b>${phrase}</b>`)}</li>`).join('') + '</ol>';
          });
    },
    translate(string) {
      let self = this;

      self.sheet = false;
      self.sheet_message = "Wait a few seconds";

      let url = `${self.$store.state.server_url}/api/v/tt/get-translation`;

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        },
        params: {
          lang_source: self.$store.state.stepper.s1.language,
          lang_target: self.$store.state.stepper.s1.translation_language,
          string: string
        }
      };

      axios.get(url, config)
          .then((response) => {
            self.sheet = true;
            self.sheet_message = `<b>Original:</b> ${string} <br><b>Translation:</b> ${response.data.translation}`;
          });
    },
    custom_filter(value, search, item) {
      let search_items = [];
      search = search.toString().toLowerCase()

      if (search.indexOf('+') !== -1) {
        search_items = search.split('+').map((v) => v.trim())
      }

      if (search_items.length === 0) {
        return item.ph.indexOf(search) !== -1
      }

      return search_items.filter((v) => item.ph.indexOf(v) === -1).length === 0
    },
    custom_sort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        if (!sortDesc) {
          return this.compare(a, b);
        } else {
          return this.compare(b, a);
        }
      });
      return items;
    },
    compare(a, b) {
      let docs1 = a.docs;
      let docs2 = b.docs;

      let rwcMed1 = this.calcMinEntry(a.med, a.ph.split(" ").length);
      let rwcMed2 = this.calcMinEntry(b.med, b.ph.split(" ").length);

      if (docs1 < docs2) return -1;
      if (docs1 > docs2) return 1;
      if (rwcMed1 < rwcMed2) return -1;
      if (rwcMed1 > rwcMed2) return 1;
      return 0;
    },
    getOurIcon(item, type) {
      let icon = '';
      let color = '';

      if (type === 0) {
        let entry = item.our[0];

        let minRwc = this.calcMinEntry(item.med, item.ph.split(" ").length);
        let maxRwc = this.calcMaxEntry(item.med, item.ph.split(" ").length);

        let delta_min = Math.abs((entry - minRwc) * 100 / minRwc);
        let delta_max = Math.abs((entry - maxRwc) * 100 / maxRwc);

        if (item.our[1] === item.med[1]) {
          color = 'success';
        } else if (entry > maxRwc && delta_max > 15 && delta_max <= 30) {
          icon = 'mdi-arrow-down-right-thick';
          color = 'warning';
        } else if (entry > maxRwc && delta_min > delta_max && delta_max > 30) {
          icon = 'mdi-arrow-down-thick';
          color = 'error';
        } else if (entry < minRwc && delta_min > 15 && delta_min <= 30) {
          icon = 'mdi-arrow-up-thick';
          color = 'warning';
        } else if (entry < minRwc && delta_min > 30) {
          icon = 'mdi-arrow-up-thick';
          color = 'error';
        } else if (entry > minRwc && entry < maxRwc) {
          color = 'success';
        } else if ((entry < minRwc && delta_min < 15) || (entry > maxRwc && delta_max < 15)) {
          icon = '';
        }
      } else {
        let entry = item.our[1];
        let median = item.med[1];

        let delta = Math.abs((entry - median) * 100 / median);

        if ((entry > median && delta < 15) || (entry < median && delta < 15)) {
          icon = '';
        } else if (entry > median && delta > 15 && delta <= 30) {
          icon = 'mdi-arrow-down-right-thick';
          color = 'warning';
        } else if (entry > median && delta > 30) {
          icon = 'mdi-arrow-down-thick';
          color = 'error';
        } else if (entry < median && median > 15 && median <= 30) {
          icon = 'mdi-arrow-up-right-thick';
          color = 'warning';
        } else if (entry < median && delta > 30) {
          icon = 'mdi-arrow-up-thick';
          color = 'error';
        } else if (entry === median) {
          color = 'success';
        }
      }

      return {
        icon: icon,
        color: color
      };
    },
    getClass(item, type = 'rwc') {
      let self = this;
      let result = {};
      let colors = {
        success: 'light-green lighten-4',
        danger: 'amber lighten-4'
      };
      let minAvgRec,
          minMedianRec;
      // maxRWC = 0;

      if (this.getComponent === 'MainPhrasesBySerp' ||
          this.getComponent === 'Snippet' ||
          this.getComponent === 'Related' ||
          this.getComponent === 'Suggest' ||
          this.getComponent === 'AdditionalPhrasesByVision' ||
          this.getComponent === 'KeywordsFromMainPhrasesByVision'
      ) {
        if (type === 'rwc') {
          minAvgRec = Math.round(item.avg[1] / 100 * self.additions[0]);
          minMedianRec = Math.round(item.med[1] / 100 * self.additions[0]);
          // maxRWC = Math.round(item.avg[1] / 100 * self.additions[1]);

          if (item.our[0] === 0 && (minAvgRec >= 1 || minMedianRec >= 1)) {
            result.color = colors.danger;
          }

          if (item.our[0] < item.avg[0]) {
            result.color = colors.danger;
          }

          if (item.our[0] > item.avg[0]) {
            result.colorRWC = colors.danger;
          }
        } else if (type === 'child') {
          result.color = item[0] > 0 ? colors.success : ''
        }
      } else if (this.getComponent === 'ByWords' ||
          this.getComponent === 'WithoutStopWords2Others' ||
          this.getComponent === 'WithoutStopWords2Default' ||
          this.getComponent === 'WithoutStopWords3Others' ||
          this.getComponent === 'WithoutStopWords3StartEnd' ||
          this.getComponent === 'WithoutStopWords3Default' ||
          this.getComponent === 'WithoutStopWords4Others' ||
          this.getComponent === 'WithoutStopWords4StartEnd' ||
          this.getComponent === 'WithoutStopWords4Default' ||
          this.getComponent === 'WithoutStopWords5Others' ||
          this.getComponent === 'WithoutStopWords5StartEnd' ||
          this.getComponent === 'WithoutStopWords5Default'
      ) {
        if (type === 'rwc') {
          minAvgRec = Math.round(item.avg[1] / 100 * self.additions[0]);
          minMedianRec = Math.round(item.med[1] / 100 * self.additions[0]);
          // maxRWC = Math.round(item.avg[1] / 100 * self.additions[1]);

          if (item.our[0] === 0 && (minAvgRec >= 1 || minMedianRec >= 1)) {
            result.color = colors.danger;
          }

          if (item.our[0] < item.avg[0]) {
            result.color = colors.danger;
          }

          if (item.our[0] > item.avg[0]) {
            result.colorRWC = colors.danger;
          }
        } else if (type === 'child') {
          if (item[0] > 0) {
            result.color = colors.success;
          } else if (item[0] === 0) {
            result.color = colors.danger;
          }
        }
      }

      return result
    },
  },
  props: ['title', 'content', 'loading', 'component'],
  watch: {
    loading() {
      let self = this;
      let content = this.content;

      content.sort((a, b) => {
        return this.compare(b, a);
      });

      if (this.getComponent === "WithoutStopWords3StartEnd") {
        this.selected_phrases = content.filter((x) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords3Default") {
        this.selected_phrases = content.filter((x, i) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || i < 10 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords2Default") {
        this.selected_phrases = content.filter((x, i) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || i < 20 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords4StartEnd") {
        this.selected_phrases = content.filter((x) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords4Default") {
        this.selected_phrases = content.filter((x, i) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || i < 10 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords5StartEnd") {
        this.selected_phrases = content.filter((x) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords5Default") {
        this.selected_phrases = content.filter((x, i) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || i < 10 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponent === "WithoutStopWords2Others" || this.getComponent === "WithoutStopWords3Others" || this.getComponent === "WithoutStopWords4Others" || this.getComponent === "WithoutStopWords5Others") {
        this.selected_phrases = [];
      } else if (
          this.getComponent === "MainPhrasesBySerp" ||
          this.getComponent === "AdditionalPhrasesByVision" ||
          this.getComponent === "KeywordsFromMainPhrasesByVision" ||
          this.getComponent === "Snippet" ||
          this.getComponent === 'Related' ||
          this.getComponent === 'Suggest'
      ) {
        let selected_phrases = [];

        for (let i = 0; i < this.content.length; i++) {
          if (this.content[i] !== undefined && this.content[i].checked === true && this.content[i].child.filter((x) => parseInt(x[0]) !== 0).length > 0) {
            selected_phrases.push(this.content[i]);
          }
        }

        this.selected_phrases = selected_phrases;
      } else if (this.getComponent === "ByWords" && this.getComponentFull !== "ByWords1") {
        this.selected_phrases = content.filter((x) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.6 || len_top_child / len_all_child >= 0.4;
        });
      } else if (this.getComponentFull === "ByWords1") {
        this.selected_phrases = content.filter((x) => {
          let len_all_child = x.child.length;
          let len_good_child = x.child.filter((x) => x[0] !== 0).length;
          let len_top_child = x.child.filter((x, i) => x[0] !== 0 && i < len_all_child * 0.4).length;
          return len_good_child / len_all_child >= 0.75 && x.ph != parseInt(x.ph) || len_top_child / len_all_child >= 0.4;
        });
      }

      if (
          this.getComponent === "MainPhrasesBySerp" ||
          this.getComponent === "AdditionalPhrasesByVision" ||
          this.getComponent === "KeywordsFromMainPhrasesByVision" ||
          this.getComponent === "Snippet" ||
          this.getComponent === 'Related' ||
          this.getComponent === 'Suggest'
      ) {
        this.selected_phrases = this.selected_phrases.map(function (item) {
          item.favorite = true
          return item
        })
      } else {
        this.selected_phrases = this.selected_phrases.map(function (item) {
          item.favorite = Math.round(item.docs * 100 / self.size_child) === 100
          return item
        })
      }
    }
  },
  computed: {
    addedToRelinking(){
      let arr = []

      this.$store.state.stepper.s6.relinking.forEach((v) => arr[v.anchor] = 1)

      return arr
    },
    base_url_key() {
      if (this.$store.state.stepper.s4.base_url !== -1) {
        return this.$store.state.stepper.s4.base_url;
      }
      return -1
    },
    tableHeader() {
      let self = this;
      let columns = [
        {text: 'Phrase', value: 'ph'},
        {text: 'Volume', value: 'vol', align: 'center'},
        {text: `Popularity`, value: 'docs', sortable: true, align: 'center'},
        {text: 'CU', value: 'our', align: 'center', width: '90px'},
        {text: 'Median', value: 'med', align: 'center'},
        {text: 'RWC', value: 'rwc_med', align: 'center', sortable: true},
      ].filter((v) => {
        let cond1 = true;
        let cond2 = true;

        if (false === self.visibleCompareUrl) {
          cond1 = v.value !== 'our'
        }

        if (self.getComponent !== 'MainPhrasesBySerp' && self.getComponent !== 'AdditionalPhrasesByVision') {
          cond2 = v.value !== 'vol';
        }

        return cond1 && cond2
      });

      if (this.size_child > 0) {
        let headers = self.$props.content[0].headers;
        for (let i in headers) {
          let visibility = 0;
          if (this.$store.state.stepper.s2.visionByUrl[headers[i].url.trim()] !== undefined) {
            visibility = Math.round(this.$store.state.stepper.s2.visionByUrl[headers[i].url.trim()] * 100 / this.$store.getters.amountVisibility)
          }
          columns.push({text: `${visibility}%`, value: `p${i + 1}`, align: 'center'});
        }
      }

      return columns
    },
    selected_phrases: {
      get() {
        return this.$store.state.stepper.s4[this.$props.component].selected_phrases
      },
      set(newValue) {
        let self = this

        let old_phrases = this.$store.state.stepper.s4[this.$props.component].selected_phrases.map(x => x.ph)
        let new_phrases = newValue.map(x => x.ph)

        if(old_phrases !== new_phrases){
          let oldest_phrase = old_phrases.filter(x => !new_phrases.includes(x))[0]
          let newest_phrase = new_phrases.filter(x => !old_phrases.includes(x))[0]

          let components = [
            'MainPhrasesBySerp',
            'AdditionalPhrasesByVision',
            'KeywordsFromMainPhrasesByVision',
            'Snippet',
            'Related',
            'Suggest',
            'WithoutStopWords',
            'WithoutStopWords5Default',
            'WithoutStopWords5StartEnd',
            'WithoutStopWords5Others',
            'WithoutStopWords4Default',
            'WithoutStopWords4StartEnd',
            'WithoutStopWords4Others',
            'WithoutStopWords3Default',
            'WithoutStopWords3StartEnd',
            'WithoutStopWords3Others',
            'WithoutStopWords2Default',
            'WithoutStopWords2Others',
            'Processing',
            'ByWords10',
            'ByWords9',
            'ByWords8',
            'ByWords7',
            'ByWords6',
            'ByWords5',
            'ByWords4',
            'ByWords1'
          ].filter(x => x !== this.$props.component)

          for(let i = 0; i < components.length; i++){
            let component_name = components[i]

            let old_selected_phrases = self.$store.state.stepper.s4[component_name].selected_phrases
              .map(x => x.ph)
              .filter(x => x !== oldest_phrase)

            old_selected_phrases.push(newest_phrase)

            self.$store.state.stepper.s4[component_name].selected_phrases = self.$store.state.stepper.s4[component_name].content.filter(x => old_selected_phrases.includes(x.ph))
          }
        }

        self.$store.state.stepper.s4[self.$props.component].selected_phrases = newValue.map((v) => {
          v.resource = self.getComponent.indexOf('WithoutStopWords') !== -1 ? 'WithoutStopWords' : self.getComponent
          return v
        })
      }
    },
    additions() {
      return this.$store.state.stepper.s3.additions
    },
    size_child() {
      return this.$store.state.stepper.s3.selected_urls.length
    },
    getComponent() {
      return this.$props.component.indexOf('ByWords') !== -1 ? 'ByWords' : this.$props.component
    },
    getComponentFull() {
      return this.$props.component
    },
    visibleCompareUrl() {
      return this.$store.getters.hasCompareUrl
    },
    needShowVolume() {
      return this.getComponent === 'MainPhrasesBySerp' || this.getComponent === 'AdditionalPhrasesByVision'
    },
    compare_text() {
      return this.$store.state.stepper.s3.compare_text
    }
  },
  created(){
    // this.selected_phrases = this.selected_phrases.filter(x => x.phrase )
  }
}
</script>

<style scoped>
td {
  white-space: nowrap;
}
</style>