<template>
    <Report
            :title="title"
            :component="nameComponent"
            :loading="loading"
            :content="content"
    />
</template>

<script>
    const nameComponent = 'WithoutStopWords';
    import Report from './../Report'
    // import axios from 'axios';

    export default {
        name: nameComponent,
        props: ['type', 'title', 'count_words'],
        data(){
            return {
                nameComponent: nameComponent + this.$props.count_words + this.$props.type
            }
        },
        components: {
            Report
        },
        computed: {
            loading(){
                return this.$store.state.stepper.s4[this.nameComponent].loading
            },
            content: {
                get() {
                    return this.$store.state.stepper.s4[this.nameComponent].content
                },
                set(newValue) {
                    this.$store.state.stepper.s4[this.nameComponent].content = newValue
                }
            }
        }
    }
</script>

<style scoped>

</style>