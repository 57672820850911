<template>
    <v-container
            class="fill-height"
            style="height: 90vh"
    >
        <v-row
                align="center"
                justify="center"
        >
            <v-col
                    cols="4"
            >
                <v-form
                        ref="form"
                        v-model="valid"
                >
                    <v-card
                            class="pa-md-4 mx-lg-auto"
                            color="white"
                            elevation="12"
                    >
                        <v-card-title>Registration Form</v-card-title>
                        <v-card-text>

                            <v-text-field
                                    label="Name"
                                    :rules="nameRules"
                                    v-model="name"
                            ></v-text-field>
                            <v-text-field
                                    label="Email"
                                    :rules="emailRules"
                                    v-model="email"
                            ></v-text-field>
                            <v-text-field
                                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show3 ? 'text' : 'password'"
                                    :rules="passwordRules"
                                    name="input-10-2"
                                    label="Password"
                                    value="wqfasds"
                                    v-model="password"
                                    class="input-group--focused"
                                    @click:append="show3 = !show3"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                    color="primary"
                                    @click="register"
                                    :loading="loading"
                                    class="mr-2"
                            >
                                Create
                            </v-btn>
                            <router-link
                                    to="login"
                            >
                                <v-btn
                                        text
                                >
                                    Back
                                </v-btn>
                            </router-link>
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-snackbar
                        v-model="snackbar"
                >
                    {{ message }}
                    <v-btn
                            color="pink"
                            text
                            @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Register",
        data() {
            return {
                valid: false,
                snackbar: false,
                show3: false,
                name: '',
                nameRules: [
                    v => !!v || 'Name is required'
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                password: '',
                passwordRules: [
                    v => !!v || 'Password is required',
                    v => (v && v.length >= 8) || 'Password must be less than 8 characters',
                ],
                confirm_password: ''
            }
        },
        computed: {
            message() {
                return this.$store.state.auth.response_register.msg
            },
            loading: {
                set(value) {
                    this.$store.state.auth.loading = value
                },
                get() {
                    return this.$store.state.auth.loading
                }
            }
        },
        methods: {
            register() {
                if (false === this.$refs.form.validate()) {
                    return;
                }
                let self = this;

                self.loading = true;

                self.$store.dispatch('register', {
                    name: self.name,
                    email: self.email,
                    password: self.password,
                    then() {
                        self.$store.dispatch('login', {
                            email: self.email,
                            password: self.password,
                            then() {
                                let redirect_url = self.$store.state.redirect_to_after_login;

                                if (redirect_url !== '') {
                                    self.$store.state.redirect_to_after_login = '';
                                    self.$router.push(redirect_url)
                                } else {
                                    self.$router.push('/welcome')
                                }
                                self.loading = false;
                            },
                            catch() {
                                self.loading = false;
                            }
                        })
                    },
                    catch() {
                        self.snackbar = true;
                        self.loading = false;
                    }
                })
            }
        },
    }
</script>

<style scoped>

</style>