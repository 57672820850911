<template>
    <Report
            title="Snippet"
            :component="nameComponent"
            :loading="loading"
            :content="content"
    />
</template>

<script>
    const nameComponent = 'Snippet';
    import Report from './../Report';

    export default {
        name: nameComponent,
        data() {
            return {
                nameComponent: nameComponent
            }
        },
        components: {
            Report
        },
        computed: {
            loading() {
                return this.$store.state.stepper.s4[this.nameComponent].loading
            },
            content: {
                get() {
                    return this.$store.state.stepper.s4[this.nameComponent].content
                },
                set(newValue) {
                    this.$store.state.stepper.s4[this.nameComponent].content = newValue
                }
            },
        }
    }
</script>

<style scoped>

</style>