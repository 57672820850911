import KeywordsFromMainPhrasesByVision from './modules/KeywordsFromMainPhrasesByVision'
import AdditionalPhrasesByVision from './modules/AdditionalPhrasesByVision'
import MainPhrasesBySerp from './modules/MainPhrasesBySerp'
import WithoutStopWords from './modules/WithoutStopWords'
import WithoutStopWords5Default from './modules/WithoutStopWords5Default'
import WithoutStopWords5StartEnd from './modules/WithoutStopWords5StartEnd'
import WithoutStopWords5Others from './modules/WithoutStopWords5Others'
import WithoutStopWords4Default from './modules/WithoutStopWords4Default'
import WithoutStopWords4StartEnd from './modules/WithoutStopWords4StartEnd'
import WithoutStopWords4Others from './modules/WithoutStopWords4Others'
import WithoutStopWords3Default from './modules/WithoutStopWords3Default'
import WithoutStopWords3StartEnd from './modules/WithoutStopWords3StartEnd'
import WithoutStopWords3Others from './modules/WithoutStopWords3Others'
import WithoutStopWords2Default from './modules/WithoutStopWords2Default'
import WithoutStopWords2Others from './modules/WithoutStopWords2Others'
import ByWords10 from './modules/ByWords10'
import ByWords9 from './modules/ByWords9'
import ByWords8 from './modules/ByWords8'
import ByWords7 from './modules/ByWords7'
import ByWords6 from './modules/ByWords6'
import ByWords5 from './modules/ByWords5'
import ByWords4 from './modules/ByWords4'
import ByWords1 from './modules/ByWords1'
import ByWords from './modules/ByWords'
import Snippet from './modules/Snippet'
import Related from './modules/Related'
import Suggest from './modules/Suggest'
import Processing from './modules/Processing'

const s4 = {
    modules: {
        MainPhrasesBySerp,
        AdditionalPhrasesByVision,
        KeywordsFromMainPhrasesByVision,
        Snippet,
        Related,
        Suggest,
        WithoutStopWords,
        WithoutStopWords5Default,
        WithoutStopWords5StartEnd,
        WithoutStopWords5Others,
        WithoutStopWords4Default,
        WithoutStopWords4StartEnd,
        WithoutStopWords4Others,
        WithoutStopWords3Default,
        WithoutStopWords3StartEnd,
        WithoutStopWords3Others,
        WithoutStopWords2Default,
        WithoutStopWords2Others,
        Processing,
        ByWords,
        ByWords10,
        ByWords9,
        ByWords8,
        ByWords7,
        ByWords6,
        ByWords5,
        ByWords4,
        ByWords1
    },
    actions: {
        fetchDataOnStep4(ctx) {
            let condition_lang = ctx.rootState.stepper.s1.language !== 'ja';
            let words = 10;
            let listCountWords = [10, 9, 8, 7, 6];

            ctx.dispatch('fetchMainKeywords');
            if(condition_lang){
                ctx.dispatch('fetchKeywordsFromMainPhrasesByVision');
                listCountWords = [10, 9, 8, 7, 6, 1];
            }
            ctx.dispatch('fetchAdditionalKeywords');
            ctx.dispatch('fetchSnippet');
            ctx.dispatch('fetchRelated');
            ctx.dispatch('fetchSuggest');

            if (ctx.rootState.enable_test_data) {
                words = 4;
                // listCountWords = [5, 4, 1];
            }

            if (ctx.rootState.stepper.s4.ignore_parent_keywords) {
                ctx.dispatch('fetchByWords', {
                    words: words,
                    ignore: true
                });
            } else {

                for (let i in listCountWords) {
                    ctx.dispatch('fetchByWords', {
                        words: listCountWords[i],
                        ignore: false
                    });
                }
            }
            ctx.dispatch('fetchWithoutStopWords', {count_words: 5, type: 'Default'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 5, type: 'StartEnd'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 5, type: 'Others'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 4, type: 'Default'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 4, type: 'StartEnd'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 4, type: 'Others'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 3, type: 'Default'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 3, type: 'StartEnd'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 3, type: 'Others'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 2, type: 'Default'});
            ctx.dispatch('fetchWithoutStopWords', {count_words: 2, type: 'Others'});
        }
    },
    getters: {
        is_data_received(state) {
            return (
                state.MainPhrasesBySerp.loading === true ||
                state.AdditionalPhrasesByVision.loading === true ||
                state.KeywordsFromMainPhrasesByVision.loading === true ||
                state.Snippet.loading === true ||
                state.Related.loading === true ||
                state.Suggest.loading === true ||
                state.WithoutStopWords5Default.loading === true ||
                state.WithoutStopWords5StartEnd.loading === true ||
                state.WithoutStopWords5Others.loading === true ||
                state.WithoutStopWords4Default.loading === true ||
                state.WithoutStopWords4StartEnd.loading === true ||
                state.WithoutStopWords4Others.loading === true ||
                state.WithoutStopWords3Default.loading === true ||
                state.WithoutStopWords3StartEnd.loading === true ||
                state.WithoutStopWords3Others.loading === true ||
                state.WithoutStopWords2Default.loading === true ||
                state.WithoutStopWords2Others.loading === true ||
                state.ByWords10.loading === true ||
                state.ByWords9.loading === true ||
                state.ByWords8.loading === true ||
                state.ByWords7.loading === true ||
                state.ByWords6.loading === true ||
                state.ByWords5.loading === true ||
                state.ByWords4.loading === true ||
                state.ByWords1.loading === true
            )
        }
    },
    state: {
        disable_stop_words: true,
        ignore_parent_keywords: true,
        ignore_keywords: [],
        selected_phrases: [],
        base_url: -1
    }
};

export default s4