const s1 = {
    state: {
        main_keywords: [],
        additional_keywords: [],
        serp_type: "desktop",
        language: "en",
        translation_language: "en",
        search_engine: "com",
        compare_url: ""
    },
    getters: {
        hasCompareUrl: state => {
            return state.compare_url.length !== 0
        },
        getActiveLanguage: state => state.language,
    },
    mutations: {
        updateMainKeywords(state, value) {
            state.main_keywords = value
        },
        updateAdditionalKeywords(state, value) {
            state.additional_keywords = value
        },
        updateLanguage(state, value) {
            state.language = value
        },
        updateSearchEngine(state, value) {
            state.search_engine = value
        },
        updateCompareUrl(state, value) {
            state.compare_url = value
        },
    }
};

export default s1