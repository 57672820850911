export const calcMinMaxMixin = {
    methods:{
        calcMinEntry(med, count_words) {
            let absolute_med = med[0];
            // let relative_med = med[1];
            let average = this.get_avg_text_length();

            if(this.is_language_equals('ja')){
                return Math.floor((absolute_med * this.get_additions(0)) / average)
            }

            if(this.$store.state.beta){
                let percent = Math.round(absolute_med * count_words * 100 * 100 / average) / 100
                let desired_text_length = (this.get_additions(0) + this.get_additions(1)) / 2;
                let min_entry = Math.floor(desired_text_length * percent * 0.75 / (count_words * 100));
                return min_entry < 1 ? 1 : min_entry
            }

            if (absolute_med === 0 || count_words === 0) {
                return 0
            }

            let result = Math.floor((absolute_med * 0.92 * this.get_additions(0)) / average);

            return result < 1 ? 1 : result
        },
        calcMaxEntry(med, count_words) {
            let absolute_med = med[0];
            // let relative_med = med[1];
            let average = this.get_avg_text_length();

            if(this.is_language_equals('ja')){
                return Math.ceil((absolute_med * this.get_additions(1)) / average)
            }

            if(this.$store.state.beta){
                let percent = Math.round(absolute_med * count_words * 100 * 100 / average) / 100
                let desired_text_length = (this.get_additions(0) + this.get_additions(1)) / 2;
                return Math.ceil(desired_text_length * percent * 1.25 / (count_words * 100))
            }

            if (absolute_med === 0 || count_words === 0) {
                return 0
            }

            let result_from = Math.floor((absolute_med * 1.08 * this.get_additions(0)) / average);
            let result_to = Math.floor((absolute_med * 1.08 * this.get_additions(0)) / average);

            return result_from < 1 ? 1 : result_to
        },
        is_language_equals(lang){
            return this.$store.state.stepper.s1.language === lang
        },
        get_avg_text_length(){
            return this.$store.state.stepper.s3.average_length
        },
        get_additions(i) {
            return this.$store.state.stepper.s3.additions[i]
        },
    }
}

// recomended phrase count
//
// let phrases = [{p: 'bluehost', e: 6}, {p: 'shared hosting', e: 7}]
//
// let median_text_length = 5960
// let desired_text_length = 5960
//
// for(let i in phrases){
//     let phrase = phrases[i].p
//     let count_words = phrase.split(' ').length
//     let entry = phrases[i].e
//     let percent = Math.round(entry * count_words * 100 * 100 / median_text_length) / 100
//
//     let desired_entry = Math.round(desired_text_length * percent / (count_words * 100))
//     let desired_entry_old = Math.floor(entry * desired_text_length / median_text_length / count_words)
//
//     console.log(`The phrase ${phrase}, occurs in ${percent}% of cases`)
//     console.log(`Desired entry - ${desired_entry}, old ${desired_entry_old}`)
// }