import Vue from 'vue'
import Vuex from 'vuex';
import stepper from './modules/stepper'
import auth from './modules/auth/auth'
// import VueTagManager from "vue-tag-manager"
// import VueGtm from 'vue-gtm';

Vue.use(Vuex);

// Vue.use(VueGtm, {
//     id: 'UA-153988207-1', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
//     // queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
//     //     gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
//     //     gtm_preview:'env-4',
//     //     gtm_cookies_win:'x'
//     // },
//     enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//     debug: true, // Whether or not display console logs debugs (optional)
//     loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//     // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//     // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
// });
// Vue.use(VueTagManager, {
//     gtmId: 'GTM-WL32VR4'
// });

const store = new Vuex.Store({
    modules: {
        stepper,
        auth
    },
    state: {
        beta: false,
        sheet: false,
        sheet_type: 'error',
        sheet_message: "An error has occurred, we are working on a fix.",
        enable_test_data: false,
        count: 0,
        currentTab: 1,
        redirect_to_after_login: '',
        // server_url: 'http://88.99.5.25'
        server_url: 'http://159.69.135.21'
        // server_url: 'http://localhost:8000'
        // server_url: 'http://serpinsider.mac'
    },
    mutations: {
        increment(state) {
            state.count++
        }
    }
});

export default store