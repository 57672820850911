<template>
    <v-card
            class="mx-auto mb-12 mt-2"
            raised
    >
        <v-card-title>
            Use these phrases in the first 150 words
        </v-card-title>
        <v-card-text>
            <v-data-table
                    :headers="headers"
                    :items="result"
                    item-key="phrase"
                    :items-per-page="5"
                    class="elevation-1"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-for="item in items" :key="item.phrase">
                        <td>{{ item.phrase }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        computed: {
            result() {
                return this.$store.state.stepper.s5.phrases_in_top_150_words
            },
            headers() {
                return [
                    {text: 'Phrase', value: 'phrase'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>