var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mx-auto mb-12 mt-2",attrs:{"max-width":"500","raised":""}},[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"label":"Main Keywords","no-resize":"","rows":"4","rules":_vm.rules_main_keywords,"value":_vm.main_keywords,"required":"","placeholder":'bluehost review;1300\nbluehost wordpress review;100'},on:{"change":_vm.updateMainKeywords},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"left":"","allow-overflow":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" keyword 1;volume"),_c('br'),_vm._v(" keyword 2;volume ")])]},proxy:true}])}),_c('v-textarea',{attrs:{"label":"Additional Keywords","no-resize":"","rows":"4","rules":_vm.rules_addition_keywords,"value":_vm.additional_keywords,"placeholder":'bluehost review;1300\nbluehost wordpress review;100'},on:{"change":_vm.updateAdditionalKeywords},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"left":"","allow-overflow":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" keyword 1;volume"),_c('br'),_vm._v(" keyword 2;volume ")])]},proxy:true}])}),_c('v-text-field',{attrs:{"label":"Compare Url","placeholder":'https://app.contentball.com',"value":_vm.compare_url},on:{"change":_vm.updateCompareUrl},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" Compare Url ")])]},proxy:true}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mx-auto mb-12 mt-2",attrs:{"max-width":"500","raised":""}},[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-autocomplete',{attrs:{"label":"SERP","items":_vm.serp_list,"value":_vm.serp_type,"rules":[function (v) { return !!v || 'Item is required'; }],"filter":_vm.active_search_engine_filter},on:{"change":_vm.updateSerp},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" Google Search ")])]},proxy:true}])}),_c('v-autocomplete',{attrs:{"label":"Search Engine","items":_vm.search_engines,"value":_vm.search_engine,"rules":[function (v) { return !!v || 'Item is required'; }],"filter":_vm.active_search_engine_filter},on:{"change":_vm.updateSearchEngine},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" Google Search ")])]},proxy:true}])}),_c('v-autocomplete',{attrs:{"items":_vm.languages,"rules":[function (v) { return !!v || 'Item is required'; }],"value":_vm.language,"label":"Language","item-text":"country","item-value":"iso","filter":_vm.active_search_engine_filter},on:{"change":_vm.updateLanguage},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" Interface Language ")])]},proxy:true}])}),_c('v-autocomplete',{attrs:{"items":_vm.languages,"value":_vm.translation_language,"label":"Translation Language","item-text":"country","item-value":"iso"},on:{"change":_vm.updateTranslationLanguage},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information-outline")])]}}])},[_vm._v(" Translation Language ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"float-right",attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":function($event){return _vm.setCurrentTab(2)}}},[_vm._v(" Choose competitors ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }