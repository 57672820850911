<template>
    <div>
        <v-stepper-content step="2">
            <v-alert
                    v-if="loading"
                    class="mx-3"
                    text
                    prominent
                    type="info"
                    icon="mdi-clock-fast"
            >
                Processing keywords data. Please wait few minutes.
            </v-alert>
            <v-alert
                    v-if="false === loading"
                    class="mx-3"
                    text
                    prominent
                    color="blue-grey"
                    icon="mdi-information-outline"
            >
                Choose at least three competitors to analyze
            </v-alert>
            <v-card
                    class="mb-12 mt-2 mx-3"
                    :loading="loading"
                    raised
            >
                <v-card-text class="fixed-header-table">
                    <v-data-table
                            v-model="selected_urls"
                            :headers="tableHeader"
                            :items="tableData"
                            item-key="url"
                            sort-by="vision"
                            :sort-desc="true"
                            show-select
                            multi-sort
                            class="elevation-1"
                    >
                        <template v-slot:body="{ items }">
                            <tbody>
                            <tr v-for="item in items" :key="item.url">
                                <td>
                                    <v-checkbox class="mt-0" hide-details v-model="selected_urls" :value="item"
                                                show-select/>
                                </td>
                                <td>
                                    <a :href="item.url" target="_blank">{{ item.url }}</a><br/>
                                    <span v-html="item.anchor"></span>
                                </td>
                                <td width="130" class="text-center">
                                    {{ Math.round(item.vision * 100 / amountVisibility) }}%
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-form
                            ref="form"
                            v-model="valid"
                    >
                        <v-textarea
                                label="Add your urls to analysis"
                                no-resize
                                rows="6"
                                :rules="rules_additional_urls"
                                :value="additional_urls"
                                required
                                class="mt-4"
                                @change="updateAdditionalUrls"
                        ></v-textarea>
                    </v-form>
                </v-card-text>
            </v-card>
            <v-btn @click="setCurrentTab(1)" text>back to settings</v-btn>
            <v-btn :disabled="disableNext" color="primary" @click="setCurrentTab(3)" class="float-right">Collect texts</v-btn>
        </v-stepper-content>
    </div>
</template>

<script>
    export default {
        name: 'Step2',
        methods: {
            setCurrentTab(tab) {
                this.$store.state.stepper.current_tab = tab;
                this.$store.dispatch('fetchTexts');
            },
            updateAdditionalUrls(value) {
                this.$store.state.stepper.s2.additional_urls = value.split("\n").map((v) => v.trim()).filter((v) => v !== '');
            },
        },
        data() {
            return {
                valid: false,
                selected: [],
                rules_additional_urls: [
                    v => v !== undefined && v.trim().split('\n').length <= 10 || 'Urls should be less than 10',
                ],
            }
        },
        computed: {
            additional_urls() {
                return this.$store.state.stepper.s2.additional_urls.join("\n")
            },
            tableHeader() {
                return [
                    {text: 'Url', align: 'left', value: 'url'},
                    {text: 'Visibility', align: 'center', value: 'vision', sortable: true},
                ]
            },
            tableData() {
                return this.$store.state.stepper.s2.vision
            },
            selected_urls: {
                get() {
                    return this.$store.state.stepper.s2.selected_urls
                },
                set(value) {
                    this.$store.state.stepper.s2.selected_urls = value
                }
            },
            loading() {
                return this.$store.state.stepper.s2.loading
            },
            disableNext() {
                return this.selected_urls.length <= 2 || !this.valid
            },
            count() {
                return this.$store.state.count
            },
            amountVisibility() {
                return this.$store.getters.amountVisibility;
            },
            vision() {
                return this.$store.state.stepper.s2.vision
            },
            headers() {
                return [
                    {label: 'Url', align: 'left', field: 'url'},
                    {label: 'Visibility', align: 'center', field: 'vision', sortable: true},
                ]
            }
        }
    }
</script>

<style>
    .v-data-table-header th.sortable {
        cursor: default !important;
    }

    .v-data-table-header th.sortable i,
    .v-data-table-header th.sortable .v-data-table-header__sort-badge {
        display: none !important;
    }
</style>